const HourlyLightSchedule = (props) => {
    const {toggle1, toggle2, toggle3, toggle4, toggle5, toggle6, toggle7, toggle8, toggle9, toggle10, toggle11, toggle12, toggle13, toggle14, toggle15, toggle16, toggle17, toggle18, toggle19, toggle20, toggle21, toggle22, toggle23, toggle24, onToggle1, onToggle2, onToggle3, onToggle4, onToggle5, onToggle6, onToggle7, onToggle8, onToggle9, onToggle10, onToggle11, onToggle12, onToggle13, onToggle14, onToggle15, onToggle16, onToggle17, onToggle18, onToggle19, onToggle20, onToggle21, onToggle22, onToggle23, onToggle24,} = props
    
    return <>
    <div className="flex bg-gray-100 w-2/3 ml-auto mr-auto justify-evenly py-3 rounded shadow" >
        <h1 className="px-5 italic text-center ">Hourly Light Settings: </h1>
    
        <table className="table-fixed">
            <thead>
            <tr>
                <th></th>
                <th>12</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
                <th>11</th>
            </tr>
            </thead>
            
            <tbody>
            <tr>
                <td>AM</td>
                <td>
                    <button onClick={() => onToggle1(!toggle1)} className={`p-1 font-bold text-white ${!toggle1 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle1 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle2(!toggle2)} className={`p-1 font-bold text-white ${!toggle2 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle2 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle3(!toggle3)} className={`p-1 font-bold text-white ${!toggle3 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle3 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle4(!toggle4)} className={`p-1 font-bold text-white ${!toggle4 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle4 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle5(!toggle5)} className={`p-1 font-bold text-white ${!toggle5 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle5 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle6(!toggle6)} className={`p-1 font-bold text-white ${!toggle6 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle6 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle7(!toggle7)} className={`p-1 font-bold text-white ${!toggle7 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle7 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle8(!toggle8)} className={`p-1 font-bold text-white ${!toggle8 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle8 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle9(!toggle9)} className={`p-1 font-bold text-white ${!toggle9 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle9 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle10(!toggle10)} className={`p-1 font-bold text-white ${!toggle10 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle10 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle11(!toggle11)} className={`p-1 font-bold text-white ${!toggle11 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle11 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle12(!toggle12)} className={`p-1 font-bold text-white ${!toggle12 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle12 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                
            </tr>
            
            <tr>
                <td>PM</td>
                
                <td>
                    <button onClick={() => onToggle13(!toggle13)} className={`p-1 font-bold text-white ${!toggle13 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle13 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle14(!toggle14)} className={`p-1 font-bold text-white ${!toggle14 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle14 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle15(!toggle15)} className={`p-1 font-bold text-white ${!toggle15 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle15 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle16(!toggle16)} className={`p-1 font-bold text-white ${!toggle16 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle16 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle17(!toggle17)} className={`p-1 font-bold text-white ${!toggle17 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle17 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle18(!toggle18)} className={`p-1 font-bold text-white ${!toggle18 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle18 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle19(!toggle19)} className={`p-1 font-bold text-white ${!toggle19 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle19 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle20(!toggle20)} className={`p-1 font-bold text-white ${!toggle20 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle20 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle21(!toggle21)} className={`p-1 font-bold text-white ${!toggle21 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle21 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle22(!toggle22)} className={`p-1 font-bold text-white ${!toggle22 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle22 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle23(!toggle23)} className={`p-1 font-bold text-white ${!toggle23 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle23 ? 'OFF': 'ON'}        
                    </button> 
                </td>
                <td>
                    <button onClick={() => onToggle24(!toggle24)} className={`p-1 font-bold text-white ${!toggle24 ? 'bg-blue-500 rounded hover:bg-blue-700' : 'bg-red-500 rounded hover:bg-red-700'}`}>
                        {!toggle24 ? 'OFF': 'ON'}        
                    </button> 
                </td>
            </tr>
            </tbody>
        </table>
     </div>
  </>
}

export default HourlyLightSchedule

