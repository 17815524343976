const content = [
        {
            id:1,
            title: 'Setting Your Location',
            description: 'Introduction to the general organization of Virtual Grower and selecting the location of your greenhouse.',
            source: 'settingYourLocation'
        },
        {
            id:2,
            title: 'Greenhouse Design',
            description: 'Selecting the dimensions and materials of your greenhouse, describing air infiltration opportunities, and activating energy curtains.',
            source: 'greenhouseDesign'
        },
        {
            id:3,
            title: 'Heating',
            description: 'Defining your heating schedules and characterizing your heating systems.',
            source: 'heating'
        },
        {
            id:4,
            title: 'Lighting',
            description: 'Defining your lighting schedules and characterizing your lighting systems.',
            source: 'lighting'
        },
        {
            id:5,
            title: 'Plants',
            description: 'Describing the features within the plant section and creating a plant list.',
            source: 'plants'
        },
        {
            id:6,
            title: 'Output and Greenhouse Setup',
            description: 'Assigning heating, lighting, and plant properties to a greenhouse to begin the cost-calculating process.',
            source: ''
        },
        {
            id:7,
            title: 'Output and Calculating Cost',
            description: 'Calculating costs and reviewing the data tables and graphs generated by your simulation.',
            source: ''
        },
        {
            id:8,
            title: 'Generating Reports',
            description: 'Exporting your simulated costs and opening your report in a word-processing and spreadsheet program.',
            source: ''
        },
        {
            id:9,
            title: 'Predicting Crop Timing',
            description: 'Calculating when a crop should be started to be ready for a specified finish date.',
            source: ''
        },
        {
            id:10,
            title: 'Real Time Heating Cost',
            description: 'Predicting heating costs for the next three days based on current weather forecasts.',
            source: ''
        },
        {
            id:11,
            title: 'Edit Fuels and Materials',
            description: 'dding a real or theoretical fuel and material to the database for use in simulations.',
            source: ''
        },
    ]

export default content;
