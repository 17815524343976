import { useMemo } from "react";
import Plot from 'react-plotly.js'

const DailyTemperature = (props) => {
  const dailytemperature = JSON.parse(localStorage.getItem('dailytemperature'));
   
  const getAverageDailyTemperature = useMemo(() => {
    let adt = [365.0];
    let count = 0;
    for (let i = 0; i < 8760; i += 24) {
		let sum = 0;
		for (let j = i; j < i + 24; j++) {
			if(j > 0) sum += dailytemperature[j].temperature
		}
		adt[count] = sum / 24;
      	count++;
    }
    return adt;
  },[dailytemperature]);
  
  const getDailyLows = useMemo(() => {
    let dl = [365.0];
    let count = 0;
    for (let i = 0; i < 8760; i += 24) {
      let low = 9999;
      for (let j = i; j < i + 24; j++) {
        if(j > 0) {
          if (dailytemperature[j].temperature < low) low = dailytemperature[j].temperature;
        	if (dailytemperature[j].temperature < props.coldestHour) props.onColdestHour(j);
        }
      }

      dl[count] = low;
      count++;
    }
    return dl
  }, [dailytemperature, props])

  const getDailyHighs = useMemo(() => {
    let dh = [365.0]
    let count = 0
    for(let i = 0; i < 8760; i+=24) {
      let high = -9999
      for (let j = i; j < i + 24; j++) {
        if (j > 0)  {
          if (dailytemperature[j].temperature > high) high = dailytemperature[j].temperature
        }
      }

      dh[count] = Number(high.toFixed(2))
      count++

    }
    return dh
  },[dailytemperature])

  const transformData = (data) => {
    let plot_data = [];
    let x = [];
    let y = [];
    data.map((value, id) => id > 0 && (x.push(id), y.push(value)))  // x = day, y = temperature
    plot_data['x'] = x
    plot_data['y'] = y

    return plot_data
  }  

  return <>
    <Plot
      data = {[
        // Highs
        {type: 'scatter',
        mode: 'lines',
        x: transformData(getDailyHighs)['x'],
        y: transformData(getDailyHighs)['y'],
        marker: { color: 'red'},
        name: `Day Highs, \u00B0F`,
      },  

        // Lows
        {type: 'scatter',
        mode: 'lines',
        x: transformData(getDailyLows)['x'], 
        y: transformData(getDailyLows)['y'],
        marker: { color: 'blue'},
        name: `Day Lows, \u00B0F`,
      }        
      ]}
        
      layout = { {
        width: 1000, 
        height: 500, 
        title: 'Typical Daily Temperature Range', 
        showlegend:true, 
        xaxis: { title: 'Day', showline: false, mirror: 'ticks', linecolor: '#636363', linewidth: 3, tickmode:'linear', tick0:0, dtick:50}, 
        yaxis: { title: `Temperature \u00B0F`} }}
      
      config = {{displayModeBar: false}}
      
    />
  </>

};

export default DailyTemperature;