const lightingUnit = ["µmol /m² /s", "Footcandles", "Lux", "W/m² [PAR]", "W/m² [SW]"]

function HourlyThreshold(props) {
    const {hourlyThreshold1, hourlyThreshold2, hourlyThreshold3, hourlyThreshold4, hourlyThreshold5, hourlyThreshold6, hourlyThreshold7, hourlyThreshold8, hourlyThreshold9, hourlyThreshold10, hourlyThreshold11, hourlyThreshold12, hourlyThreshold13, hourlyThreshold14, hourlyThreshold15, hourlyThreshold16, hourlyThreshold17, hourlyThreshold18, hourlyThreshold19, hourlyThreshold20, hourlyThreshold21, hourlyThreshold22, hourlyThreshold23, hourlyThreshold24, onHourlyThreshold1, onHourlyThreshold2, onHourlyThreshold3, onHourlyThreshold4, onHourlyThreshold5, onHourlyThreshold6, onHourlyThreshold7, onHourlyThreshold8, onHourlyThreshold9, onHourlyThreshold10, onHourlyThreshold11, onHourlyThreshold12, onHourlyThreshold13, onHourlyThreshold14, onHourlyThreshold15, onHourlyThreshold16, onHourlyThreshold17, onHourlyThreshold18, onHourlyThreshold19, onHourlyThreshold20, onHourlyThreshold21, onHourlyThreshold22, onHourlyThreshold23, onHourlyThreshold24,} = props
   

    const unitChanger = () => {
        

        if(props.LUDropDown === lightingUnit[1]) {
            onHourlyThreshold1(((hourlyThreshold1 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold1(((hourlyThreshold1 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold2(((hourlyThreshold2 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold3(((hourlyThreshold3 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold4(((hourlyThreshold4 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold5(((hourlyThreshold5 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold6(((hourlyThreshold6 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold7(((hourlyThreshold7 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold8(((hourlyThreshold8 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold9(((hourlyThreshold9 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold10(((hourlyThreshold10 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold11(((hourlyThreshold11 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold12(((hourlyThreshold12 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold13(((hourlyThreshold13 * 0.2) * 10.76).toFixed(2)) 
            onHourlyThreshold14(((hourlyThreshold14 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold15(((hourlyThreshold15 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold16(((hourlyThreshold16 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold17(((hourlyThreshold17 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold18(((hourlyThreshold18 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold19(((hourlyThreshold19 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold20(((hourlyThreshold20 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold21(((hourlyThreshold21 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold22(((hourlyThreshold22 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold23(((hourlyThreshold23 * 0.2) * 10.76).toFixed(2))
            onHourlyThreshold24(((hourlyThreshold24 * 0.2) * 10.76).toFixed(2))
        }
        else if(props.LUDropDown === lightingUnit[2]) {
            onHourlyThreshold1(((hourlyThreshold1 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold1(((hourlyThreshold1 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold2(((hourlyThreshold2 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold3(((hourlyThreshold3 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold4(((hourlyThreshold4 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold5(((hourlyThreshold5 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold6(((hourlyThreshold6 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold7(((hourlyThreshold7 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold8(((hourlyThreshold8 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold9(((hourlyThreshold9 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold10(((hourlyThreshold10 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold11(((hourlyThreshold11 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold12(((hourlyThreshold12 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold13(((hourlyThreshold13 * 0.2) * 0.044).toFixed(2)) 
            onHourlyThreshold14(((hourlyThreshold14 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold15(((hourlyThreshold15 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold16(((hourlyThreshold16 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold17(((hourlyThreshold17 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold18(((hourlyThreshold18 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold19(((hourlyThreshold19 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold20(((hourlyThreshold20 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold21(((hourlyThreshold21 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold22(((hourlyThreshold22 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold23(((hourlyThreshold23 * 0.2) * 0.044).toFixed(2))
            onHourlyThreshold24(((hourlyThreshold24 * 0.2) * 0.044).toFixed(2))
        }

        else if(props.LUDropDown === lightingUnit[4]) {
            onHourlyThreshold1(((hourlyThreshold1 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold1(((hourlyThreshold1 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold2(((hourlyThreshold2 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold3(((hourlyThreshold3 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold4(((hourlyThreshold4 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold5(((hourlyThreshold5 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold6(((hourlyThreshold6 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold7(((hourlyThreshold7 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold8(((hourlyThreshold8 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold9(((hourlyThreshold9 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold10(((hourlyThreshold10 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold11(((hourlyThreshold11 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold12(((hourlyThreshold12 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold13(((hourlyThreshold13 * 0.2) * 0.101).toFixed(2)) 
            onHourlyThreshold14(((hourlyThreshold14 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold15(((hourlyThreshold15 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold16(((hourlyThreshold16 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold17(((hourlyThreshold17 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold18(((hourlyThreshold18 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold19(((hourlyThreshold19 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold20(((hourlyThreshold20 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold21(((hourlyThreshold21 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold22(((hourlyThreshold22 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold23(((hourlyThreshold23 * 0.2) * 0.101).toFixed(2))
            onHourlyThreshold24(((hourlyThreshold24 * 0.2) * 0.101).toFixed(2))
        }
        else {
            onHourlyThreshold1(hourlyThreshold1)
            onHourlyThreshold1(hourlyThreshold1)
            onHourlyThreshold2(hourlyThreshold2)
            onHourlyThreshold3(hourlyThreshold3)
            onHourlyThreshold4(hourlyThreshold4)
            onHourlyThreshold5(hourlyThreshold5)
            onHourlyThreshold6(hourlyThreshold6)
            onHourlyThreshold7(hourlyThreshold7)
            onHourlyThreshold8(hourlyThreshold8)
            onHourlyThreshold9(hourlyThreshold9)
            onHourlyThreshold10(hourlyThreshold10)
            onHourlyThreshold11(hourlyThreshold11)
            onHourlyThreshold12(hourlyThreshold12)
            onHourlyThreshold13(hourlyThreshold13) 
            onHourlyThreshold14(hourlyThreshold14)
            onHourlyThreshold15(hourlyThreshold15)
            onHourlyThreshold16(hourlyThreshold16)
            onHourlyThreshold17(hourlyThreshold17)
            onHourlyThreshold18(hourlyThreshold18)
            onHourlyThreshold19(hourlyThreshold19)
            onHourlyThreshold20(hourlyThreshold20)
            onHourlyThreshold21(hourlyThreshold21)
            onHourlyThreshold22(hourlyThreshold22)
            onHourlyThreshold23(hourlyThreshold23)
            onHourlyThreshold24(hourlyThreshold24)
        }
    }
    
    return <>
        <div className="flex bg-gray-100 w-2/3 ml-auto mr-auto justify-evenly py-3 rounded shadow" >
        <h1 className="px-5 italic text-center ">Hourly Light Settings: </h1>
        
        <table className="table-fixed">
            <thead>
            <tr>
                <th></th>
                <th>12</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
                <th>11</th>
            </tr>
            </thead>
            
            <tbody>
            <tr>
                <td>AM</td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold1} onChange={e => onHourlyThreshold1(e.target.value)} id="12a" size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold2} onChange={e => onHourlyThreshold2(e.target.value)} id="1a"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold3} onChange={e => onHourlyThreshold3(e.target.value)} id="ht3"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold4} onChange={e => onHourlyThreshold4(e.target.value)} id="ht4"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold5} onChange={e => onHourlyThreshold5(e.target.value)} id="ht5"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold6} onChange={e => onHourlyThreshold6(e.target.value)} id="ht6"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold7} onChange={e => onHourlyThreshold7(e.target.value)} id="ht7"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold8} onChange={e => onHourlyThreshold8(e.target.value)} id="ht8"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold9} onChange={e => onHourlyThreshold9(e.target.value)} id="ht9"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold10} onChange={e => onHourlyThreshold10(e.target.value)} id="ht10"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold11} onChange={e => onHourlyThreshold11(e.target.value)} id="ht11"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold12} onChange={e => onHourlyThreshold12(e.target.value)} id="ht12"  size="1"></input> </td>
            </tr>
                   
            <tr>
                <td>PM</td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold13} onChange={e => onHourlyThreshold13(e.target.value)} id="ht13" size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold14} onChange={e => onHourlyThreshold14(e.target.value)} id="ht14"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold15} onChange={e => onHourlyThreshold15(e.target.value)} id="ht15"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold16} onChange={e => onHourlyThreshold16(e.target.value)} id="ht16"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold17} onChange={e => onHourlyThreshold17(e.target.value)} id="ht17"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold18} onChange={e => onHourlyThreshold18(e.target.value)} id="ht18"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold19} onChange={e => onHourlyThreshold19(e.target.value)} id="ht19"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold20} onChange={e => onHourlyThreshold20(e.target.value)} id="ht20"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold21} onChange={e => onHourlyThreshold21(e.target.value)} id="ht21"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold22} onChange={e => onHourlyThreshold22(e.target.value)} id="ht22"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold23} onChange={e => onHourlyThreshold23(e.target.value)} id="ht23"  size="1"></input> </td>
                <td><input className="border-2 border-bg-gray-200" value={hourlyThreshold24} onChange={e => onHourlyThreshold24(e.target.value)} id="ht24"  size="1"></input> </td>
            </tr>
            </tbody>
        </table>
     </div>
    <div className="flex bg-gray-100 w-2/3 ml-auto mr-auto justify-evenly py-3 rounded shadow " >
        <button onClick={unitChanger} className="OVERRIDE_E">Calculate</button>
    </div>
    </>
}

export default HourlyThreshold
