import {useEffect, useContext} from 'react'
import TimeField from 'react-simple-timefield';
import { GlobalContext } from '../../hooks/GlobalContext';

const LightSchedule = (props) => {
    const context = useContext(GlobalContext)


    useEffect(() => {
        context.beginTime.split(":")[0] > 12 ? 
            context.onBeginTime12HR(context.beginTime.split(":")[0] - 12 + ":" + context.beginTime.split(":")[1])
        :
            context.beginTime.split(":")[0] === 24 || context.beginTime.split(":")[0] === '00' ? 
                context.onBeginTime12HR(12 + ":" + context.beginTime.split(":")[1])
        :
            context.onBeginTime12HR(context.beginTime)
    },[context])

     useEffect(() => {
        context.endTime.split(":")[0] > 12 ? 
            context.onEndTime12HR(context.endTime.split(":")[0] - 12 + ":" + context.endTime.split(":")[1])
        :
            context.endTime.split(":")[0] === 24 || context.endTime.split(":")[0] === '00' ? 
                context.onEndTime12HR(12 + ":" + context.endTime.split(":")[1])
        :
            context.onEndTime12HR(context.endTime)
    },[context])

    
    useEffect(() => {
        let light = context.uValue / 0.2
       
        if(props.LUDropDown === props.lightingUnit[1]) context.onCValueChange(light) 
        else if(props.LUDropDown === props.lightingUnit[2]) context.onCValueChange(parseFloat(light * 10.76).toFixed(2))
        else if(props.LUDropDown === props.lightingUnit[3]) context.onCValueChange(parseFloat(light * 0.044).toFixed(2))
        else if(props.LUDropDown === props.lightingUnit[4]) context.onCValueChange(parseFloat(light * 0.101).toFixed(2))       
        else return context.onCValueChange(context.uValue);
    },[props.LUDropDown, props.lightingUnit, context])


    return <>
        <div className={props.oneGrayBox}>
            <div className={`m-5 p-5 shadow ${context.beginTime.split(":")[0] < 12  ? "bg-yellow-100": "bg-gray-100" }`}>
                <label className="font-semibold m-1 " htmlFor="begin-light-schedule">Begin Light Schedule ({context.beginTime.split(":")[0] < 12 ? 'AM': 'PM'}): </label>
                <div className="flex justify-evenly my-5">
                    <TimeField className="border-black text-center" key="5" value={context.beginTime} onChange={e => context.onBeginTimeChange(e.target.value)} id="begin-light-schedule" style={{width: "50px"}}/>
                    {/* <label className="font-semibold m-1 " id="begin-light-schedule">{context.changeBeginTimeTo12HR} </label> */}
                </div>
                
            </div>
            <div className={`m-5 p-5 shadow ${context.endTime.split(":")[0] >= 12  ? "bg-gray-100": "bg-yellow-100" }`}>   
                <label className="font-semibold m-1 " htmlFor="end-light-schedule">End Light Schedule ({context.endTime.split(":")[0] >= 12  ? 'PM': 'AM'}): </label>
                <div className="flex justify-evenly my-5">
                    <TimeField className="border-black text-center" key="5" value={context.endTime} onChange={e => context.onEndTimeChange(e.target.value)} id="begin-light-schedule" style={{width: "50px"}}/>
                    {/* <label className="font-semibold m-1 " id="end-light-schedule">{context.changeEndTimeTo12HR} </label> */}
                </div>
            </div>
        </div> 
    </>
}

export default LightSchedule