function ViewSelection(props) {
    const {info, id, onRemoveButton} = props
    
    return (
        <div className="grid grid-cols-1 relative pb-5">
            <div className="pb-2 w-4/5">
                <img className="h-4/5" key={info.id} src={process.env.PUBLIC_URL + `/images/Plants/${info.image}`} alt={info.Common_Name}  />
                <label className="label">{info.Common_Name}</label>
                
            </div>
            <div className="bg-red-500 absolute ml-1 mt-1 pt-1">
                <button onClick={() => onRemoveButton(id)} value={id}>
                    <svg className="h-5 w-5"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"/>
                    </svg>
                </button>

            </div>
        </div>
        
    )
}

export default ViewSelection
