import {useEffect} from 'react' 

const DOCUMENT_TITLE = "Structure"

export const Structure = (props) => {
    const {useDocumentTitle, onLocalComponent, ROOF_OPTIONS, length, onLength, width, onWidth, sideHeight, onSideHeight, roofHeight, onRoofHeight, numberOfSpans, onNumberOfSpans, roofShape, onRoofShape, peaksPerSpan, onPeaksPerSpan, endWall1, onEndWall1, endWall2, onEndWall2, sideWall1, onSideWall1, sideWall2, onSideWall2, isMetric} = props;

    useDocumentTitle(DOCUMENT_TITLE)
    useEffect(() => {
         onLocalComponent(DOCUMENT_TITLE);
    }, [onLocalComponent]);


    const handleKneewallClick = () => {
        onEndWall1(endWall1)
        onEndWall2(endWall1)
        onSideWall1(endWall1)
        onSideWall2(endWall1)
    }

    const handleSideHeight = (e) => {
        if((Number(e.target.value) > Number(roofHeight))) onSideHeight(roofHeight-1)
        else  onSideHeight(e.target.value)
    }

    return <>    
        {/* Controls */}
       
        <div className="flex justify-evenly py-3">
            <label className="px-2 font-semibold" htmlFor="gh-spans" >Number of Spans: </label>
            <input size="1" className="border" type="text" id="gh-spans" value={numberOfSpans} onChange={e => onNumberOfSpans(e.target.value)}></input>
        </div>
        
        <div className="flex justify-evenly py-3">
            <label className="font-semibold" htmlFor="gh-width"> Width {isMetric ? '(m)': '(ft)'}: </label>
            <input size="1" className="border" type="text" id="gh-width" value={width} onChange={e => onWidth(e.target.value)}></input>

            <label className="px-2 font-semibold" htmlFor="gh-length" >Length {isMetric ? '(m)': '(ft)'}: </label>
            <input size="1" className="border" type="text" id="gh-length" value={length} onChange={e => onLength(e.target.value)} ></input>
        </div>

        <div className="flex justify-evenly py-3">
            <label className="px-2 font-semibold" htmlFor="gh-sideHeight" >Side Height {isMetric ? '(m)': '(ft)'}: </label>
            <input size="1" className="border" id="gh-sideHeight" value={sideHeight} onChange={e => handleSideHeight(e)}></input>                    
        </div>


        <div className={`flex justify-evenly py-3 ${(roofShape === "Triangular" || roofShape === "Arch") && "pb-3"}`}>
            <label className="px-2 font-semibold" htmlFor="gh-roofShape">Roof Shape:</label>
            <select className="border" id="gh-roofShape" name="type" value={roofShape} onChange={e => onRoofShape(e.target.value)}>
                <option key="-1">Select One</option>
                {ROOF_OPTIONS.map((name, id) => <option key={id}>{name}</option> )}
            </select>

            
        </div>

            {(roofShape === "Triangular" ||roofShape === "Arch") &&
            <div className="flex justify-evenly py-3">
                <label className="px-2 font-semibold" htmlFor="gh-peaks">Peaks per Span: </label>
                <input size="1" className="border" type="text" id="gh-peaks" value={peaksPerSpan} onChange={e => onPeaksPerSpan(e.target.value)}></input>   
            </div>
        }

        {roofShape !== "Rectangular" && <div className="flex justify-evenly py-3">
            <label className="px-2 font-semibold" htmlFor="gh-roofHeight">Roof Height {isMetric ? '(m)': '(ft)'}: </label>
            {/* roofHeight <  */}
            <input size="1" className="border" type="text" id="gh-roofHeight" value={roofShape !== 'Rectangular' ? roofHeight: ''} onChange={e => onRoofHeight(e.target.value)} disabled={roofShape === 'Rectangular' ? 'disabled' : ''}></input>                   
        </div>}
        
        <p className=" px-3 text-indigo-700"> Kneewall Heights</p>
        {/* These values must be less than sideHeight by .01, Model should not calculate this. */}
        <div className="flex justify-evenly py-3">
            <label className="px-2 font-semibold" htmlFor="gh-endWall1">End Wall 1: </label>
            <input size="1" className="border" type="text" id="gh-endWall1" value={endWall1}  onChange={e => onEndWall1(e.target.value)}></input>

            <label className="px-2 font-semibold" htmlFor="gh-endWall2">End Wall 2: </label>
            <input size="1" className="border" type="text" id="gh-endWall2" value={endWall2}  onChange={e => onEndWall2(e.target.value)}></input>
        </div>

        <div className="flex justify-evenly py-3">
            <label className="px-2 font-semibold" htmlFor="gh-sideWall1">Side Wall 1: </label>
            <input size="1" className="border" type="text" id="gh-sideWall1" value={sideWall1}  onChange={e => onSideWall1(e.target.value)}></input>

            <label className="px-2 font-semibold" htmlFor="gh-sideWall2">Side Wall 2: </label>
            <input size="1" className="border" type="text" id="gh-sideWall2" value={sideWall2}  onChange={e => onSideWall2(e.target.value)}></input>
        </div>

        <div className="flex justify-evenly py-3">
            <input className="border p-2 OVERRIDE_D"  type="button" id="gh-kneewall" value="Set Kneewall Equal"  onClick={handleKneewallClick}></input>
        </div>
    </>
}
