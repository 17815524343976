import {useEffect} from 'react'
import Switch from 'react-switch'
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

const EnergyCurtain = (props) => {
    const {useDocumentTitle, onLocalComponent, ecRating, materialTypes, ecData, ecInstalled, oneGrayBox, ecMaterialOption, qualityOption, conditionOption, currentAirInput, currentAirToggle, currentUValueInput, currentUValueToggle, onECSwitch, onECMaterialOption, onQualityOption, onConditionOption, onCurrentAirToggle, onCurrentAirInput, onCurrentUValueToggle, onCurrentUValueInput} = props
    
    useDocumentTitle("Energy Curtain")
    useEffect(() => {
         onLocalComponent("Energy Curtain");
    }, [onLocalComponent]);

    //Only show energy curtain material primary_keys from 16 - 26
    const ecMaterialRange = (value) => {if (value >= 16 && value < 27)return value}

    //Installation Quality vs Material Condition - EC Installs !% 2, EC Materials % 2. 
    const handleQualityConditionOptions = (value) => {if (value.id % 2) return value}

    const handleCurrentAirExchangeClick = () => onCurrentAirToggle(!currentAirToggle)
    const handleCurrentUValveClick = () => onCurrentUValueToggle(!currentUValueToggle)
    
    const handleImagePopupClick = (e) => {       
        const content = (
            e.currentTarget.name === 'installation-quality' ? <img key={e.currentTarget.id} src={e.currentTarget.src} alt={e.currentTarget.alt}/> : 
            e.currentTarget.name === 'material-condition' ? <img key={e.currentTarget.id} src={e.currentTarget.src} alt={e.currentTarget.alt}/> : '' 
        )
        PopupboxManager.open({ content })
    }

    const handleCardPopupClick = () => {
        const content = (
            <div className="ml-auto mr-auto">
                <p className="py-5">These are the U-Value and the Estimated Exchange Rate of the energy curtain setup. These values will change to reflect the selection that you make in this section.  If you already know these values, you can click the override button and enter your value.</p>      
            </div>
        )
        PopupboxManager.open({ content })
    }
    
    return <>
         <div className="w-1/2 ml-auto mr-auto flex justify-around m-2">
            <label className=" mt-1 label" htmlFor="ec-override">Is an energy curtain installed?</label>
            <Switch className="react-switch" onChange={onECSwitch} checked={ecInstalled} />
        </div>

        {/* Content */}
        {ecInstalled ? 
        [  
            <div className={oneGrayBox}>
                <div className="w-3/5">  
                    <div className="flex flex-wrap justify-around">
                        <label className="label py-2" htmlFor="ec-materials">Energy Curtain Material</label>
                        <select className="border-black" id="ec-materials" name="type" value={ecMaterialOption} onChange={e => onECMaterialOption(e.target.value)}>
                            <option>Select One</option>
                            {materialTypes.filter((opt) => ecMaterialRange(opt.Primary_Key)).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name}</option>)}
                        </select>
                    
                    </div>
                </div>
            </div>,
            
            <div className={oneGrayBox}>
                <div className="w-2/5">  
                    <div className="flex bg-green-100 rounded shadow justify-evenly">
                        <h1 className="ml-2 text-3xl">Installation Quality</h1>        
                    </div>
                    <div className="flex flex-wrap justify-center">
                        <label className="pt-2 mx-2 py-5" htmlFor="installation-quality">What is the quality of the energy curtain installation?</label>
                        <select className="border-black" id="installation-quality" name="type" value={qualityOption} onChange={e => onQualityOption(e.target.value)}>
                            <option>Select One</option>
                            {ecRating.map((option, index) => <option key={index} value={option}>{option} </option>)}    
                        </select>   
                        <div className="py-5 flex justify-center">
                            {ecData.filter( i => handleQualityConditionOptions(i)).map((x) => x.name === qualityOption ? <img name="installation-quality" id={x.id}  width="50%" key={x.id} src={process.env.PUBLIC_URL + `/images/EnergyCurtain/${x.image}`}  alt={x.name} onClick={handleImagePopupClick}/> : '')} 
                        </div>
                    </div>
                </div>
                <div className="w-2/5"><div className="flex bg-green-100 rounded shadow justify-evenly">
                        <h1 className="ml-2 text-3xl">Material Condition</h1>        
                    </div>
                    <div className="flex flex-wrap justify-center">
                        <label className="pt-2 mx-2 py-5" htmlFor="material-condition">What is the quality of the energy curtain material?</label>
                        <select className="border-black" id="material-condition" name="type" value={conditionOption} onChange={e => onConditionOption(e.target.value)}>
                            <option>Select One</option>
                            {ecRating.map((option) => <option value={option}>{option} </option>)}    
                        </select>
                        <div className="py-5 flex justify-center">
                            {ecData.filter( i => !handleQualityConditionOptions(i)).map((x) => x.name === conditionOption ? <img name="material-condition" width="50%" key={x.id} src={process.env.PUBLIC_URL + `/images/EnergyCurtain/${x.image}`} alt={x.name} onClick={handleImagePopupClick}/>  : '')} 
                        </div>
                    </div>
                </div>
            </div>,

            <div className={oneGrayBox}>
                <div className="flex bg-green-100 rounded shadow justify-evenly">
                    <h1 className="ml-2 text-3xl">Energy Curtain Override</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" name="global" onClick={handleCardPopupClick}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>        
                </div>
                <div className=" w-3/5">  
                    <div className="flex flex-wrap justify-around">
                        <button 
                            className={`px-4 py-2 font-semibold  rounded shadow center 
                                ${currentAirToggle ? 
                                    "text-gray-800 bg-white border border-gray-400 hover:bg-gray-100"
                                : 
                                    "bg-indigo-500 hover:bg-indigo-700 text-white"
                                } 
                            `}  
                            type="button" 
                            htmlFor="air-override" 
                            onClick={handleCurrentAirExchangeClick}>{currentAirToggle ? "Edit Current Air Exchange Rate": "Lock rate?"}
                        </button> 
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                        <input className={`border-black bg-white  ${currentAirToggle ? 'cursor-not-allowed': ''}`} disabled={currentAirToggle} name="override-value" value={currentAirInput} onChange={e => onCurrentAirInput(e.target.value)} id="air-override" type="text"  size="1"></input>     
                    </div>
                </div>
                <div className="w-3/5">  
                    <div className="flex flex-wrap justify-around">
                        <button 
                            className={currentUValueToggle ? "OVERRIDE_D": "OVERRIDE_E"}  
                            type="button" 
                            onClick={handleCurrentUValveClick}>
                                {currentUValueToggle ? "Edit Current U-Value": "Lock value?"}
                        </button>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                        <input 
                            className={`border-black ${currentUValueToggle ? 'cursor-not-allowed': ''}`} 
                            name="uvalve" 
                            value={currentUValueInput} 
                            onChange={e => onCurrentUValueInput(e.target.value)} 
                            type="text"  
                            disabled={currentUValueToggle}
                            size="1">
                        </input>  
                    </div>
                </div>
            </div>
        ]:''}

        <PopupboxContainer />
    </>
}

export default EnergyCurtain;