import React from 'react'

function ThresholdLightingUnit(props) {
    return <>
        <div className="w-11/12 ml-auto mr-auto flex flex-wrap justify-evenly m-2">
            <label className="label" htmlFor="schedule-type">Threshold Lighting Unit ({props.lightingUnit[0]}): </label>
            <label className="label">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
            </label>
          
            <select disabled={props.scheduleTypeOption === 'Select One'} className="border-2 border-gray-200" id="schedule-type" name="type" value={props.LUDropDown} onChange={e => props.onLightingUnitChange(e)}>
                {props.lightingUnit.map((option, id) => <option key={id} value={option}>{option} </option>)}    
            </select>
          
            <p className="text-sm"><span className="italic text-red-900 font-semibold">Note: </span> The conversion rate of 5 {props.lightingUnit[1]} = 1 {props.lightingUnit[0]} is only for full direct sunlight.</p>
           
        </div>
    </>
}

export default ThresholdLightingUnit
