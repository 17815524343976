import {useState, useEffect, useCallback, useContext} from 'react'

import { GlobalContext } from '../hooks/GlobalContext'
import {Structure}  from './Structure'
import Materials from './Materials'
import AirInfiltration from './AirInfiltration'
import EnergyCurtain from './EnergyCurtain'
import Model, {Legend} from './Model'
import Session from '../hooks/Session'
import DropDownList from '../hooks/DropDownList'
import { archArea, archLength } from '../Output/calculations'

import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
import useDocumentTitle from '../hooks/useDocumentTitle';

import {materials} from '../../tables/materials'
import {energycurtain} from '../../tables/energycurtain'

// Greenhouse 2D Model Interface -> Save/Reset, 2D Model, Control Menu. Data will reside in App
const ROOF_OPTIONS = ['Arch', 'Triangular', 'Half Arch', 'Rectangular']
let oneGrayBox = "bg-gray-200 p-3 m-1 w-3/4 ml-auto mr-auto flex justify-evenly shadow"    

const Greenhouse = (props) => {
    const {aiData} = props 
    const context = useContext(GlobalContext)

    useEffect(() => {
         context.global.onComponent("Greenhouse");
    }, [context.global]);
    
    const [localComponent, setLocalComponent] = useState("")
    const handleLocalComponent = (value) => setLocalComponent(value)
    
    const [subMenu, setSubMenu] = useState("")

    const handleCardPopupClick = () => {
        const content = (
            <div className="ml-auto mr-auto">
                <p className="py-5">Note: Only one span is represented in image. The light transmittance value of the roof is represented by the varying gradient of white.</p>     
            </div>
        )
        PopupboxManager.open({ content })
    }

    // // Calculate End Wall & Side Wall Surface Sq Ft
    let ew1SurfaceSqFt = context.ew1SurfaceArea + context.ew1KneewallSurfaceArea
    let ew2SurfaceSqFt = context.ew2SurfaceArea + context.ew2KneewallSurfaceArea
    let sw1SurfaceSqFt = context.sw1SurfaceArea + context.sw1KneewallSurfaceArea
    let sw2SurfaceSqFt = context.sw2SurfaceArea + context.sw2KneewallSurfaceArea

    let houseSurfaceSqFt = context.roofSurfaceArea + ew1SurfaceSqFt + ew2SurfaceSqFt + sw1SurfaceSqFt + sw2SurfaceSqFt
    let footprintSqFt = context.width * context.length * context.numberOfSpans
    

    // Quonset
    const handleQuonset = useCallback(() => {
        (context.roofShape === 'Arch' && context.sideHeight === 0) ? context.onQuonset(true) : context.onQuonset(false)
    },[context])
    useEffect(() => {
        handleQuonset(); 
    }, [handleQuonset])


    // House Volume
    useEffect(() => {
        context.onHouseVolume(context.sideHeight * context.length * context.width * context.numberOfSpans)
    }, [context])

    // Roof Volume
    useEffect(() => {
        if (context.roofShape === "Arch") context.onRoofVolume(archArea((context.width/context.peaksPerSpan), (context.roofHeight - context.sideHeight)) * context.peaksPerSpan * context.numberOfSpans * context.length)
        else if (context.roofShape === "Triangular") context.onRoofVolume((context.width * context.length * (context.roofHeight - context.sideHeight)* 0.5) * context.umberOfSpans)
        else if (context.roofShape === "Half Arch") context.onRoofVolume(((archArea(2 * context.width, (context.roofHeight - context.sideHeight)) / 2)) * context.numberOfSpans * context.length);
        else context.onRoofVolume(0.001)
    },[context])

    // Volume Above Curtain
    useEffect(() => {
        if (context.quonset){
            let bHeight = (context.roofHeight - context.sideHeight) - context.width;
            let aHeight = (context.roofHeight - context.sideHeight) - bHeight;
            let r = (Math.pow((context.width/2), 2) + Math.pow(aHeight, 2)) / (2 * aHeight);
            let ecWdth = r * Math.sqrt(2 - Math.sqrt(2));
            let ah = r - Math.sqrt(Math.pow(r, 2) - Math.pow((ecWdth/2), 2));

            context.onVolumeAboveCurtain((3 * (archArea(ecWdth, ah) * context.length)) + (context.length * bHeight * ah))
        } else {
            context.onVolumeAboveCurtain(context.roofVolume)
        }
    },[context])

    // Curtain Surface Area
    useEffect(() => {
        if (context.quonset){
            let bHeight = (context.roofHeight - context.sideHeight) - context.width;
            let aHeight = (context.roofHeight - context.sideHeight) - bHeight;
            let r = (Math.pow((context.width/2), 2) + Math.pow(aHeight, 2)) / (2 * aHeight);
            let ecWdth = r * Math.sqrt(2 - Math.sqrt(2));
            let ah = r - Math.sqrt(Math.pow(r, 2) - Math.pow((ecWdth/2), 2));

            let archL = archLength(ecWdth, ah);
            let archA = archArea(ecWdth, ah);
            let bottomSA = 2 * context.length * bHeight;
            
            context.onECSurfaceArea((3 * ((context.length * archL)+(archA * 2)))+ bottomSA);
            
        } else  context.onECSurfaceArea(context.length * context.width)
    }, [context])

    // Roof Surface Area
    useEffect(() => {
        if (context.roofShape === 'Arch') {
            let arch = archLength((context.width / context.peaksPerSpan),(context.roofHeight - context.sideHeight));
            let archA = archArea((context.width / context.peaksPerSpan),(context.roofHeight - context.sideHeight));
            context.onRoofSurfaceArea(((context.length * arch * context.peaksPerSpan) + (archA * context.peaksPerSpan * 2)) * context.numberOfSpans)
        }

        else if (context.roofShape === "Triangular") {
            let alpha = Math.sqrt(Math.pow((context.roofHeight - context.sideHeight), 2) + Math.pow((0.5 * (context.width/context.peaksPerSpan)), 2));
            let tri_area = 0.5 * (context.roofHeight - context.sideHeight) * (context.width/context.peaksPerSpan);
            let slope_area = alpha * context.length;
            context.onRoofSurfaceArea(2 * ((tri_area * context.peaksPerSpan * context.numberOfSpans) + (slope_area * context.peaksPerSpan * context.numberOfSpans)))
        }
        
        else if (context.roofShape === "Half Arch") {
            let ah = context.roofHeight - context.sideHeight;
            let w = context.width * 2;
            let arch = archLength(w,ah);
            let archA = archArea(w, ah);
            context.onRoofSurfaceArea((archA + (0.5 *(arch * context.length)) + ((context.roofHeight - context.sideHeight) * context.length)) * context.numberOfSpans) 
        }

        else context.onRoofSurfaceArea(context.length * context.width * context.numberOfSpans)
    },[context])

    // House Surface Area
    useEffect(() => {
        // Side walls
        context.onEw1SurfaceArea(context.width * (context.sideHeight - context.endWall1))
        context.onEw2SurfaceArea(context.width * (context.sideHeight - context.endWall2))
        context.onSw1SurfaceArea(context.length * (context.sideHeight - context.sideWall1))
        context.onSw2SurfaceArea(context.length * (context.sideHeight - context.sideWall2))

        //kneewalls
        context.onEW1KneewallSurfaceArea(context.width * context.endWall1)
        context.onEW2KneewallSurfaceArea(context.width * context.endWall2 )
        context.onSw1KneewallSurfaceArea(context.length * context.sideWall1)
        context.onSW2KneewallSurfaceArea(context.length * context.sideWall2)            
    },[context])


    // Materials
    const materialTypes = materials
    
    //Update values
    const values = { listName: context.dropDownOption,subMenu: 'Greenhouse',length: context.length,width: context.width,sideHeight: context.sideHeight,roofHeight: context.roofHeight,numberOfSpans: context.numberOfSpans,roofShape: context.roofShape,peaksPerSpan: context.peaksPerSpan,endWall1: context.endWall1,endWall2: context.endWall2,sideWall1: context.sideWall1,sideWall2: context.sideWall2,endWall1Type: context.endWall1Type,endWall2Type: context.endWall2Type, roofType:context.roofType, sideWall1Type: context.sideWall1Type,sideWall2Type: context.sideWall2Type,endKneewall1Type: context.endKneewall1Type,endKneewall2Type: context.endKneewall2Type,sideKneewall1Type: context.sideKneewall1Type,sideKneewall2Type: context.sideKneewall2Type,largeGap: context.largeGap,smallGap: context.smallGap,office: context.office,retailArea: context.retailArea,shippingArea: context.shippingArea,headHouse: context.headHouse,currentAirInput: context.currentAirInput,currentAirToggle: context.currentAirToggle,malfunctioningToggle: context.malfunctioningToggle,gapsBetweenToggle: context.gapsBetweenToggle,outsideIntakeToggle: context.outsideIntakeToggle,ecInstalled: context.ecInstalled,ecMaterialOption: context.ecMaterialOption,qualityOption: context.qualityOption,conditionOption: context.conditionOption,currentECAirInput: context.currentECAirInput,currentECAirToggle: context.currentECAirToggle,currentUValueToggle: context.currentUValueToggle,currentUValueInput: context.currentUValueInput, energyCurtainSurfaceArea: context.energyCurtainSurfaceArea, roofSurfaceArea: context.roofSurfaceArea, roofVolume: context.roofVolume, houseVolume: context.houseVolume, quonset: context.quonset
                   
    }
     
    // Air Infiltration
    const gapOptions = ['None', 'Few', 'Several', 'Many']
    const insulationOptions = ['Separate Building', 'Insulated', 'Not Insulated']  

    // Energy Curtain
    const ecRating = ['Excellent', 'Fair', 'Good', 'Poor']
    const [ecData] = useState(energycurtain)
    

    return <>
        <div className="flex flex-wrap justify-evenly w-full">
            <div className="w-1/5 bg-green-300 rounded my-4 shadow">
                <div className="flex justify-around m-1 space-x-3">
                    <label className=" text-3xl" htmlFor="subMenuTitle">Greenhouse  Design - {localComponent}</label>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" name="global" onClick={handleCardPopupClick}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg> 
                    
                </div> 
            </div>
            <Session values={values}/>
            
            <DropDownList subMenu={context.global.component} localComponent={context.global.component} />

            <div className="w-1/6 bg-transparent rounded my-4 shadow px-3 pb-1">
                <h4 className='flex justify-center text-lg'>Material U-Value</h4>
                <label className='flex justify-start gap-36'>Excellent<span className='flex justify-end'>Fair</span></label>
                <Legend />
            </div>
        </div>
        <div className="flex flex-wrap">
            <div className="w-3/5 ml-auto mr-auto">
                {/* Header Menu */}
                <div className="mx-2 my-2 flex flex-wrap justify-around">
                    <button key="gS" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="Structure">Structure</button>
                    
                    <button key="gM" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="Materials">Materials</button>
                    
                    <button key="aI" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="ai">Air Infiltration</button>
                    
                    <button key="eC" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="ec">Energy Curtain</button>
                </div>
            </div>
        </div>
        {/* Menu Content */}
        <div className="flex flex-wrap">
        {(subMenu === 'Structure' || subMenu === 'Materials') ? 
            <div className="w-full ml-auto mr-auto flex flex-wrap">
                <div className="w-1/4 ml-auto rounded bg-gradient-to-b from-gray-50 to-gray-200">
                    <p className="text-center underline"> {localComponent} Control Menu</p>
                    {subMenu === 'Structure' ? 
                        <Structure
                            useDocumentTitle={useDocumentTitle} 
                            onLocalComponent={handleLocalComponent}  
                            isMetric={context.global.isMetric}
                            ROOF_OPTIONS={ROOF_OPTIONS}
                            structure={context.structure}
                            onStructure={context.onStructure}
                            width={context.width}
                            length={context.length}
                            sideHeight={context.sideHeight}
                            roofHeight={context.roofHeight}
                            numberOfSpans={context.numberOfSpans}
                            roofShape={context.roofShape}
                            peaksPerSpan={context.peaksPerSpan}
                            endWall1={context.endWall1}
                            endWall2={context.endWall2}
                            sideWall1={context.sideWall1}
                            sideWall2={context.sideWall2}
                            onLength={context.onLengthValue}
                            onWidth={context.onWidthValue}
                            onSideHeight={context.onSideHeight}
                            onRoofHeight = {context.onRoofHeight}
                            onNumberOfSpans={context.onNumberofSpans}
                            onRoofShape={context.onRoofShape}
                            onPeaksPerSpan={context.onPeaksPerSpan}
                            onEndWall1={context.onEndWall1}
                            onEndWall2={context.onEndWall2}
                            onSideWall1={context.onSideWall1}
                            onSideWall2={context.onSideWall2}
                        />

                    : subMenu === 'Materials' ?
                        <Materials
                            useDocumentTitle={useDocumentTitle} 
                            onLocalComponent={handleLocalComponent}  
                            isMetric={context.global.isMetric}
                            materialTypes={materialTypes}
                            endWall1Type={context.endWall1Type}
                            endWall2Type={context.endWall2Type}
                            roofType={context.roofType}
                            sideWall1Type={context.sideWall1Type}
                            sideWall2Type={context.sideWall2Type}
                            endKneewall1Type={context.endKneewall1Type}
                            endKneewall2Type={context.endKneewall2Type}
                            sideKneewall1Type={context.sideKneewall1Type}
                            sideKneewall2Type={context.sideKneewall2Type}
                            onEndWall1Type={context.onEndWall1Type}
                            onEndWall2Type={context.onEndWall2Type}
                            onRoofType={context.onRoofType}
                            onSideWall1Type={context.onSideWall1Type}
                            onSideWall2Type={context.onSideWall2Type}
                            onEndKneewall1Type={context.onEndKneewall1Type}
                            onEndKneewall2Type={context.onEndKneewall2Type}
                            onSideKneewall1Type={context.onSideKneewall1Type}
                            onSideKneewall2Type={context.onSideKneewall2Type}
                            />  
                    : null}   
                </div>

                {/* 2D Output  */}
                <div className="w-3/5 px-5 py-2 ml-auto mr-10 rounded bg-gradient-to-b from-gray-50 to-gray-200">
                    <p className="flex justify-center">Footprint Sq {context.global.isMetric ? '(m)': '(ft)'}: {footprintSqFt} 
                        <span className="mx-3"> Volume Cubic {context.global.isMetric ? '(m)': '(ft)'}: {context.houseVolume}</span> 
                        Surface Sq {context.global.isMetric ? '(m)': '(ft)'}: {Math.round(houseSurfaceSqFt)}
                    </p>
                    <Model values={values} ROOF_OPTIONS={ROOF_OPTIONS} materialTypes={materialTypes}/>
                </div>            
            </div>           
         : subMenu === 'ai' ?
                <AirInfiltration 
                    useDocumentTitle={useDocumentTitle} 
                    onLocalComponent={handleLocalComponent}  
                    global={context.global} 
                    aiData={aiData}
                    largeGap={context.largeGap}
                    smallGap={context.smallGap}
                    office={context.office}
                    retailArea={context.retailArea}
                    shippingArea={context.shippingArea}
                    headHouse={context.headHouse}
                    currentAirInput={context.currentAirInput}
                    currentAirToggle={context.currentAirToggle}
                    malfunctioningToggle={context.malfunctioningToggle}
                    gapsBetweenToggle={context.gapsBetweenToggle}
                    outsideIntakeToggle={context.outsideIntakeToggle}
                    oneGrayBox={oneGrayBox}
                    gapOptions={gapOptions}
                    insulationOptions={insulationOptions}
                    onLargeGap={context.onLargeGap}
                    onSmallGap={context.onSmallGap}
                    onOffice={context.onOffice}
                    onRetailArea={context.onRetailArea}
                    onShippingArea={context.onShippingArea}
                    onHeadHouse={context.onHeadHouse}
                    onCurrentAirInput={context.onCurrentAirInput}
                    onCurrentAirToggle={context.onCurrentAirToggle}
                    onMalfunctionToggle={context.onMalfunctiongToggle}
                    onGapsBetweenToggle={context.onGapBetweenToggle}
                    onOutsideIntakeToggle={context.onOutsideIntakeToggle}
                />
        : subMenu === 'ec' ?
                <EnergyCurtain 
                    useDocumentTitle={useDocumentTitle} 
                    onLocalComponent={handleLocalComponent} 
                    ecRating={ecRating}
                    materialTypes={materialTypes}
                    ecData={ecData}
                    ecInstalled={context.ecInstalled}
                    oneGrayBox={oneGrayBox}
                    ecMaterialOption={context.ecMaterialOption}
                    qualityOption={context.qualityOption}
                    conditionOption={context.conditionOption}
                    currentAirInput={context.currentECAirInput}
                    currentAirToggle={context.currentECAirToggle}
                    currentUValueToggle={context.currentUValueToggle}
                    currentUValueInput={context.currentUValueInput}
                    onECSwitch={context.onECSwitch}
                    onECMaterialOption={context.onECMaterialOption}    
                    onQualityOption={context.onQualityOption}
                    onConditionOption={context.onConditionOption}
                    onCurrentAirToggle={context.onCurrentECAirToggle}
                    onCurrentAirInput={context.onCurrentECAirInput}
                    onCurrentUValueToggle={context.onCurrentUValueToggle}
                    onCurrentUValueInput={context.onCurrentUValueInput}

                />
        : null}
    </div>

    <PopupboxContainer />
    </>
}

export default Greenhouse
