import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../hooks/GlobalContext";

import useDocumentTitle from "../hooks/useDocumentTitle";
import Session from "../hooks/Session";
import DailyLightIntergal from "./DailyLightIntergal";
import DailyTemperature from "./DailyTemperature";
import MapChart from "./MapChart";

const LOCAL_COMPONENT_NAME = "Location";

// Location Interface -> Save/Reset / Control Menu, Map, TMY Data for Graph 1 & 2
const Location = (props) => {
  const { cityData, zipData } = props;
  const context = useContext(GlobalContext);
  const [subMenu, setSubMenu] = useState("")

  useEffect(() => {
    context.global.onComponent(LOCAL_COMPONENT_NAME);
  }, [context.global]);

  useDocumentTitle(LOCAL_COMPONENT_NAME);

  const values = {
    listName: 'location',
    subMenu: LOCAL_COMPONENT_NAME,
    zipcode: context.zipcode,
    WBAN: context.WBAN,
    city: context.city,
    state: context.state,
    position: context.position,
    temperature: context.temperature,
    radiance: context.radiance,
    illuminance: context.illuminance,
    humidity: context.humidity,
    windSpeed: context.windSpeed,
    cloudCover: context.cloudCover,
    avgDailyTemp: context.avgDailyTemp,
    coldestHour: context.coldestHour,
  };

  const [temperature, setTemperature] = useState([]);
  const [radiance, setRadiance] = useState([]);
  const [illuminance, setIlluminance] = useState([]);
  const [humidity, setHumidity] = useState([]);     
  const [windSpeed, setWindSpeed] = useState([]);  
  const [cloudCover, setCloudCover] = useState([]);   

  const lowerCaseAllWordsExceptFirstLetters = string =>
    string.replaceAll(
      /\S*/g,
      word => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
    );

  //Control Menu Lookup will intitiate WBAN lookup
  const handleGetWBANByZip = (e, zip) => {
    e.preventDefault();

    //1. Retrieve Latitude and Longitude
    const zipLookup = zipData.find(data => data.Zipcode === Number(zip));

    //2. Compare lookup State & City against cityData
    const cityLookup = cityData
      .map(data => data)
      .find(
        loc =>
          loc.City.toLowerCase() === zipLookup.City.toLowerCase() &&
          loc.State === zipLookup.State
      );

    //3. If it exists - retrieve Lat, Lng, Elevation, WBAN
    if (cityLookup !== undefined) {
      context.onPosition([Number(cityLookup.Latitude), Number(cityLookup.Longitude)]);
      context.onElevation(cityLookup.Elevation);
      context.onWBAN(cityLookup.WBAN);
      context.onCityLookup(cityLookup.City);
      context.onStateLookup(cityLookup.State);
    } else {
      context.onPosition([
        zipLookup.Latitude,
        zipLookup.Longitude
      ]);
      context.onElevation();
      context.onWBAN();
      context.onCityLookup(lowerCaseAllWordsExceptFirstLetters(zipLookup.City));
      context.onStateLookup(zipLookup.State);
    }
  };

  // Alternative WBAN lookup via City, State 
  const handleGetWBANByCityState = (e, city, state) => {
    e.preventDefault();
    
    //1. Retrieve cityData data
    const lookup = cityData.map(data => data).find(loc => loc.City.toLowerCase() === city.toLowerCase() && loc.State === state)        
       
    //2. retrieve Lat, Lng, Elevation, WBAN
    if ( lookup !== undefined) {
      context.onPosition([Number(lookup['Latitude']), Number(lookup['Longitude'])]);
      context.onElevation(lookup['Elevation']);
      context.onWBAN(lookup['WBAN']);
      context.onCityLookup(lookup['City']);
      context.onStateLookup(lookup['State']);
      context.onZipcodeLookup('')
    }
  };

  //Retrieve unique state name abbreviation
  const onlyUnique = (value, index, self) => self.indexOf(value) === index
  const stateLookup = cityData.map(data => data['State'])
  const states = stateLookup.filter(onlyUnique)

  //Forces Location to use latest zip code before sending it to populateWeatherData
  useEffect(() => {
    if (context.WBAN) populateWeatherData(context.WBAN);
  },[context.WBAN])

  // Find and decipher weather data values. 
  const populateWeatherData = WBAN => {
    fetch(process.env.PUBLIC_URL + `/TMY/${WBAN}.tmy`)
      .then(response => response.text())
      .then(text => [
        setTemperature(
          text
            .split(/[\n/\r]+/)
            .map(item => 1.8 * (item.substring(26, 30) / 10) + 32)
        ),
        setRadiance(text.split(/[\n/\r]+/).map(item => item.substring(8, 12))),
        setIlluminance(
          text.split(/[\n/\r]+/).map(item => item.substring(16, 20) * 100)
        ),
        setHumidity(text.split(/[\n/\r]+/).map(item => item.substring(34, 37))),
        setWindSpeed(
          text
            .split(/[\n/\r]+/)
            .map(item => (item.substring(44, 47) / 10) * 2.23693629)
        ),
        setCloudCover(
          text.split(/[\n/\r]+/).map(item => item.substring(24, 26) * 10)
        )
      ])
      .catch(err => console.log(err));
  };

  //Lastly, send local weather data back to GlobalContext's
  useEffect(() => {
    context.onTemperature(temperature)
    context.onRadiance(radiance)
    context.onIlluminance(illuminance)
    context.onHumidity(humidity)
    context.onWindSpeed(windSpeed)
    context.onCloudCover(cloudCover)
  }, [context, temperature, radiance, illuminance, humidity, windSpeed, cloudCover])
  
  //Convert temperature from array to object so charting library can work its magic. 
  const dailyTemperature = temperature.map((value, key ) => key > 0 ? ({ temperature: value }) : ({temperature: -1}));
  const dailyIlluminance = illuminance.map((value, key) => key > 0 ? ({illuminance: value}) : ({illuminance: -1}))

  //Next, save to localstorage otherwise, data is undefined.
  useEffect(() => {
    localStorage.setItem("dailytemperature", JSON.stringify(dailyTemperature))
    localStorage.setItem("dailyilluminance", JSON.stringify(dailyIlluminance))
  }, [dailyTemperature, dailyIlluminance]);


  return <>
    <div className="flex flex-wrap justify-evenly w-full">
      <div className="w-1/5 bg-blue-300 rounded my-4 shadow">
        <div className="flex justify-around ">
          <label className=" py-3 text-3xl" htmlFor="override">
            Location
          </label>
        </div>
      </div>

      <Session subMenu={subMenu} values={values} dropDownOption={context.dropDownOption} onDropDownOption={context.onDropDownOption}/>
    </div>
    
    {/* Control Menu  */}
    <div className="ml-auto mr-auto flex justify-evenly w-5/6 h-5/6 ">
      <div className="w-1/5 px-5 py-2 rounded bg-gradient-to-b from-gray-50 to-gray-200">
        <p className="flex justify-center">Control Menu </p>
        
        {/* Zipcode lookup */}
        <form onSubmit={e => handleGetWBANByZip(e, context.zipcode)}>
          <div className="flex justify-evenly py-3">
            <label className="px-2 font-semibold" htmlFor="zipcode">
              Zipcode Lookup:{" "}
            </label>
            <input
              size="5"
              className="border"
              type="text"
              id="zipcode"
              value={context.zipcode}
              onChange={e => context.onZipcodeLookup(e.target.value)}
            ></input>
          </div>
          <div className="flex justify-evenly py-3">
            <button className="SAVE" type="submit">
              Find
            </button>
          </div>
        </form>
        
        <hr className="border-gray-500" />
        <span className="flex justify-center">OR</span>
        <hr className="border-gray-500" />

        {/* City, State Lookup */}
        
        <form
          onSubmit={e =>
            handleGetWBANByCityState(e, context.city, context.state)
          }
        >
          <label className="px-2 font-semibold" htmlFor="state">State Lookup:{" "}</label>
          <div className="flex justify-evenly py-3">
            <select
              id="state"
              className="border-black"
              name="type"
              value={context.state}
              onChange={e => context.onStateLookup(e.target.value)}
            >
              <option>Select One</option>
              {states.map((state) => <option>{state}</option>)}
              
              
            </select>
          </div>
         
          <label className="px-2 font-semibold" htmlFor="city">City Lookup:{" "}</label>
          <div className="flex justify-evenly py-3">
            <select
              id="city"
              className="border-black"
              name="type"
              value={context.city}
              onChange={e => context.onCityLookup(e.target.value)}
            >
              <option>Select One</option>
              {cityData.map(data => context.state === data["State"] && <option>{data['City']}</option>)}
            </select>
          </div>

          <div className="flex justify-evenly py-3">
            <button className="SAVE" type="submit">
              Find
            </button>
          </div>
        </form>
        <hr className="border-blue-500 border-dashed" />

        <div className="flex justify-evenly py-3">
          <p className="px-2 font-semibold">Latitude: </p>
          <p className="px-2 font-semibold">{context.position[0].toFixed(2)} </p>
        </div>

        <div className="flex justify-evenly py-3">
          <p className="px-2 font-semibold">Longitude: </p>
          <p className="px-2 font-semibold">{context.position[1].toFixed(2)} </p>
        </div>

        <div className="flex justify-evenly py-3">
          <p className="px-2 font-semibold">Elevation ({context.global.isMetric ? 'm' : 'ft'}): </p>
          <p className="px-2 font-semibold">{context.elevation} </p>
        </div>
      </div>

      {/* Data visualization */}
      <div className="w-3/4">
          
        {/* Tab Menu */}
        <div className="flex flex-wrap">
          <div className="w-3/5 ml-auto mr-auto">  
            <div className="mx-2 my-2 flex flex-wrap justify-around">
              <button key="gS" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="map">Map</button>
              
              <button key="gM" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="dtr">Daily Temperature Range</button>
              
              <button key="aI" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="dli">Daily Light Integral</button>
            </div>
          </div>
        </div>
        
        {subMenu === 'map' ? <MapChart values={values}/>
        : subMenu === 'dtr' ? <DailyTemperature />
        : subMenu === 'dli' ? <DailyLightIntergal />
        : <MapChart values={values}/>}  
        
      </div>
    </div>
  </>
}

export default Location
