const DATABASE_NAME = 'VIRTUALGROWER_DB'
const DATABASE_VERSION = 2

const onRequestError = (e) => {
  console.log('Database Error:', e);
}

const DB = () => {
    const request = indexedDB.open(DATABASE_NAME, DATABASE_VERSION)

    if (!('indexedDB' in window)) {console.log("This browser doesn't support IndexedDB"); return;}    
             
    request.onsuccess = () => { console.log('DB Connected!')}
    request.onerror = (event) => {console.log("An oopsie happened: ", event)} 
    request.onupgradeneeded = (event) => {
        const db = event.target.result
        db.createObjectStore('virtualGrower', {keypath: 'listName', autoIncrement: true }) //autoIncrement must be set to true
    }  
    
    return request;
}

///////////////////////////////////////
export const get = (callback) => {
    const request = DB();
    request.onerror = onRequestError
    request.onsuccess = (e) => {
        const db = e.target.result
        const transaction = db.transaction(['virtualGrower'], 'readonly')
        const store = transaction.objectStore('virtualGrower')
        store.getAll().onsuccess = (ev) => {callback(ev.target.result)}
    }
  }

export const getOne = (lookup, callback) => {
    const request = DB();
    request.onerror = onRequestError
    request.onsuccess = (e) => {
        const db = e.target.result
        const transaction = db.transaction(['virtualGrower'], 'readonly')
        const store = transaction.objectStore('virtualGrower')
        const getRequest = store.get(lookup)
        getRequest.onsuccess = () => callback(getRequest.result)
    }
}

export const getSelection = (key, callback) => {
    const request = DB();
    request.onerror = onRequestError
    request.onsuccess = (e) => {
        const db = e.target.result
        const transaction = db.transaction(['virtualGrower'], 'readonly')
        const store = transaction.objectStore('virtualGrower')
        const getRequest =  store.get(key)
        getRequest.onsuccess = () => callback(getRequest.result.plantSelection)
    }
}

export const save = (values, options) => {
    const request = DB();
    request.onerror = onRequestError;
    request.onsuccess = (e) => {
        const db = e.target.result;
        const transaction = db.transaction(['virtualGrower'], 'readwrite');
        const store = transaction.objectStore('virtualGrower');

        if(options === 'SAVE') store.add({...values}, values.listName) //must include keypath option to eliminate duplicates
        if(options === 'UPDATE') store.put({...values}, values.listName)
    }
}

export const erase = (id) => {
    const request = DB();
    request.onerror = onRequestError;
    request.onsuccess = (e) => {
        const db = e.target.result;
        const transaction = db.transaction(['virtualGrower'], 'readwrite');
        const store = transaction.objectStore('virtualGrower');
        store.delete(id)
    }
}

export const reset = () => {indexedDB.deleteDatabase(DATABASE_NAME)}