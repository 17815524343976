export const heatingSysSetupImages = [
    {
        id: 0,
        name: 'Forced Air',
        image: 'ForcedAir.jpg'
    },{
        id: 1,
        name: 'Air Above Bench',
        image: 'AirAboveBench.jpg'
    },
    {
        id: 2,
        name: 'Air Below Bench',
        image: 'AirBelowBench.jpg'
    },
    {
        id: 3,
        name: 'Tubes - Above Bench',
        image: 'TubesAboveBench.jpg'
    },
    {
        id: 4,
        name: 'Tubes - Below Bench',
        image: 'TubesBelowBench.jpg'
    },
    {
        id: 5,
        name: 'Steam Pipes',
        image: 'SteamPipes.jpg'
    },
    {
        id: 6,
        name: 'Perimeter',
        image: 'PipesPerimeter.jpg'
    },
    {
        id: 7,
        name: 'Below Bench',
        image: 'PipesBelowBench.jpg'
    },
    {
        id: 8,
        name: 'Finned - Perimeter',
        image: 'FinnedPipesPerimeter.jpg'
    },
    {
        id: 9,
        name: 'Finned - Below Bench',
        image: 'FinnedPipesBelowBench.jpg'
    },
    {
        id: 10,
        name: 'Heated Floors',
        image: 'HeatedFloors.jpg'
    },
    {
        id: 11,
        name: 'Insulated',
        image: 'FloorInsulated.jpg'
    },
    {
        id: 12,
        name: 'Not Insulated',
        image: 'FloorNotInsulated.jpg'
    },
    {
        id: 13,
        name: 'Unit Heater',
        image: 'Unit Heater.jpg'
    },
    {
        id: 14,
        name: 'Hot Water Boiler',
        image: 'Boiler.jpg'
    },
    {
        id: 15,
        name: 'Steam Boiler',
        image: 'steam boiler.jpg'
    },
    {
        id: 16,
        name: 'High Efficiency Unit Heater',
        image: 'High Efficiency Unit Heater.jpg'
    },
    {
        id: 17,
        name: 'Gravity Ventilation',
        image: 'Gravity Vented.jpg'
    },
    {
        id: 18,
        name: 'Powered Ventilation',
        image: 'Power Vented.jpg'
    },
    {
        id: 19,
        name: 'Condensing',
        image: 'Condensing Boiler.jpg'
    },
    {
        id: 20,
        name: 'Separated Combustion',
        image: 'Separate.jpg'
    },
     {
        id: 21,
        name: 'Hot Water Pipe',
        image: 'HotWaterPipes.jpg'
    }

]