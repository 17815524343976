export const materials = [
  {
    "Primary_Key": 1,
    "Material_Name": "Glass",
    "Heat_Transfer": "1.13",
    "Light_Transmit": "0.75",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 2,
    "Material_Name": "Glass Double Layer",
    "Heat_Transfer": "0.65",
    "Light_Transmit": "0.7",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 3,
    "Material_Name": "Fiber Glass",
    "Heat_Transfer": "1.0",
    "Light_Transmit": "0.75",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 4,
    "Material_Name": "Corrugated Polycarbonate",
    "Heat_Transfer": "1.2",
    "Light_Transmit": "0.75",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 5,
    "Material_Name": "Polyethylene",
    "Heat_Transfer": "1.15",
    "Light_Transmit": "0.65",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 6,
    "Material_Name": "Polyethylene Double Layer",
    "Heat_Transfer": "0.7",
    "Light_Transmit": "0.6",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 7,
    "Material_Name": "Polycarbonate Bi-Wall",
    "Heat_Transfer": "0.65",
    "Light_Transmit": "0.6",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 8,
    "Material_Name": "Polycarbonate Triple-Wall",
    "Heat_Transfer": "0.58",
    "Light_Transmit": "0.56",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 9,
    "Material_Name": "Acrylic Bi-Wall",
    "Heat_Transfer": "0.65",
    "Light_Transmit": "0.6",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 10,
    "Material_Name": "IR Film",
    "Heat_Transfer": "1.0",
    "Light_Transmit": "0.65",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 11,
    "Material_Name": "IR Film Double Layer",
    "Heat_Transfer": "0.61",
    "Light_Transmit": "0.6",
    "Material_Type": "1",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 12,
    "Material_Name": "Concrete Block",
    "Heat_Transfer": "0.51",
    "Light_Transmit": "0.0",
    "Material_Type": "2",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 13,
    "Material_Name": "Concrete Poured",
    "Heat_Transfer": "0.75",
    "Light_Transmit": "0.0",
    "Material_Type": "2",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 14,
    "Material_Name": "Concrete Insulated",
    "Heat_Transfer": "0.13",
    "Light_Transmit": "0.0",
    "Material_Type": "2",
    "Air_Exchange": "0.0",
    "Description": ""
  },
  {
    "Primary_Key": 15,
    "Material_Name": "Mixed",
    "Heat_Transfer": "0.0",
    "Light_Transmit": "0.0",
    "Material_Type": "4",
    "Air_Exchange": "0.0",
    "Description": "Used in basic builder only"
  },
  {
    "Primary_Key": 16,
    "Material_Name": "Mobile Air Curtain",
    "Heat_Transfer": "0.68",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "1.5",
    "Description": ""
  },
  {
    "Primary_Key": 17,
    "Material_Name": "Stationary Air Curtain",
    "Heat_Transfer": "0.54",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "1.5",
    "Description": ""
  },
  {
    "Primary_Key": 18,
    "Material_Name": "White Spun Bonded Polyolefin Film",
    "Heat_Transfer": "0.51",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "0.7",
    "Description": ""
  },
  {
    "Primary_Key": 19,
    "Material_Name": "Grey Spun Bonded Polyolefin Film (Light Weight)",
    "Heat_Transfer": "0.56",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "1.5",
    "Description": ""
  },
  {
    "Primary_Key": 20,
    "Material_Name": "Clear Polyethylene Film",
    "Heat_Transfer": "0.45",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "0.4",
    "Description": ""
  },
  {
    "Primary_Key": 21,
    "Material_Name": "Black Polyethylene Film",
    "Heat_Transfer": "0.48",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "0.4",
    "Description": ""
  },
  {
    "Primary_Key": 22,
    "Material_Name": "Grey Spun Bonded Polyolefin Film (Heavy Weight)",
    "Heat_Transfer": "0.43",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "1.0",
    "Description": ""
  },
  {
    "Primary_Key": 23,
    "Material_Name": "Aluminum Foil - Clear Vinyl Film Laminate",
    "Heat_Transfer": "0.4",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "0.7",
    "Description": ""
  },
  {
    "Primary_Key": 24,
    "Material_Name": "Aluminum Fabric",
    "Heat_Transfer": "0.39",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "0.9",
    "Description": ""
  },
  {
    "Primary_Key": 25,
    "Material_Name": "Aluminum Foil - Black Vinyl Film Laminate",
    "Heat_Transfer": "0.63",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "0.7",
    "Description": ""
  },
  {
    "Primary_Key": 26,
    "Material_Name": "Double Layer Spun Bonded Polyester",
    "Heat_Transfer": "0.53",
    "Light_Transmit": "0.0",
    "Material_Type": "5",
    "Air_Exchange": "1.0",
    "Description": ""
  },
  {
    "Primary_Key": 27,
    "Material_Name": "Twin-wall HDPE (Solexx, 3.5 mm)",
    "Heat_Transfer": "0.47",
    "Light_Transmit": "0.64",
    "Material_Type": "1",
    "Air_Exchange": "0",
    "Description": ""
  },
  {
    "Primary_Key": 28,
    "Material_Name": "Twin-wall HDPE (Solexx, 5 mm)",
    "Heat_Transfer": "0.43",
    "Light_Transmit": "0.62",
    "Material_Type": "1",
    "Air_Exchange": "0",
    "Description": ""
  },
  {
    "Primary_Key": 29,
    "Material_Name": "Solid Insulation Foam",
    "Heat_Transfer": "0.23",
    "Light_Transmit": "0.0",
    "Material_Type": "2",
    "Air_Exchange": "0.0",
    "Description": "Must be a solid foam, used for kneewalls"
  }
]