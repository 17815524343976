import {useContext} from 'react'
import { GlobalContext } from '../../hooks/GlobalContext';


const oneGrayBox = "bg-gray-200 p-5 my-5 ml-auto mr-auto w-2/3 flex justify-evenly shadow"

function InsideLightingThreshold(props) {
    const {LUDropDown, lightingUnit} = props
    const context = useContext(GlobalContext) 
    
    return <>
        <div className={oneGrayBox}>
            <label className="label" htmlFor="begin-light-schedule">Inside Light Threshold ({lightingUnit[0]})</label>
            <input className="border-2 border-gray-200" key={props.index} value={context.uValue} onChange={e => context.onUValueChange(e.target.value)} id="hourly-temp"  size="1"></input> 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
            <label>{context.cValue} {LUDropDown}</label>    
        </div>
    </>
}

export default InsideLightingThreshold
