import {useState, useEffect, useContext} from 'react'
import { GlobalContext } from '../hooks/GlobalContext';
import DropDownList from '../hooks/DropDownList'
import Session from '../hooks/Session';
import SingleCard from './SingleCard';
import ViewSelection from './ViewSelection';
import { getSelection } from '../hooks/Database';


import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

 /**
 * FIXED DESIGN FLOW:
 *  The View Selection is the main show. Users can manipulate the view selection with the following tools:
 *      1. Reload - View Selection items do not auto populate and must be reloaded back into the system (for 2 or more lists)
 *      2. Clear - Clears view Selection. (Start fresh)
 *      3. Trash Icon (Remove) - Removes single item from View Selection
 *      
 * It is important to keep track as well as update/save your data.
 * 
 */
    
const Plants = (props) => {
    const {plantData} = props
    const context = useContext(GlobalContext)


    useEffect(() => {
         context.global.onComponent("Plants");
    }, [context.global]);

    const [currentPage, setCurrentPage] = useState(1)
    const [plantPerPage] = useState(16)
    
    const handlePageClick = (e) => {
        setCurrentPage(Number(e.target.id))
    }

    // Logic for displaying plants
    const indexOfLastPlant = currentPage * plantPerPage;
    const indexOfFirstPlant = indexOfLastPlant - plantPerPage;
    const currentPlant = plantData.slice(indexOfFirstPlant, indexOfLastPlant);

  
    // Logic for displaying page numbers
    const pages = [];
    for (let i = 1; i <= Math.ceil(plantData.length / plantPerPage); i++) {
      pages.push(i);
    }
   
    const renderPageNumbers = pages.map(number => 
        <li 
            className={`px-2 mx-1 border-2 rounded  
                ${currentPage === number ? 'bg-white border-gray-600 text-gray-600' : 'bg-gray-500 border-gray-600 text-white'}`} 
            key={number} id={number} onClick={handlePageClick}> {number}
        </li>
    );
        
    // Render Card popup when single plant is clicked from currentPlant list
    const renderPlant = () =>  currentPlant.map((info) => {
        
        return <>
            <div className="mx-2 rounded overflow-hidden shadow-md bg-white">
                
                <div>
                    <img className="w-full" key={info.id} src={process.env.PUBLIC_URL + `/images/Plants/${info.image}`} alt={info.Common_Name} onClick={handleCardPopupClick} id={info.id} />
                    <span className="block px-1 font-bold">{info.Common_Name}</span>
                    <span className="block p-1 italic text-gray-500 text-sm">{info.ScientificName}</span>
               </div>
            </div>
        </>
    })

    // Logic for Popup Card (http://fraina.github.io/react-popupbox/) 
    const handleCardPopupClick = (e) => {
        const content = (
            currentPlant.map((info) => {
                return <>
                    {e.target.id === info.id ? 
                        <SingleCard key={info.id} info={info} PopupboxManager={PopupboxManager} />
                    : null }
                </>
            })
        )
        PopupboxManager.open({ content })
    }
    

    const handleRemoveButton = (e) => {
        context.onRemoveSelection(e)
        PopupboxManager.close()
    }

    const handleReloadButton = (value) => {
        context.onViewSelection(value, context.dropDownOption, 'reload')
        context.onFlagged(value, 'reload')
        PopupboxManager.close()
    }

    const handleClearButton = () => {
        context.onViewSelection([], context.dropDownOption, 'clear')
        context.onFlagged([], 'clear')
        PopupboxManager.close()
    }
   
    useEffect(() => {
        try {
            if(context.dropDownOption !== null && context.dropDownOption !== 'Select One') getSelection(context.dropDownOption, data => context.onPlaceholder(data))     
        } catch (error) {console.log(error)}    
    },[context.dropDownOption, context])  

    const handleViewSelection = () => {        
        const content = (
            <div className="flex flex-row flex-wrap">
                {context.viewSelection.map((info) => <ViewSelection info={info} onRemoveButton={handleRemoveButton} id={info.id}></ViewSelection>)}
                <div className='w-1/2'>
                    <button className='DEFAULT_E' onClick={() => handleReloadButton(context.placeholder)}> Reload </button>
                    
                    <button className='RESETALL' onClick={() => handleClearButton()}> Clear </button>
                </div>
            </div>
        )
        
        PopupboxManager.open({ content })
    }
    
    const values = {
        subMenu: context.global.component,
        plantSelection: context.viewSelection,
        flagged: context.flagged,
        listName: context.dropDownOption,
    }

    // Header Menu
    return <>
        <div className="flex flex-wrap justify-evenly w-full">
            <div className="w-1/6 bg-green-300 rounded my-4 shadow">
                <div className="flex justify-around">
                    <label className=" py-4 text-3xl" htmlFor="override">Plants</label>
                </div> 
            </div>

            {(context.dropDownOption !== null && context.dropDownOption !== 'Select One') && <Session values={values} />}

            <DropDownList subMenu={context.global.component} localComponent={context.global.component} />
                       
            <div className="w-1/6 bg-transparent rounded my-4 shadow px-3 pb-1">
                <p className="text-2xl text-center">Total: {plantData.length} plants</p>
                <div className="m-2 flex flex-wrap justify-around">
                    <ul className="flex flex-wrap items-center" id="page-numbers px-3">
                        <p className="label">Page:</p>
                            {renderPageNumbers}
                    </ul>
                </div>
            </div>
        </div>
        <div className="flex flex-wrap">
            <div className="w-1/4 ml-auto mr-auto">
                <div className="m-2 flex flex-wrap justify-around">
                    <button onClick={e => handleViewSelection(context.placeholder)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">View Selection</button>
                </div>
            </div>
            <div className="w-4/5 ml-auto mr-auto m-1">
                <div className="grid grid-cols-8 gap-8">
                    {renderPlant(plantData)}
                </div>      
            </div>  
        </div>

        <PopupboxContainer  />
    </>
}
 
export default Plants;