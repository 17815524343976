import {useState, useEffect} from 'react'
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

function DeliveryMethod(props) {
    const {hsImages, htOptions, dmo, onDeliveryMethodChange, dmUnitHeater, dmHotH2OBoiler, dmSteamBoiler} = props
    
    const [option, setOption] = useState([])
    const [imageLookup, setImageLookup] = useState([{name:'', image: ''}])   


    useEffect(() => {
        if (htOptions !== undefined)
            if (htOptions === 'Unit Heater' || htOptions === 'High Efficiency Unit Heater') setOption(dmUnitHeater)
            else if (htOptions === 'Hot Water Boiler') setOption(dmHotH2OBoiler)
            else if (htOptions === 'Steam Boiler') setOption(dmSteamBoiler)
        else {
            setOption([])
            setImageLookup([{name:'', image: ''}])
        }
        
    }, [htOptions, dmUnitHeater, dmHotH2OBoiler, dmSteamBoiler])
    
    useEffect(() => {
        hsImages.map((p) => {
            if (p.name === dmo){
                setImageLookup([{name: p.name, image: p.image}])
            }
            return null;
        })
    }, [hsImages, dmo])

    // Logic for Popup Card (http://fraina.github.io/react-popupbox/) 
    const handleCardPopupClick = (e) => {
        const content = (
            imageLookup.map((x, id) => <img key={id} src={process.env.PUBLIC_URL + `/images/HeatingEfficiency/${x.image}`} alt={x.name}/> )
        )
        PopupboxManager.open({ content })
    }

    return <>
       <div className="py-3 w-2/5" >  
            <div className="flex flex-wrap justify-evenly m-1">
                <label className="label" htmlFor="dMethod">Delivery Method: </label>
                <select className="border-black" id="dMethod" name="type" onChange={e => onDeliveryMethodChange(e.target.value)}>
                    <option>Select One</option>
                    {option.map((v, index) => htOptions !== undefined ? htOptions === 'Select One' ? null : <option key={index} name={v}>{v}</option>: 'Select One')}
                </select>
                {hsImages.filter((x) => x.name === htOptions).map((x) => <svg xmlns="http://www.w3.org/2000/svg" id={x.id} className="w-6 h-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={handleCardPopupClick}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>)}
            </div>
        </div>

        <PopupboxContainer />
    </>
}

export default DeliveryMethod
