export const fuelInformation = [
  {
    Primary_Key: "1",
    Fuel_Name: "Oil 2",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "138500.0",
    Estimated_Cost: "4.4000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "2",
    Fuel_Name: "Oil 4",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "145000.0",
    Estimated_Cost: "4.8000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "3",
    Fuel_Name: "Oil 6",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "153000.0",
    Estimated_Cost: "4.9000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "4",
    Fuel_Name: "Natural Gas",
    Units_of_Measurement: "therm",
    Metric: "cu m",
    BTUs: "100000.0",
    Estimated_Cost: "2.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "5",
    Fuel_Name: "Propane",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "92500.0",
    Estimated_Cost: "2.4000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "6",
    Fuel_Name: "Coal Hard",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "26000000.0",
    Estimated_Cost: "135.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "7",
    Fuel_Name: "Coal Soft",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "24000000.0",
    Estimated_Cost: "130.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "8",
    Fuel_Name: "Electricity",
    Units_of_Measurement: "kWh",
    Metric: "kJ",
    BTUs: "3412.0",
    Estimated_Cost: ".1300",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "9",
    Fuel_Name: "Butane",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "130000.0",
    Estimated_Cost: "1.6000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "10",
    Fuel_Name: "Ethanol",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "76000.0",
    Estimated_Cost: "2.8200",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "11",
    Fuel_Name: "Gasoline",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "125000.0",
    Estimated_Cost: "4.0900",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "12",
    Fuel_Name: "Kerosene",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "135000.0",
    Estimated_Cost: "4.1700",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "13",
    Fuel_Name: "Methanol",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "57000.0",
    Estimated_Cost: "5.4000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "14",
    Fuel_Name: "BioDiesel",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "120000.0",
    Estimated_Cost: "3.6500",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "15",
    Fuel_Name: "Waste Oil",
    Units_of_Measurement: "gallon",
    Metric: "Liter",
    BTUs: "125000.0",
    Estimated_Cost: "3.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "16",
    Fuel_Name: "Landfill Gas",
    Units_of_Measurement: "cu ft",
    Metric: "cu m",
    BTUs: "500.0",
    Estimated_Cost: ".0100",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "17",
    Fuel_Name: "Methane",
    Units_of_Measurement: "cu ft",
    Metric: "cu m",
    BTUs: "1000.0",
    Estimated_Cost: ".0200",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "18",
    Fuel_Name: "Sawdust Dry",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "16000000.0",
    Estimated_Cost: "60.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "19",
    Fuel_Name: "Sawdust Green",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "9000000.0",
    Estimated_Cost: "50.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "20",
    Fuel_Name: "Wood Hard",
    Units_of_Measurement: "cord",
    Metric: "cu m",
    BTUs: "21000000.0",
    Estimated_Cost: "175.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "21",
    Fuel_Name: "Wood Soft",
    Units_of_Measurement: "cord",
    Metric: "cu m",
    BTUs: "12500000.0",
    Estimated_Cost: "130.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "22",
    Fuel_Name: "Wood Hogged",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "18000000.0",
    Estimated_Cost: "140.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "23",
    Fuel_Name: "Wood Chips 45",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "7600000.0",
    Estimated_Cost: "60.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "24",
    Fuel_Name: "Wood Pellets 10",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "16000000.0",
    Estimated_Cost: "150.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "25",
    Fuel_Name: "Bark",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "9750000.0",
    Estimated_Cost: "85.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "26",
    Fuel_Name: "Corn Cobs",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "16500000.0",
    Estimated_Cost: "90.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "27",
    Fuel_Name: "Corn Shelled",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "16000000.0",
    Estimated_Cost: "85.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "28",
    Fuel_Name: "Plastic",
    Units_of_Measurement: "lb",
    Metric: "kilo",
    BTUs: "19000.0",
    Estimated_Cost: ".5000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "29",
    Fuel_Name: "Rubber Pellets",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "33000000.0",
    Estimated_Cost: "150.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "30",
    Fuel_Name: "Switchgrass",
    Units_of_Measurement: "ton",
    Metric: "metric ton",
    BTUs: "11625000.0",
    Estimated_Cost: "60.0000",
    Description_of_Fuel: ""
  },
  {
    Primary_Key: "102",
    Fuel_Name: "test",
    Units_of_Measurement: "kWh",
    Metric: "kJ",
    BTUs: "5603.0",
    Estimated_Cost: "63.0",
    Description_of_Fuel: ""
  }
]