import {useEffect} from 'react'

import DeliveryMethod from './DeliveryMethod'
import Variations from './Variations'
import Ventilation from './Ventilation'

import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

const DOCUMENT_TITLE = "System Setup"
let oneGrayBox = "bg-gray-200 p-3 m-2 w-3/4 ml-auto mr-auto flex justify-evenly shadow"

function HeatingSystemSetup(props) {
    const {onLocalComponent, useDocumentTitle, fuelInformation, heatingSysSetupImages, heaterAge, haOption, msOption, maintenanceSchedule, heaterTypeOption, dmUnitHeater, dmHotH2OBoiler, dmSteamBoiler, varForcedAir, varH2OSteamPipes,varHeatedFloors, fuelTypeOptions, fuelPrice, localHeaterEfficiencyToggle, localHeaterEfficiencyInput, ventilationOption, deliveryMethodOption, variationOptions, isFuelPriceChecked, globalHeaterEfficiencyToggle,  globalHeaterEfficiencyInput, onHeaterTypeOption, onFuelTypeOptions, onFuelPrice, onLocalHeaterEfficiencyOverrideClick, onLocalHeaterEfficiencyInput, onVentilationChange, onDeliveryMethodChange,onVariationOptionsChange, onIsFuelPriceChecked, onHeaterAgeOption, onMSOption, onGlobalHeaterEfficiencyOverrideClick, onGlobalHeaterEfficiencyInput, } = props
    
    useDocumentTitle(DOCUMENT_TITLE)
    useEffect(() => {
         onLocalComponent(DOCUMENT_TITLE);
    }, [onLocalComponent]);

    
    //This breaks things. Need to fix
    // useEffect(() => {
    //     console.log(globalHeaterEfficiencyToggle)
    //     if(globalHeaterEfficiencyToggle) {
    //         if (heaterTypeOption === 'Hot Water Boiler') onGlobalHeaterEfficiencyInput(49)
    //         else if (heaterTypeOption === 'High Efficiency Unit Heater') onGlobalHeaterEfficiencyInput(51)
    //     }

    // }, [heaterTypeOption])


     // Logic for Popup Card (http://fraina.github.io/react-popupbox/) 
     const handleImagePopupClick = () => {
        const content = (
            heatingSysSetupImages.map((x) => x.name === heaterTypeOption ? <img id="heater-type"  key={x.id} src={process.env.PUBLIC_URL + `/images/HeatingEfficiency/${x.image}`} alt={x.name}/>  : '')
        )

        PopupboxManager.open({ content })
    }

    const handleCardPopupClick = () => {
        const content = (
            <div className="ml-auto mr-auto">
                <p>This is your estimated heating efficiency.  This efficiency will update as you progress through the efficiency estimator.  If you already know your overall heating efficiency, click on the override button and enter your value.</p>   
            </div>
        )

        PopupboxManager.open({ content })
    }
            
    return <>
            <div className="w-2/3 ml-auto mr-auto my-5 flex justify-around p-1 bg-red-300 rounded shadow">
                <label className="mt-1 label" htmlFor="schedule-type">To begin, please select a heater type: </label>
                <select className="border-black" id="heater" name="type" value={heaterTypeOption} onChange={e => onHeaterTypeOption(e.target.value)}>
                    <option>Select One</option>
                    {heatingSysSetupImages.filter((imgID) => imgID.id === 13 || imgID.id === 14 || imgID.id === 15 || imgID.id === 16).map((image) => <option>{image.name}</option>)}
                </select>    
                
                {heatingSysSetupImages.filter((x) => x.name === heaterTypeOption).map((x) => <svg xmlns="http://www.w3.org/2000/svg" id={x.id} className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" name="heater-type" onClick={handleImagePopupClick}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>)}
            </div>
    

            {/* Content */}
            <div className={oneGrayBox}>
                <div className="py-3 w-1/4 px-2 bg-red-100 rounded shadow">
                    <h1 className="text-2xl ">Energy Curtain Override</h1>   
                </div>
                <div className="py-3 w-3/5">  
                    <div className="flex flex-wrap justify-around">
                        <button 
                            className={`px-4 py-2 font-semibold  rounded shadow center 
                                ${localHeaterEfficiencyToggle ? 
                                    "text-gray-800 bg-white border border-gray-400 hover:bg-gray-100"
                                : 
                                    "bg-indigo-500 hover:bg-indigo-700 text-white"
                                } 
                            `}  
                            type="button" 
                            id="local-override" 
                            onClick={onLocalHeaterEfficiencyOverrideClick}>
                                {localHeaterEfficiencyToggle ? "Edit Current Heater Efficiency": "Lock value?"}
                            </button>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                        <input className={`border-black bg-white  ${localHeaterEfficiencyToggle ? 'cursor-not-allowed': ''}`} disabled={localHeaterEfficiencyToggle} name="override-value" value={localHeaterEfficiencyInput} onChange={e => onLocalHeaterEfficiencyInput(e.target.value)} id="local-override" type="text"  size="1"></input>
                            
                    </div>
                </div>
            </div>
            
            
            {heaterTypeOption !== 'Select One' ? 
                [<div className={oneGrayBox}>
                    <Ventilation 
                        hsImages={heatingSysSetupImages} 
                        htOptions={heaterTypeOption} 
                        ventilationOption={ventilationOption}
                        
                        onVentilationChange={onVentilationChange} 
                    />
                  
                   <DeliveryMethod 
                        hsImages={heatingSysSetupImages}
                        htOptions={heaterTypeOption} 
                        dmo={deliveryMethodOption} 
                        dmUnitHeater={dmUnitHeater}
                        dmHotH2OBoiler={dmHotH2OBoiler}
                        dmSteamBoiler={dmSteamBoiler} 

                        onDeliveryMethodChange={onDeliveryMethodChange} 
                    /> 

                    <Variations 
                        hsImages={heatingSysSetupImages}
                        dmo={deliveryMethodOption} 
                        varOptions={variationOptions} 
                        varForcedAir={varForcedAir}
                        varH2OSteamPipes={varH2OSteamPipes}
                        varHeatedFloors={varHeatedFloors}
                        
                        onVariationOptions={onVariationOptionsChange}
                    />
                </div>,
                
                <div className={oneGrayBox}>
                    <div className="py-3 w-3/5">  
                        <div className="flex flex-wrap justify-between m-1 px-3">
                            <label className="label" htmlFor="fuel-type">Fuel Type: </label>
                            <select 
                                className="border-black" 
                                id="fuel-type" 
                                name="type" 
                                value={fuelTypeOptions} 
                            onChange={e => onFuelTypeOptions(e.target.value)}>
                                <option>Select One</option>
                                {fuelInformation.map((option) => <option key={option.Primary_Key} value={option.Fuel_Name}>{option.Fuel_Name}</option>)}
                            </select>
                            
                            {fuelInformation.map((fuel) => fuel.Fuel_Name === fuelTypeOptions ? <p>$ <b>{parseFloat(fuel.Estimated_Cost).toFixed(2) }</b>  per ton</p> : <p></p>)}
                                
                            <label className="label" htmlFor="edit-value">Custom Fuel Price <input checked={isFuelPriceChecked} type="checkbox" onChange={onIsFuelPriceChecked} id="edit-value"></input></label>
                            $ <input className={`border-black bg-white  ${!isFuelPriceChecked ? 'cursor-not-allowed': ''}`} disabled={!isFuelPriceChecked } value={fuelPrice} onChange={e => onFuelPrice(e.target.value)} type="text" size="1"></input> 
                        </div>
                    </div>
                </div>,
            
                <div className={oneGrayBox}>
                    <div className="py-3 w-4/5">  
                        <div className="flex flex-wrap justify-between m-1 px-3">
                            <label className="label" htmlFor="ageOfHeater">Heater's Age: </label>
                            <select className="border-black" id="ageOfHeater" name="type" value={haOption} onChange={e => onHeaterAgeOption(e.target.value)}>
                                <option>Select One</option>
                                {heaterAge.map((v, index) => <option key={index} name={v}>{v}</option>)}
                            </select>
                                
                            <label className="label" htmlFor="maintainenceSchedule">Maintainence Schedule: </label>
                            <select className="border-black" id="maintainenceSchedule" name="type" value={msOption} onChange={e => onMSOption(e.target.value)}>
                            <option>Select One</option>
                            {maintenanceSchedule.map((v, index) => <option key={index} name={v}>{v}</option>)}
                        </select>
                        </div>
                    </div>
                </div>,
            
                <div className={oneGrayBox}>
                    <div className="py-3 w-2/5 bg-red-100 rounded shadow">
                        <h1 className="text-2xl">Heating System Efficiency Override</h1>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" name="global" onClick={handleCardPopupClick}>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    
                    <div className="py-3 w-3/5">  
                        <div className="flex flex-wrap justify-between m-1 px-3">
                            <button className={`px-4 py-2 font-semibold  rounded shadow center ${globalHeaterEfficiencyToggle ? "text-gray-800 bg-white border border-gray-400 hover:bg-gray-100": "bg-indigo-500 hover:bg-indigo-700 text-white"} `}  type="button" id="global-override" onClick={onGlobalHeaterEfficiencyOverrideClick}>{globalHeaterEfficiencyToggle ? "Edit Current Heating Efficiency": "Lock value?"}</button>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                            </svg>
                            <input className={`border-black bg-white  ${globalHeaterEfficiencyToggle ? 'cursor-not-allowed': ''}`} disabled={globalHeaterEfficiencyToggle} name="global-override" value={globalHeaterEfficiencyInput} onChange={(e) => onGlobalHeaterEfficiencyInput(e.target.value)} id="global-override" type="text"  size="1"></input>         
                        </div>
                    </div>
                </div>]
            : ''}
        <PopupboxContainer />
    </>
}

export default HeatingSystemSetup
