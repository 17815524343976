import {useMemo } from 'react'
import Plot from 'react-plotly.js'

const DailyLightIntergal = () => {
  const dailyilluminance = JSON.parse(localStorage.getItem('dailyilluminance'))
   /*
     * getDLI
     *   Loops through each day and computes the DLI. Illuminance is converted from lux to micromols(lux/54) and 
     *   then averaged for the day and multiplied by a constant to convert it to mols.
     */
  const getDLI = useMemo(() => {
    const dli = [365.0];
    let cnt = 0;
    for(let i = 0; i < 8760; i += 24)
    {
      let sum = 0.0;
      for(let j = i; j < i + 24; j++)
        if (j > 0) sum += dailyilluminance[j].illuminance / 54;
        dli[cnt] = Number(((sum * 3600 )/ 1000000).toFixed(2)); 
        cnt++; 
    }
    return dli;

  }, [dailyilluminance])

  const transformData = (data) => {
      let plot_data = [];
      let x = [];
      let y = [];
      data.map((value, id) => id > 0 && (x.push(id), y.push(value)))  // x = day, y = illuminity
      plot_data['x'] = x;
      plot_data['y'] = y;  

      return plot_data
  }
  
  return <>
    <Plot
      data = {[
          {type: 'scatter',
          mode: 'lines',
          x: transformData(getDLI)['x']-1, //start x-axis at 0
          y: transformData(getDLI)['y'],
          marker: { color: '#FFD700'},
          name: `(day, mol\u2219m\u207B\u00B2\u2219)`},          
        ]}
        
      layout = { {
        width: 1000, 
        height: 500, 
        title: 'Typical Daily Light Integral', 
        showlegend:false, 
        xaxis: { title: 'Day', linecolor: '#636363', linewidth: 3, tickmode:'linear', tick0:0, dtick:50}, 
        yaxis: { title: 'mol\u2219m\u207B\u00B2\u2219day\u207B\u00B9'} }}
      
      config = {{displayModeBar: false}}
      
    />
  </>
}

export default DailyLightIntergal