import {useEffect} from 'react'

const DOCUMENT_TITLE = "Setup"

function LightingSetup(props) {
    const {useDocumentTitle, onLocalComponent, lightingUnit, typeOfLights, LUDropDown, numberOfLights, TOLDropDown, efficacy, wattageOfLights, wattageOfBallasts, onLUDropDown, onNumberOfLights,  onTOLDropDown, onEfficacy, onWattageOfLights, onWattageOfBallasts} = props
    useDocumentTitle(DOCUMENT_TITLE)
    
    useEffect(() => {
         onLocalComponent(DOCUMENT_TITLE);
    }, [onLocalComponent]);


    let oneGrayBox = "bg-gray-200 p-5 m-1 w-2/3 ml-auto mr-auto flex justify-evenly shadow"

    
    return <>
            <div className={oneGrayBox}>
                <div className="m-5 py-3">
                    <label className="label" htmlFor="number-of-lights">Lighting Unit: </label>
                    <select className="border-2 border-gray-200" id="schedule-type" key="1" name="type" 
                        value={LUDropDown} onChange={e => onLUDropDown(e.target.value)}
                        >
                        {lightingUnit.map((option, id) => <option key={id} value={option}>{option} </option>)}    
                    </select>
                </div>
                <div className="m-5 py-3">
                    <label className="label" htmlFor="number-of-lights">Number of Lights: </label>
                    <input className="border-2 border-gray-200" id="number-of-lights" key="2" value={numberOfLights} onChange={e => onNumberOfLights(e.target.value)}  size="10"></input>                 
                </div>
            </div>

            <div className={oneGrayBox}>
                <div className="m-5 py-3">
                    <label className="label" htmlFor="type-of-lights">Type of Lights: </label>
                    <select className="border-2 border-gray-200" id="type-of-lights" key="3" name="type" 
                            value={TOLDropDown} onChange={e => onTOLDropDown(e.target.value)}
                            >
                            {typeOfLights.map((option, id) => <option key={id} value={option}>{option} </option>)}    
                        </select>
                </div>
                <div className="m-5 py-3">
                    <label className="label" htmlFor="efficacy">Efficacy <span className="italic text-red-900">(µmol/J)</span>: </label>
                    <input className="border-black" id="efficacy" key="5" value={efficacy} onChange={e => onEfficacy(e.target.value)}  size="5" />
                     <div className="flex justify-center my-2">
                         <p>Note, this value is typically from x to y</p>
                    </div>
                </div>
            </div>

           
            <div className={oneGrayBox}>
                <div className="m-5 py-3">
                    <label className="label" htmlFor="wattage-of-lights">Wattage of Lights: </label>
                    <input className="border-2 border-gray-200" id="wattage-of-lights" key="5" value={wattageOfLights} onChange={e => onWattageOfLights(e.target.value)}   size="5"></input> 
                </div>
                <div className="m-5 py-3">
                    <label className="label" htmlFor="wattage-of-ballasts">Wattage of Ballasts: </label>
                    <input 
                        className={`border-black 
                            ${TOLDropDown === typeOfLights[3] || TOLDropDown === typeOfLights[4] ? 
                                'cursor-not-allowed bg-gray-200'
                            : 
                                'bg-white'}
                            `}  
                        id="wattage-of-ballasts" 
                        key="6" 
                        value={wattageOfBallasts} 
                        onChange={e => onWattageOfBallasts(e.target.value)}
                        disabled={TOLDropDown === typeOfLights[3] || TOLDropDown === typeOfLights[4] ? 'true' : ''}
                        size="5" /> 
                </div>
            </div>
        

    </>
}

export default LightingSetup
