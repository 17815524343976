import {useState, useEffect, useContext} from 'react'

import { GlobalContext } from '../hooks/GlobalContext'
import DropDownList from '../hooks/DropDownList';
import Session from '../hooks/Session'
import useDocumentTitle from '../hooks/useDocumentTitle';

const Test = () => {
    const context = useContext(GlobalContext)
    useEffect(() => {
         context.global.onComponent("Test");
    }, [context.global]);
    // kale
    const calcEnergy = () => {
        const headerNames = ['', 'Heating (MMBtu)', 'Lighting (kWh)', 'Heating Cost ($)', 'Lighting Cost ($)', 'Total Cost ($)']
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let table = document.querySelector("#calcTable")
        for (var i = 0; i < 12; i++) {
            table.rows[i+1].cells[0].innerHTML = monthNames[i]
        }
        for (var i = 0; i < headerNames.length; i++) {
            table.rows[0].cells[i].innerHTML = headerNames[i]
        }
        // dimensions in feet
        const ghLen = context.length * 0.3048
        const ghWidth = context.width * 0.3048
        // this counts everything above gutters (e.g. triangular face above endwall)
        const roofArea = context.roofSurfaceArea * 0.3048 * 0.3048
        // endwalls
        // area above kneewall
        const ewSurfaceArea = context.ew1SurfaceArea * 0.3048 * 0.3048
        const ewKneeArea = context.ew1KneewallSurfaceArea * 0.3048 * 0.3048
        // sidewalls
        // area above kneewall
        const swSurfaceArea = context.sw1SurfaceArea * 0.3048 * 0.3048
        const swKneeArea = context.sw1KneewallSurfaceArea * 0.3048 * 0.3048
        const ghVolume = context.houseVolume * 0.3048 * 0.3048 * 0.3048
        const ghArea = ghLen * ghWidth

        // ACH
        const infiltration = context.currentAirInput
        const ventilation = 30

        // heating eff
        const heatingEff = context.globalHeaterEfficiencyInput / 100
        // fuel type
        // lighting power, efficacy, fixtures, control
        const numLuminaires = context.numberOfLights
        const lightingEfficacy = context.efficacy
        const luminairePower = context.wattageOfLights
        const lightThreshold = context.uValue
        const lightStart = parseInt(context.beginTime.substring(0, 2))
        const lightEnd = parseInt(context.endTime.substring(0, 2))
        // temp setpoints, convert F to C
        const constantTemp = (context.constantTemp - 32) / 1.8
        // const dayTemp = (context.dayTemp - 32) / 1.8
        // const nightTemp = (context.nightTemp - 32) / 1.8
        // const startDay = context.startDay
        // const startNight = context.startNight

        // fuel cost, elec cost
        // weather data
        let hrMonth = new Array(8761)
        let hrDay = new Array(8761)
        let hrHour = new Array(8761)
        let hourNum = 0
        let monthNum = 1
        let dayNum = 1
        for (var i = 1; i < 8761; i++) {
            hrHour[i] = hourNum
            hrDay[i] = dayNum
            hrMonth[i] = monthNum
            hourNum = (hourNum + 1) % 24
            if (hourNum == 0) {
                dayNum += 1
                if ((dayNum == 29 && monthNum == 2) ||
                    (dayNum == 31 && ((monthNum == 4) || (monthNum == 6) || (monthNum == 9) || (monthNum == 11))) ||
                    (dayNum == 32))
                {
                    monthNum += 1
                    dayNum = 1
                }
            }
        }
        let hrTemp = context.temperature
        // convert back to C
        for (var i = 1; i < 8761; i++) {
            hrTemp[i] = (hrTemp[i] - 32) / 1.8
        }
        // Wh/m2
        let hrRad = context.radiance
        let hrSolarPPFD = new Array(8761)
        // umol/m2/s = W/m2 * 2.0715
        for (var i = 1; i < 8761; i++) {
            hrRad[i] = hrRad[i] * 1.0
            hrSolarPPFD[i] = hrRad[i] * 2.0715
        }

        // alert(ventilation)
        // table.rows[1].cells[0].innerHTML = heatingEff
        // table.rows[4].cells[0].innerHTML = numLuminaires
        // table.rows[5].cells[0].innerHTML = lightingEfficacy
        // table.rows[6].cells[0].innerHTML = luminairePower
        // table.rows[5].cells[0].innerHTML = constantTemp
        // table.rows[6].cells[0].innerHTML = 0
        // table.rows[7].cells[0].innerHTML = context.WBAN
        // table.rows[8].cells[0].innerHTML = hrTemp[1]
        // table.rows[9].cells[0].innerHTML = hrRad[12]
        // table.rows[10].cells[0].innerHTML = hrSolarPPFD[12]
        // table.rows[1].cells[0].innerHTML = lightThreshold
        // table.rows[2].cells[0].innerHTML = lightStart
        // table.rows[3].cells[0].innerHTML = lightEnd

        // heating load = conduction + infiltration + ventilation - (radiation + internal gains)
        // internal gains = lighting + ET
        let hrHeating = new Array(8761)
        let hrLighting = new Array(8761)
        let monthHeating = new Array(13)
        let monthLighting = new Array(13)
        for (var i = 1; i < 13; i++) {
            monthHeating[i] = 0
            monthLighting[i] = 0
        }
        for (var i = 1; i < 8761; i++) {
            let deltaT = constantTemp - hrTemp[i]
            let Vdot = 0.0
            // infiltration
            // c = 0.33 Wh/m3/K
            // Qdot = c * Vdot(m3/h) * deltaT(K)
            Vdot = infiltration * ghVolume
            let Qinfiltration = 0.33 * Vdot * deltaT
            // ventilation55
            // c = 0.33 Wh/m3/K
            // Qdot = c * Vdot(m3/h) * deltaT(K)
            Vdot = ventilation * ghVolume
            let Qventilation = 0.33 * Vdot * deltaT
            // conduction
            // Qdot = U * A * deltaT
            let uValue = 5.8 // W/m2/K
            let glazingArea = roofArea + 2 * (ewSurfaceArea + swSurfaceArea + ewKneeArea + swKneeArea)
            let Qconduction = uValue * glazingArea * deltaT
            // radiation W
            let Qradiation = 0.7 * hrRad[i] * ghArea
            // lighting
            let lightsOn = false
            if ((hrHour[i] >= lightStart && hrHour[i] < lightEnd) &&
                (hrSolarPPFD[i] * 0.7 < lightThreshold)) {
                lightsOn = true
            }

            let Qlighting = 0.0
            if (lightsOn) {
                Qlighting = numLuminaires * luminairePower
                // kWh
                hrLighting[i] = numLuminaires * luminairePower / 1000.0
                // hrLighting[i] = 1
            }
            else {
                hrLighting[i] = 0.0
            }
            // ET
            let Qet = 0.0
            hrHeating[i] = Math.max(0, Qinfiltration + Qventilation + Qconduction - (Qradiation + Qlighting + Qet))
            hrHeating[i] /= heatingEff
            // Wh to MMBtu
            hrHeating[i] /= 293071
            monthHeating[hrMonth[i]] += hrHeating[i]
            monthLighting[hrMonth[i]] += hrLighting[i]
        }
        let annualHeating = 0.0
        let annualLighting = 0.0
        let annualHeatingCost = 0.0
        let annualLightingCost = 0.0
        let annualTotalCost = 0.0
        for (var i = 1; i < 13; i++) {
            table.rows[i].cells[1].innerHTML = (monthHeating[i]).toExponential(3)
            table.rows[i].cells[2].innerHTML = (monthLighting[i]).toExponential(3)
            // assume $1/therm
            let heatingCost = 1 * 1e5 * monthHeating[i] / 1e6
            // assume $0.05/kWh
            let lightingCost = 0.05 * monthLighting[i]
            let totalCost = heatingCost + lightingCost
            table.rows[i].cells[3].innerHTML = (heatingCost).toExponential(3)
            table.rows[i].cells[4].innerHTML = (lightingCost).toExponential(3)
            table.rows[i].cells[5].innerHTML = (totalCost).toExponential(3)
            annualHeating += monthHeating[i]
            annualLighting += monthLighting[i]
            annualHeatingCost += heatingCost
            annualLightingCost += lightingCost
            annualTotalCost += totalCost
        }
        table.rows[13].cells[0].innerHTML = "Total"
        table.rows[13].cells[1].innerHTML = (annualHeating).toExponential(3)
        table.rows[13].cells[2].innerHTML = (annualLighting).toExponential(3)
        table.rows[13].cells[3].innerHTML = (annualHeatingCost).toExponential(3)
        table.rows[13].cells[4].innerHTML = (annualLightingCost).toExponential(3)
        table.rows[13].cells[5].innerHTML = (annualTotalCost).toExponential(3)


    }
    const rows = 13
    const cols = 6

    return <>
        <div name="test" className="w-full h-screen flex justify-center items-center px-4 pt-8 pb-4">
		    <div className="max-w-[600px] mx-auto p-8 flex flex-col items-center justify-center w-full h-full sm:text-center">
                <table id="calcTable" className="table-auto border">
                    <thead>
                        <tr>
                            {Array.from({length: cols}, (_, id) => <th key={id} className="border p-2"></th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                    {Array.from({length: rows}, (_, rId) => {
                        return <tr key={rId}>
                            {Array.from({length: cols}, (_, cId) => <th key={cId} className="border p-2"></th>
                            )}
                        </tr>
                               
                            })}
                    </tbody>
                </table>
                <div>
                    <button key="calc" className="DEFAULT_E" onClick={calcEnergy} name="calcButton">Calculate</button>
                </div>
            </div>      
        </div>
	
  </>
}

export default Test
