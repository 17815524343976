import {useEffect} from 'react'
import Switch from 'react-switch'

import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"




const AirInfiltration = (props) => {
    const {useDocumentTitle, onLocalComponent, aiData, largeGap, smallGap, office, retailArea, shippingArea, headHouse, currentAirInput, currentAirToggle, malfunctioningToggle, gapsBetweenToggle, outsideIntakeToggle, oneGrayBox, gapOptions, insulationOptions, onLargeGap, onSmallGap, onOffice, onRetailArea, onShippingArea, onHeadHouse, onCurrentAirInput, onCurrentAirToggle, onMalfunctionToggle, onGapsBetweenToggle, onOutsideIntakeToggle } = props               
    
    useDocumentTitle("Air Infiltration")
    useEffect(() => {
         onLocalComponent("Air Infiltration");
    }, [onLocalComponent]);

     
    const handleCurrentAirExchangeClick = () => {
        onCurrentAirToggle(!currentAirToggle)
    }

    const handleImagePopupClick = (e) => {       
        const content = (
            e.currentTarget.name === 'largeGap' ? <img key={e.currentTarget.id} src={e.currentTarget.src} alt={e.currentTarget.alt}/> : 
            e.currentTarget.name === 'smallGap' ? <img key={e.currentTarget.id} src={e.currentTarget.src} alt={e.currentTarget.alt}/> :
            e.currentTarget.name === 'inAroundVents' ? <img key={e.currentTarget.id} src={e.currentTarget.src} alt={e.currentTarget.alt}/> : 
            e.currentTarget.name === 'separateRoom' ? <img key={e.currentTarget.id} src={e.currentTarget.src} alt={e.currentTarget.alt}/>  : '' 
        )

        PopupboxManager.open({ content })
    }

    const handleCardPopupClick = () => {
        const content = (
            <div className="ml-auto mr-auto">
                <p className="py-5">This is your current infiltration (air exchange) rate, number of exchanges an hour. As you update the wizard, the number will update. Advanced users can override the value and replace it with a value of their own.</p>     
            </div>
        )

        PopupboxManager.open({ content })
    }
    
    return <>
       {/* Content */}
        <div className="w-1/2 ml-auto mr-auto flex justify-around m-2">
            <label className=" text-3xl" htmlFor="local-override">Air Infiltration Rate Override</label>

            <button className={`px-4 py-2 font-semibold rounded shadow center ${currentAirToggle ? "border border-gray-400 hover:bg-gray-100": "bg-indigo-500 hover:bg-indigo-700 text-white"} `}  type="button" id="global-override" onClick={handleCurrentAirExchangeClick}>{currentAirToggle ? "Edit Current Air Exchange Rate": "Lock value?"}</button>

            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>

            <input className={`${currentAirToggle ? 'cursor-not-allowed': 'cursor-text'}`} disabled={currentAirToggle} name="local-override" value={currentAirInput} onChange={e => onCurrentAirInput(e.target.value)} id="local-override" type="text"  size="2"></input>    

            <sup>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" name="global" onClick={handleCardPopupClick}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> 
            </sup>
        </div> 
    
           
        {/* Gaps in Material & Structure */}
        <div className={oneGrayBox}>
            <div className="w-1/5 px-1 bg-green-300 rounded my-4 shadow">
                <h1 className="text-2xl ">Gaps in Material &amp; Structure</h1>   
            </div>
            
            {/* Large gaps */}
            <div className="w-2/5">  
                <div className="flex flex-wrap">
                    <div className="ml-auto mr-auto py-2 space-x-2">
                        <label className="label" htmlFor="large-gap" >How many large gaps?</label>
                        <select className="border-black" id="large-gap" name="type" value={largeGap} onChange={e => onLargeGap(e.target.value)}>
                            <option>Select One</option>
                            {gapOptions.map((option, key) => <option key={key} value={option}>{option} </option>)}
                        </select>
                    </div>
                    <div className="flex flex-wrap justify-center space-x-5">
                        {/* https://stackoverflow.com/questions/54033765/how-to-give-image-src-dynamically-in-react-js */}
                        {aiData.filter((imgID) => imgID.id === 1 || imgID.id === 2).map((image) => <img name="largeGap" id="large-gap" width="25%" key={image.id} src={process.env.PUBLIC_URL + `/images/AirInfiltration/${image.image}`} alt={image.name} onClick={handleImagePopupClick}/>)}  
                    </div>
                </div>
            </div>
            {/* Small gaps */}
            <div className="w-2/5">  
                <div className="flex flex-wrap">
                    <div className="ml-auto mr-auto py-2 space-x-2">
                        <label className="label" htmlFor="small-gap" >How many small gaps?</label>
                        <select className="border-black" id="small-gap" name="type" value={smallGap} onChange={e => onSmallGap(e.target.value)}>
                            <option>Select One</option>
                            {gapOptions.map((option, key) => <option key={key} value={option}>{option} </option>)}
                        </select>
                    </div>
                    <div className="flex flex-wrap justify-center space-x-5">
                        {aiData.filter((imgID) => imgID.id === 3 || imgID.id === 4).map((image) => <img name="smallGap" id="small-gap" width="25%" key={image.id} src={process.env.PUBLIC_URL + `/images/AirInfiltration/${image.image}`}  alt={image.name} onClick={handleImagePopupClick}/>)}
                    </div>
                </div>
            </div>
        </div>
        
        {/* Gaps in & around vents */}
        <div className={oneGrayBox}>
            <div className="w-1/5 px-1 bg-green-300 rounded my-4 shadow">
                 <h1 className="text-2xl ">Gaps in  &amp; Around Vents</h1>  
            </div>
           
            {aiData.filter((imgID) => imgID.id === 7).map((image) => {
                return <>
                    <div className="w-2/5">  
                        <div className="flex flex-wrap justify-evenly">
                            <div className="flex justify-center">
                                <img id="vent-gaps" width="30%" key={image.id} src={process.env.PUBLIC_URL + `/images/AirInfiltration/${image.image}`}  alt={image.name} name="inAroundVents" onClick={handleImagePopupClick}/>  
                            </div>
                            <div className="flex space-y-2 py-2">
                                <Switch className="react-switch" onChange={onMalfunctionToggle} checked={malfunctioningToggle} />
                            </div>
                        </div>
                    </div>
                </>
            })}  

            {aiData.filter((imgID) => imgID.id === 8).map((image) => {
                return <>
                    <div className="w-2/5  mx-3">  
                        <div className="flex flex-wrap justify-evenly">
                            <div className="flex justify-center">
                                <img id="vent-gaps" width="30%" key={image.id} src={process.env.PUBLIC_URL + `/images/AirInfiltration/${image.image}`}  alt={image.name} name="inAroundVents" onClick={handleImagePopupClick}/>  
                            </div>
                            <div className="flex space-y-2 py-2">
                                <Switch className="react-switch" onChange={onGapsBetweenToggle} checked={gapsBetweenToggle} />
                            </div>
                            
                        </div>
                    </div>
                </>
            })}  

            {aiData.filter((imgID) => imgID.id === 9).map((image) => {
                return <>
                    <div className="w-2/5 mx-3">  
                        <div className="flex flex-wrap justify-evenly">
                            <div className="flex justify-center">
                                <img id={image.id} width="30%" key={image.id} src={process.env.PUBLIC_URL + `/images/AirInfiltration/${image.image}`}  alt={image.name} name="inAroundVents" onClick={handleImagePopupClick}/>  
                            </div>
                            <div className="flex py-2">
                                <Switch className="react-switch" onChange={onOutsideIntakeToggle} checked={outsideIntakeToggle} />
                            </div>
                        </div>
                    </div>
                </>
            })}  
        </div>

        {/* Room Seperation */}
        <div className={oneGrayBox}>
            <div className="w-1/5 px-1 bg-green-300 rounded my-4 shadow">
                <h1 className="text-2xl ">Room Seperation</h1>   
            </div>
            
            <div className="w-3/5"> 
                <div className="flex flex-wrap justify-evenly">
                    <div className="w-1/3">
                        {aiData.filter((imgID) => imgID.id === 5).map((image) => {
                            return <>
                                <div className="space-x-3 flex flex-wrap justify-evenly my-5 space-y-3">
                                    <label className="label" htmlFor="insulated" >{image.name}</label>
                                    <img id="insulated" width="75%" key={image.id} src={process.env.PUBLIC_URL + `/images/AirInfiltration/${image.image}`}  alt={image.name} name="separateRoom" onClick={handleImagePopupClick}/>  
                                </div>    
                            </>
                        })}  
                    </div>
                    <div className="w-1/3">
                        {aiData.filter((imgID) => imgID.id === 6).map((image) => {
                            return <>
                                <div className="space-x-3 flex flex-wrap justify-evenly my-5 space-y-3">
                                    <label className="label" htmlFor="notInsulated" >{image.name}</label>
                                    <img id="notInsulated" width="75%" key={image.id} src={process.env.PUBLIC_URL + `/images/AirInfiltration/${image.image}`}  alt={image.name} name="separateRoom" onClick={handleImagePopupClick}/>  
                                </div>    
                            </>
                        })}  
                    </div>
                </div>
            </div>
            
            <div className="w-2/5">  
                <div className="flex flex-wrap justify-end py-3">
                    <div className="flex space-x-3">
                        <label className="label" htmlFor="office">Office:</label>
                        <select className="border-black" id="office" name="type" value={office} onChange={e => onOffice(e.target.value)}>
                            <option>Select One</option>
                            {insulationOptions.map((option, key) => {
                                return <option key={key} value={option}>{option} </option> 
                        })}
                        </select> 
                    </div>   
                </div>
                
                <div className="flex flex-wrap justify-end py-3">
                    <div className="flex space-x-3">
                        <label className="label" htmlFor="retail-area">Retail Area:</label>
                        <select className="border-black" id="retail-area" name="type" value={retailArea} onChange={e => onRetailArea(e.target.value)}>
                            <option>Select One</option>
                            {insulationOptions.map((option, key) => {
                                return <option key={key} value={option}>{option} </option> 
                        })}
                        </select> 
                    </div>   
                </div>
                
                <div className="flex flex-wrap justify-end py-3">
                    <div className="flex space-x-3">
                        <label className="label" htmlFor="shipping-area"> Shipping Area:</label>
                        <select className="border-black" id="shipping-area" name="type" value={shippingArea} onChange={e => onShippingArea(e.target.value)}>
                            <option>Select One</option>
                            {insulationOptions.map((option, key) => {
                                return <option key={key} value={option}>{option} </option> 
                        })}
                        </select> 
                    </div>   
                </div>

                <div className="flex flex-wrap justify-end pt-3">
                    <div className="flex space-x-3">
                        <label className="label" htmlFor="head-house">Head House:</label>
                        <select className="border-black" id="head-house" name="type" value={headHouse} onChange={e => onHeadHouse(e.target.value)}>
                            <option>Select One</option>
                            {insulationOptions.map((option, key) => {
                                return <option key={key} value={option}>{option} </option> 
                        })}
                        </select>  
                    </div>   
                </div>
            </div>
        </div>
        <PopupboxContainer />
    </>
}

export default AirInfiltration;