import {useContext} from 'react'
import { GlobalContext } from './GlobalContext';
import {save, erase} from './Database';

import { PopupboxManager } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

const DropDownList = (props) => {
    const {localComponent, subMenu} = props
    const context = useContext(GlobalContext)
   
    const handleDropDownList = (targetValue) => {
        if(targetValue === `Create New ${localComponent}`) {
            //Prompt user to name new list or use default name
            let listName = prompt("New List Name: ", `Rename Name`)

            //Automatically set option to user's list name
            context.onDropDownOption(listName)
            save({listName, subMenu}, 'SAVE')                
        } else {
            //if user decides to use a different list.
            context.onDropDownOption(targetValue)
        }

        //A popup will be used to show the list names alongside a trash bin to delete.
        if(targetValue === `Delete ${localComponent}`) {
            const content = <div className="flex flex-row flex-wrap">
            {context.db.data !== undefined && <table className="table">
                <thead><tr><th scope="col" key="LSHEADER">List Name</th></tr></thead>
                    <tbody>
                        {context.db.data.map((item) => item.subMenu === subMenu && 
                                <tr key={item.listName}>
                                    <td>{item.listName}</td>
                                    <td>
                                        <button onClick={(e) => handleRemoveButton(e, subMenu)} value={item.listName}>
                                            <svg className="h-5 w-5"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"/>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                             )}
                            
                        </tbody>
                </table>  
                }
            </div>
            
            PopupboxManager.open({ content })
        }    
    }

     const handleRemoveButton = (e) => {
        erase(e.currentTarget.value)
        context.onDropDownOption("Select One")
        context.onFlagged([])
        context.onViewSelection([])
        PopupboxManager.close()

    }
    
    return <div className="w-1/6 bg-transparent bg-gray-100 rounded my-4 shadow px-3 pb-1">
            <h4 className='flex justify-center text-lg'>Add New {localComponent} </h4>
            <select className="border-black" name="type" value={context.dropDownOption} onChange={e => handleDropDownList(e.target.value) }>
                <option>Select One</option>
                <option>Create New {localComponent}</option>
                {context.db.data !== undefined &&  context.db.data.map((item) => item.subMenu === subMenu && <option key={`${item.listName}`}>{item.listName}</option>)
                }
                <option>Delete {localComponent}</option>
                
            </select>
        </div>
}
  
export default DropDownList