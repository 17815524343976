import {useEffect, useContext} from 'react'
import { GlobalContext } from '../../hooks/GlobalContext'

import HourlyThreshold from './HourlyThreshold'
import InsideLightingThreshold from './InsideLightingThreshold'
import ThresholdLightingUnit from './ThresholdLightingUnit' //Lighting Unit Drop Down
import LightSchedule from './LightSchedule'
import HourlyLightSchedule from './HourlyLightSchedule'

const DOCUMENT_TITLE = "Schedule"
const oneGrayBox = "bg-gray-200 p-5 my-5 ml-auto mr-auto w-1/2 flex justify-evenly shadow"

function LightingSchedule(props) {
    const {useDocumentTitle, onLocalComponent,scheduleType, lightingUnit, scheduleTypeOption, LUDropDown, onScheduleTypeOption} = props;

    const context = useContext(GlobalContext)

    useDocumentTitle(DOCUMENT_TITLE)
    
    useEffect(() => {
         onLocalComponent(DOCUMENT_TITLE);
    }, [onLocalComponent]);


    return <>
        {/* <div className="flex flex-wrap justify-evenly"> */}
        <div className="flex w-full py-1 my-2 ml-auto mr-auto justify-evenly">
            <div className="w-1/3 my-2">
                <label className="label" htmlFor="schedule-type">To begin, please select a schedule type: </label>
                
                <select className="border-2 border-gray-200" id="schedule-type" name="type" value={scheduleTypeOption} onChange={e => onScheduleTypeOption(e.target.value)}>
                    <option>Select One</option>
                    {scheduleType.map((option, id) => <option key={id} value={option}>{option} </option>)}    
                </select>
            </div>
        </div>

        {/* Content  */}
        {scheduleTypeOption === scheduleType[0] ? <LightSchedule LUDropDown={LUDropDown} oneGrayBox={oneGrayBox} lightingUnit={lightingUnit}/>  
        
        : scheduleTypeOption === scheduleType[1] ? [
            
                <HourlyLightSchedule key="97" 
                    options={scheduleTypeOption} 
                    type={scheduleType} 
                    oneGrayBox={oneGrayBox} 
                    toggle1={context.toggle1}
                    toggle2 ={ context.toggle2}
                    toggle3 ={ context.toggle3}
                    toggle4 ={ context.toggle4}
                    toggle5 ={ context.toggle5}
                    toggle6 ={ context.toggle6}
                    toggle7 ={ context.toggle7}
                    toggle8 ={ context.toggle8}
                    toggle9 ={ context.toggle9}
                    toggle10 ={ context.toggle10}
                    toggle11 ={ context.toggle11}
                    toggle12 ={ context.toggle12}
                    toggle13 ={ context.toggle13}
                    toggle14 ={ context.toggle14}
                    toggle15 ={ context.toggle15}
                    toggle16 ={ context.toggle16}
                    toggle17 ={ context.toggle17}
                    toggle18 ={ context.toggle18}
                    toggle19 ={ context.toggle19}
                    toggle20 ={ context.toggle20}
                    toggle21 ={ context.toggle21}
                    toggle22 ={ context.toggle22}
                    toggle23 ={ context.toggle23}
                    toggle24 ={ context.toggle24}
                    onToggle1={context.onToggle1}
                    onToggle2 ={ context.onToggle2}
                    onToggle3 ={ context.onToggle3}
                    onToggle4 ={ context.onToggle4}
                    onToggle5 ={ context.onToggle5}
                    onToggle6 ={ context.onToggle6}
                    onToggle7 ={ context.onToggle7}
                    onToggle8 ={ context.onToggle8}
                    onToggle9 ={ context.onToggle9}
                    onToggle10 ={ context.onToggle10}
                    onToggle11 ={ context.onToggle11}
                    onToggle12 ={ context.onToggle12}
                    onToggle13 ={ context.onToggle13}
                    onToggle14 ={ context.onToggle14}
                    onToggle15 ={ context.onToggle15}
                    onToggle16 ={ context.onToggle16}
                    onToggle17 ={ context.onToggle17}
                    onToggle18 ={ context.onToggle18}
                    onToggle19 ={ context.onToggle19}
                    onToggle20 ={ context.onToggle20}
                    onToggle21 ={ context.onToggle21}
                    onToggle22 ={ context.onToggle22}
                    onToggle23 ={ context.onToggle23}
                    onToggle24 ={ context.onToggle24}
                />
            ]
        :
        scheduleTypeOption === scheduleType[2] ? [
            <ThresholdLightingUnit scheduleTypeOption={scheduleTypeOption} onLightingUnitChange={e => context.onLUDropDown(e.target.value)} LUDropDown={LUDropDown} lightingUnit={lightingUnit}  />,
            
            <InsideLightingThreshold key="98" LUDropDown={LUDropDown} lightingUnit={lightingUnit}  flag={0} />,

            <LightSchedule LUDropDown={LUDropDown} lightingUnit={lightingUnit} oneGrayBox={oneGrayBox}/>
    
        ]:
        scheduleTypeOption === scheduleType[3] ? [
            <ThresholdLightingUnit scheduleTypeOption={scheduleTypeOption} onLightingUnitChange={e => context.onLUDropDown(e.target.value)} LUDropDown={LUDropDown} lightingUnit={lightingUnit}></ThresholdLightingUnit>,
            <HourlyThreshold 
                key="96" 
                LUDropDown={LUDropDown} 
                lightingUnit={lightingUnit} 
                hourlyThreshold1={context.hourlyThreshold1}
                hourlyThreshold2 ={ context.hourlyThreshold2}
                hourlyThreshold3 ={ context.hourlyThreshold3}
                hourlyThreshold4 ={ context.hourlyThreshold4}
                hourlyThreshold5 ={ context.hourlyThreshold5}
                hourlyThreshold6 ={ context.hourlyThreshold6}
                hourlyThreshold7 ={ context.hourlyThreshold7}
                hourlyThreshold8 ={ context.hourlyThreshold8}
                hourlyThreshold9 ={ context.hourlyThreshold9}
                hourlyThreshold10 ={ context.hourlyThreshold10}
                hourlyThreshold11 ={ context.hourlyThreshold11}
                hourlyThreshold12 ={ context.hourlyThreshold12}
                hourlyThreshold13 ={ context.hourlyThreshold13}
                hourlyThreshold14 ={ context.hourlyThreshold14}
                hourlyThreshold15 ={ context.hourlyThreshold15}
                hourlyThreshold16 ={ context.hourlyThreshold16}
                hourlyThreshold17 ={ context.hourlyThreshold17}
                hourlyThreshold18 ={ context.hourlyThreshold18}
                hourlyThreshold19 ={ context.hourlyThreshold19}
                hourlyThreshold20 ={ context.hourlyThreshold20}
                hourlyThreshold21 ={ context.hourlyThreshold21}
                hourlyThreshold22 ={ context.hourlyThreshold22}
                hourlyThreshold23 ={ context.hourlyThreshold23}
                hourlyThreshold24 ={ context.hourlyThreshold24}
                
                onHourlyThreshold1={context.onHourlyThreshold1}
                onHourlyThreshold2 ={ context.onHourlyThreshold2}
                onHourlyThreshold3 ={ context.onHourlyThreshold3}
                onHourlyThreshold4 ={ context.onHourlyThreshold4}
                onHourlyThreshold5 ={ context.onHourlyThreshold5}
                onHourlyThreshold6 ={ context.onHourlyThreshold6}
                onHourlyThreshold7 ={ context.onHourlyThreshold7}
                onHourlyThreshold8 ={ context.onHourlyThreshold8}
                onHourlyThreshold9 ={ context.onHourlyThreshold9}
                onHourlyThreshold10 ={ context.onHourlyThreshold10}
                onHourlyThreshold11 ={ context.onHourlyThreshold11}
                onHourlyThreshold12 ={ context.onHourlyThreshold12}
                onHourlyThreshold13 ={ context.onHourlyThreshold13}
                onHourlyThreshold14 ={ context.onHourlyThreshold14}
                onHourlyThreshold15 ={ context.onHourlyThreshold15}
                onHourlyThreshold16 ={ context.onHourlyThreshold16}
                onHourlyThreshold17 ={ context.onHourlyThreshold17}
                onHourlyThreshold18 ={ context.onHourlyThreshold18}
                onHourlyThreshold19 ={ context.onHourlyThreshold19}
                onHourlyThreshold20 ={ context.onHourlyThreshold20}
                onHourlyThreshold21 ={ context.onHourlyThreshold21}
                onHourlyThreshold22 ={ context.onHourlyThreshold22}
                onHourlyThreshold23 ={ context.onHourlyThreshold23}
                onHourlyThreshold24 ={ context.onHourlyThreshold24}
                
                />
        ]:
        
        
            <div className="flex bg-gray-100 w-2/3 ml-auto mr-auto justify-evenly py-3 rounded shadow" > Select a Schedule Type to enable this feature </div>     
        }
    </>
}

export default LightingSchedule
