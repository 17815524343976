import {useContext} from 'react'
import { GlobalContext } from '../hooks/GlobalContext';

function SingleCard(props) {
    const {info} = props
    const context = useContext(GlobalContext)
    
    const handleAddPlantBtn = (e) => {
        const fID = context.flagged.find((item) => item === e.target.name)
        const vsID = context.viewSelection.map((item) => item.id).find((item) => item === fID)

        if( (vsID !== e.target.name) && (vsID === undefined)) {
            context.onFlagged(info.id)  
            context.onViewSelection(info)  
        }
    }

    return <>
        <div className="bg-gray-200 flex flex-wrap">
            <div className="w-1/4">
                <img className="w-full h-full" key={info.id} src={process.env.PUBLIC_URL + `/images/Plants/${info.image}`} alt={info.Common_Name} />
            </div>

            <div className='w-3/4 p-3'>
                    <p className="text-lg block"><span className="label">Common Name:</span> {info.Common_Name}</p>
                    <p className="text-lg italic block"><span className="label">Scientific Name: </span>{info.ScientificName}</p>
                    <p className="text-md block"><span className="label">Photo Period:</span> {info.Photo_Period}</p>
                    <p className="text-md block"><span className="label">Cultivar: </span>{info.Cultivar}</p>
                    <p className="text-md block"><span className="label">Optimal Temperature: </span>{Math.floor(info.Topt_Flower)}°F / {Math.floor(((info.Topt_Flower - 32) * 5) / 9)}°C *For Fastest Flower</p>
                    <p className="text-md block"><span className="label">Starting Size:</span> {info.Plug_Size}</p>                

                <div className="bg-gray-50 rounded m-3 px-3 text-lg">
                    <div className="py-2">
                        {info.Plant_Info}
                    </div>
                    <div className="pb-2">
                        <span>Source: </span> 
                        <a className="underline" href={info.Source_URL} target="_blank" rel="noreferrer">
                            {info.Source_Name}
                        </a>
                    </div>
                    <div className="pb-2">
                        <a href={info.Source_Info_URL}  target="_blank" rel="noreferrer">
                            {info.Source_Info}
                        </a>
                    </div>
                    <div className="pb-2">
                        <a href={info.Source_License_URL} target="_blank" rel="noreferrer">
                            {info.Source_License}
                        </a>
                    </div> 
                </div>

                <div className="px-3 flex justify-around">
                    <button className={`bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow ${ context.flagged.map((item) => item ).find(item => item === info.id) ? 'hidden': ''} `} onClick={(e) => handleAddPlantBtn(e)} name={info.id}>Add Plant</button> 
                    
                    <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow center" onClick={() => props.PopupboxManager.close()}>Close</button> 
                    
                </div>
            </div>     
        </div>
    </>
}

export default SingleCard