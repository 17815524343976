import Konva from 'konva';
import {useState, useEffect} from 'react'
import { Stage, Layer, Shape, Line } from 'react-konva';

export const Legend = () => {
    return <>
    <div className='bg-gradient-to-r from-red-600 via-purple-600 to-blue-600'>
        <Stage width={150} height={30}>
            
        </Stage>
    </div>
    </>
}


const Model = (props) => {
    const {values, materialTypes} = props
    
    const height = 500
    const width = 900
    const x = 320
    const y = 360
    // Per Dr. Boldt, to get the true Roof height, it is User Input Roof Height - User Input Side Height, but with coordinates, it's -RH = SH - RH
    const single = [0, values.sideHeight, values.width / 2, (values.sideHeight-values.roofHeight), values.width, values.sideHeight];
   
    // Mousewheel Zoom
    const [stageScale, setStageScale] = useState(4)
    const [stageX, setStageX] = useState(-800)
    const [stageY, setStageY] = useState(-1250)

    const handleWheel = (e) => {
        e.evt.preventDefault()

       
        let scaleBy = 1.02
        let stage = e.target.getStage();
        let oldScale = stage.scaleX()
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        };

        const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        setStageScale(newScale)
        setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale)
        setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale)

    }
    
    /**
     * 2D MODEL DEFAULT BASE
     */
    // Base
    const Base = () => {
        const lines = [];
        
        if(values.numberOfSpans > 1){
            for (var i = 0; i < values.numberOfSpans; i++) {
                lines.push(
                    <Line key={i} x={x} y={y} points={[0, 0, (Number(values.width) * values.numberOfSpans), 0]} stroke="black" strokeWidth={1}/>,
                );
            }  
            return lines
        }

        return <Line x={x} y={y} points={[0, 0, Number(values.width) , 0]} stroke="black" strokeWidth={1}/>

    }
    
    //Side Wall 1 (Left)
    const [red1, setRed1] = useState(0);
    const [blue1, setBlue1] = useState(0);
    let rgb1 = `rgb(${red1},0,${blue1})`
    useEffect(() => {
        if(values.sideWall1Type !== 'Select One'){
            materialTypes.filter((type) => type.Material_Name === values.sideWall1Type).map((material) => {
                setRed1(255 * (1 - (material.Heat_Transfer / 1.5)))
                if (red1 < 0) return setRed1(0);
        
                setBlue1(255 - red1);
                if (blue1 < 0) return setBlue1(0);
                
                return (red1, blue1)
            } )
        }
    },[materialTypes, values, red1, blue1])
    const SideWall1 = () => {
        const lines = []
        for(let i =0 ; i < values.numberOfSpans; i++){
            lines.push(
                <Line 
                    key={i}
                    x={x} 
                    y={y} 
                    points={[(Number(values.width) * i), 0, (Number(values.width) * i), Number(-values.sideHeight)]}  
                    strokeWidth={1}
                    stroke={i > 0 ? 'black': rgb1} 
                    filters={[Konva.Filters.RGB]} 
                    dash={[3, 1]}
                    dashEnabled={ i > 0 ? true: false}
                />
            )
        }
        return lines
    }

    //Side Wall 2 (Right)
    const [red2, setRed2] = useState(0);
    const [blue2, setBlue2] = useState(0);
    let rgb2 = `rgb(${red2},0,${blue2})`
    useEffect(() => {
        if(values.sideWall2Type !== 'Select One'){
            materialTypes.filter((type) => type.Material_Name === values.sideWall2Type).map((material) => {
                setRed2(255 * (1 - (material.Heat_Transfer / 1.5)))
                if (red2 < 0) return setRed2(0);
        
                setBlue2(255 - red2);
                if (blue2 < 0) return setBlue2(0);
                
                return (red2, blue2)
            } )
        }
    },[materialTypes, values, red2, blue2])
    const SideWall2 = () => {
        const lines = []
        for(let i = 0; i < values.numberOfSpans; i++){
            lines.push(
                <Line key={i}
                    x={x} 
                    y={y} 
                    points={[Number(values.width)  * i + (Number(values.width)) , 0, Number(values.width) * i + (Number(values.width) ), Number(-values.sideHeight)]} 
                    stroke={i +1 < values.numberOfSpans ? 'black': rgb2} 
                    strokeWidth={1}  
                    filters={[Konva.Filters.RGB]} 
                    dash={[2, 3]}
                    dashEnabled={ i +1 < values.numberOfSpans ? true: false}
                />
            )
            
        }
        return lines
    }

    /**
     * ROOF TYPES
     */

    // Rectangular
    const [red6, setRed6] = useState(0);
    const [blue6, setBlue6] = useState(0);
    let rgb6 = `rgb(${red6},0,${blue6})`
    useEffect(() => {
        if(values.roofType !== 'Select One'){
            materialTypes.filter((type) => type.Material_Name === values.roofType).map((material) => {
                setRed6(255 * (1 - (material.Heat_Transfer / 1.5)))
                if (red6 < 0) return setRed6(0);
        
                setBlue6(255 - red6);
                if (blue6 < 0) return setBlue6(0);
                
                return (red6, blue6)
            })
        } 
    },[materialTypes, values, red6, blue6])
    const RectangularRoof = () => {
        const lines = [];
        if(values.numberOfSpans > 1) {
            for (var i = 0; i < values.numberOfSpans; i++) {
            lines.push(
                <Line x={x} y={y} points={[0, Number(-values.sideHeight), (Number(values.width) * values.numberOfSpans), Number(-values.sideHeight)]} stroke={rgb6} strokeWidth={1} />
            );
        }  
        return lines
        }

        return <Line x={x} y={y} points={[0, Number(-values.sideHeight), (Number(values.width) * values.numberOfSpans), Number(-values.sideHeight)]} stroke={rgb6} strokeWidth={1} />
    }


    //Single & Multi Triangular
    const [red7, setRed7] = useState(0);
    const [blue7, setBlue7] = useState(0);
    const [alpha1, setAlpha1] = useState(0)
    let rgba1 = `rgb(${red7},0,${blue7}, ${alpha1})`

    useEffect(() => {
        if(values.roofType !== 'Select One'){
            materialTypes.filter((type) => type.Material_Name === values.roofType).map((material) => {
                setRed7(255 * (1 - (material.Heat_Transfer / 1.5)))
                setAlpha1(material.Light_Transmit)
                if (red7 < 0) return setRed7(0);
        
                setBlue7(255 - red7);
                if (blue7 < 0) return setBlue7(0);
                
                return (red7, blue7)
            })
        } 
    },[materialTypes, values, red7, blue7])
    const TriangularRoof = () =>  {
        const lines = []

        if(values.numberOfSpans > 0 && values.peaksPerSpan > 0) {
            for(let i =0 ; i < values.numberOfSpans; i++){
    
                for (var j = 0; j < values.peaksPerSpan; j++) {
                    const perSpan = single.map((x) => x / values.peaksPerSpan);
                    lines.push(
                        <Line 
                            x={x + (j * values.width)  / (values.peaksPerSpan ) + (values.width * i)  } 
                            y={y-(values.sideHeight / values.peaksPerSpan)-values.sideHeight} 
                            points={perSpan} 
                            strokeWidth={1} 
                            strokeLinearGradientStartPoint={{ x: perSpan[0] , y: perSpan[1] }}
                            strokeLinearGradientEndPoint={{ x: perSpan[4], y: perSpan[5] }}
                            strokeLinearGradientColorStops={[0, rgba1, alpha1, "white", 1, rgba1]}            
                        />
                    );
                }
            }
         return lines;

        } else {
            return <RectangularRoof /> 
        }
        
    }

   //Single & Multi Arch
    const [red8, setRed8] = useState(0);
    const [blue8, setBlue8] = useState(0);
    const [alpha2, setAlpha2] = useState(0)
    let rgba2 = `rgb(${red8},0,${blue8}, ${alpha2})`

    useEffect(() => {
        if(values.roofType !== 'Select One'){
            materialTypes.filter((type) => type.Material_Name === values.roofType).map((material) => {
                setRed8(255 * (1 - (material.Heat_Transfer / 1.5)))
                setBlue8(255 - red8);
                setAlpha2(material.Light_Transmit)
                
                if (red8 < 0) setRed8(0);
                if (blue8 < 0) setBlue8(0);
                
                return (red8, blue8)
            })
        } 
    },[materialTypes, values, red8, blue8])
   
    const ArchRoof = () => {
        const lines = [];

        if(values.numberOfSpans > 0 && values.peaksPerSpan > 0) {
            for(let i =0 ; i < values.numberOfSpans; i++){
    
                for (var j = 0; j < values.peaksPerSpan; j++) {
                    const perSpan = single.map((x) => x / values.peaksPerSpan);
                    lines.push(
                        <Line 
                            x={x + (j * values.width)  / (values.peaksPerSpan ) + (values.width * i)  } 
                            y={y-(values.sideHeight / values.peaksPerSpan)-values.sideHeight} 
                            points={perSpan} 
                            tension={1}  
                            strokeWidth={1} 
                            strokeLinearGradientStartPoint={{ x: perSpan[0], y: perSpan[1] }}
                            strokeLinearGradientEndPoint={{ x: perSpan[4], y: perSpan[5] }}
                            strokeLinearGradientColorStops={[0, rgba2, alpha2, "white", 1, rgba2]}          
                        />
                    );
                }
            }
         return lines;

        } else {
            return <RectangularRoof /> 
        }
        
        
    }


    //HalfArch
    const [red9, setRed9] = useState(0);
    const [blue9, setBlue9] = useState(0);
    const [alpha3, setAlpha3] = useState(0)
    let rgba3 = `rgb(${red9},0,${blue9}, ${alpha3})`

    useEffect(() => {
        if(values.roofType !== 'Select One'){
            materialTypes.filter((type) => type.Material_Name === values.roofType).map((material) => {
                setRed9(355 * (1 - (material.Heat_Transfer / 1.5)))
                setBlue9(355 - red9);
                setAlpha3(material.Light_Transmit)
                
                if (red9 < 0) setRed9(0);
                if (blue9 < 0) setBlue9(0);
                
                return (red9, blue9)
            })
        } 
    },[materialTypes, values, red9, blue9])

    const HalfArchRoof = () => {
        const lines = [];
        if(values.numberOfSpans > 0 && values.peaksPerSpan > 0) {
            for(let i =0 ; i < values.numberOfSpans; i++){
    
                for (var j = 0; j < values.peaksPerSpan; j++) {
                    lines.push(
                        <Shape
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(0,-values.sideHeight); // Create a starting point 
                                context._context.arcTo(values.width, -values.roofHeight, values.width, -values.roofHeight, values.sideHeight);
                                context.lineTo(values.width, -values.sideHeight); 
                                shape.strokeLinearGradientColorStops()
                                shape.strokeWidth(1)         
                                context.fillStrokeShape(shape); // (!) Konva specific method, it is very important. Done LAST!
                            }}
                            
                            x={x + (i * values.width)}
                            y={y}         
                            strokeLinearGradientStartPoint={{ x: 0, y: -values.sideHeight }}
                            strokeLinearGradientEndPoint={{ x:values.width, y: values.roofHeight }}
                            strokeLinearGradientColorStops={[0, rgba3, alpha3, 'white', 1, rgba3]}
                        /> 
                    );
                }
            }
            
            return lines
        } else {
            return <RectangularRoof /> 
        }
        
        
       
    }

    /**
     * KNEEWALLS
     */

    //Side Kneewall 1 (left)
    const [red3, setRed3] = useState(0);
    const [blue3, setBlue3] = useState(0);
    let rgb3 = `rgb(${red3},0,${blue3})`
    useEffect(() => {
        if(values.sideKneewall1Type !== 'Select One'){
            materialTypes.filter((type) => type.Material_Name === values.sideKneewall1Type).map((material) => {
                setRed3(255 * (1 - (material.Heat_Transfer / 1.5)))
                if (red3 < 0) return setRed3(0);
        
                setBlue3(255 - red3);
                if (blue3 < 0) return setBlue3(0);
                
                return (red3, blue3)
            })
        }
    },[materialTypes, values, red3, blue3])
    const SideKneewall1Type = () =>  {
        const lines = [];
        if(values.numberOfSpans > 0) {
            for (var i = 0; i < values.numberOfSpans; i++) {
                lines.push(
                    <Line key={i} x={x + i * values.width} y={y} points={[1, 0, 1, Number(-values.sideWall1)]} stroke={rgb3} strokeWidth={1} />
                );
            }  
        }
        
        return lines    
    }
    

    //Side Kneewall 2 (Right)
    const [red4, setRed4] = useState(0);
    const [blue4, setBlue4] = useState(0);
    let rgb4 = `rgb(${red4},0,${blue4})`
    useEffect(() => {
        if(values.sideKneewall2Type !== 'Select One'){
            materialTypes.filter((type) => type.Material_Name === values.sideKneewall2Type).map((material) => {
                setRed4(255 * (1 - (material.Heat_Transfer / 1.5)))
                if (red4 < 0) return setRed4(0);
        
                setBlue4(255 - red4);
                if (blue4 < 0) return setBlue4(0);
                
                return (red4, blue4)
            } )
        }
    },[materialTypes, values, red4, blue4])
    const SideKneewall2Type = () =>  {
        const lines = [];
        if(values.numberOfSpans > 0) {
            for (var i = 0; i < values.numberOfSpans; i++) {
                lines.push(
                    <Line key={2} x={x + i * values.width} y={y} points={[Number(values.width-1), 0, Number(values.width-1), Number(-values.sideWall2)]} stroke={rgb4} strokeWidth={1} />
                );
            }  
        }
        return lines   
    }
    

    //End Kneewall 1 (Facing)
    const [red5, setRed5] = useState(0);
    const [blue5, setBlue5] = useState(0);
    let rgb5 = `rgb(${red5},0,${blue5})`  
    useEffect(() => {
        if(values.endKneewall1Type !== 'Select One'){
            materialTypes.filter((type) => type.Material_Name === values.endKneewall1Type).map((material) => {
                setRed5(255 * (1 - (material.Heat_Transfer / 1.5)))
                if (red5 < 0) return setRed5(0);
        
                setBlue5(255 - red5);
                if (blue5 < 0) return setBlue5(0);
                
                return (red5, blue5)
            })
        }
    },[materialTypes, values, red5, blue5])
    const EndKneewall1Type = () => {
        const lines = [];
        if(values.numberOfSpans > 0) {
            for (var i = 0; i < values.numberOfSpans; i++) {
                lines.push(
                    <Line key={i} x={x + i * values.width} y={y} points={[0.5, Number(-values.endWall1), Number(values.width)-0.5, Number(-values.endWall1)]} stroke={rgb5} strokeWidth={1}/>
                );
            }  
        }
        return lines   
    }
    
    return <>
        <main>
            {/* Stage */}
            <div>
                <Stage width={width} height={height}onWheel={handleWheel}scaleX={stageScale}scaleY={stageScale}x={stageX}y={stageY}draggable={true}>
                    
                    {/* Base Shape */}
                    <Layer><SideWall1 /><Base /><SideWall2 /></Layer>

                    {/* Roof Shape */}
                    <Layer>    
                        {values.roofShape === 'Triangular' ? <TriangularRoof /> : values.roofShape === 'Rectangular' ? <RectangularRoof /> : values.roofShape === 'Arch' ? <ArchRoof /> : values.roofShape === 'Half Arch' ? <HalfArchRoof />  : null }   
                    </Layer>

                    {/* Kneewall */}
                    <Layer><EndKneewall1Type/></Layer>
                    <Layer><SideKneewall1Type/></Layer>
                    <Layer><SideKneewall2Type/></Layer>
                </Stage>
                
            </div>
        </main>
    </>
}

export default Model
