import {save, reset} from './Database'


//For Output & Prediction/Forecast
export const handleUpdateDB = (values) => {save(values, 'UPDATE'); alert('Added!')}

function Session(props) {
    const {values} = props
       
    const handleSaveButton = () => {save(values, 'UPDATE'); alert('updated!')}
    
    const handleResetAllButton = () => {
        if(window.confirm("Are you sure you want to reset this app? You will lose ALL of your data.") === true) {
            window.location.reload(true)
            reset();
        } else return null;
    }

    return <div className="w-1/6 bg-transparent rounded my-4 shadow">
                <div className="flex flex-wrap m-4 justify-around">
                    <button onClick={() => handleSaveButton(values)} className="SAVE">Update</button>
                    <button onClick={() => handleResetAllButton()} className="RESETALL">Reset All</button>
                </div>
            </div>
}

export default Session
