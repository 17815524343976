import {useState} from 'react'
import { Link } from 'react-router-dom'
import menuContent from '../Menu/MenuContent'

const SIDEBAR_LOGONAME = "text-2xl text-white font-semibold align-baseline transition duration-300 delay-100 ease"
const LOGO_DETAILS = "flex w-full h-16 mt-4 text-center"

function Navbar() {

    const [menuToggle, setMenuToggle] = useState(false)
    const [subToggle, setSubToggle] = useState(-1)
    
    return <>
        <nav className={` sidebar left-full bg-gray-800 ${menuToggle ? "SIDEBAR_OPEN" : "SIDEBAR_CLOSED close"}`}>
             <div className={LOGO_DETAILS}>
                <i className={` text-3xl text-white h-12 w-20 px-7 text-center  ${menuToggle ? "mdi mdi-close" : "mdi mdi-menu" }`} 
                    onClick={() => setMenuToggle(!menuToggle)}>
                </i>
                {menuToggle && <Link to="/"><p className={SIDEBAR_LOGONAME}>Virtual Grower</p></Link>}
            </div>
            <ul className="menu-items">
                
                {/* Location, Greenhouse, Heating, Lighting, Plants || Settings */}
                {menuContent.map((item, index) => (item.id < 7 || item.id === 99) &&
                    <li key={item.id}>
                        <div className={menuToggle ? "flex item-center justify-between":"block"}  onClick={() => setSubToggle(item.id)}>
                            <Link to={item.to}> 
                                <p className="flex items-center no-underline">
                                    <i className={item.icon} ></i>
                                    {menuToggle ? 
                                        
                                            <span className={menuToggle ? "text-lg text-white transition-all duration-500 ease-linear ": "opacity-0 pointer-events-none "}>{item.title}</span>
                                        
                                    : 
                                        <span className={menuToggle ? "text-lg text-white transition-all duration-500 ease-linear ": "opacity-0 pointer-events-none "}>{item.title}</span>
                                    }
                                </p>
                            </Link>
                            {menuToggle && item.subMenus ? <i id={index} className="px-5 mdi mdi-menu-down-outline"  ></i>: undefined}
                        </div>
            
                        <ul className={subToggle === item.id && menuToggle ? "text-lg text-white opacity-100" : "sub-menu hidden"}>    
                        <Link to={item.to}>
                            <li key={item.id}>
                                {menuToggle ? "" :  <p className="text-lg text-white transition-all duration-500 ease-linear ">{item.title}</p>}
                            </li>
                        </Link>

                        {item.subMenus && item.subMenus.map((subItem, index) => <Link to={subItem.to}>
                            <li id={index} key={index} className={menuToggle ? "w-1/2 pb-1 ml-auto mr-auto": undefined}>
                                <p className="py-1 text-white transition-all duration-300 ease-linear cursor-pointer whitespace-nowrap hover:opacity-100 hover:text-gray-400">{subItem.title}</p>
                            </li> 
                        </Link>)}
                    </ul>
                </li>)}
            </ul>
        </nav>
    </>
}

export default Navbar
