export const energycurtain = [
    {
        id: 1,
        name: 'Excellent',
        value: 3,
        placeholder: 'Installation Quality - Excellent',
        image: 'ECInstallExcellent.jpg'
    },
    {
        id: 2,
        name: 'Excellent',
        value: 3,
        placeholder: 'Material Condition - Excellent',
        image: 'ECConditionExcellent.jpg'
    },
    {
        id: 3,
        name: 'Good',
        value: 2,
        placeholder: 'Installation Quality - Fair',
        image: 'ECInstallGood.jpg'
    },
    {
        id: 4,
        name: 'Good',
        value: 2,
        placeholder: 'Material Condition - Good',
        image: 'ECConditionGood.jpg'
    },
    {
        id: 5,
        name: 'Fair',
        value: 1,
        placeholder: 'Installation Quality - Fair',
        image: 'ECInstallFair.jpg'
    },
    {
        id: 6,
        name: 'Fair',
        value: 1,
        placeholder: 'Material Condition - Fair',
        image: 'ECConditionFair.jpg'
    },
    {
        id: 7,
        name: 'Poor',
        value: 0,
        placeholder: 'Installation Quality - Poor',
        image: 'ECInstallPoor.jpg'
    },
    {
        id: 8,
        name: 'Poor',
        value: 0,
        placeholder: 'Material Condition - Poor',
        image: 'ECConditionPoor.jpg'
    },
]