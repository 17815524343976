const Welcome = () => <div className="w-full h-full flex flex-col justify-center items-center">
    <img className="w-1/3 py-4" src={process.env.PUBLIC_URL + `/images/virtual-grower.jpg`} alt="Welcome"/>
    <div>
        <a href="/getstarted"> <button className="bg-yellow-300 font-bold uppercase tracking-wider shadow-lg px-4 py-2" >Get Started</button> </a> 
        <a href="/about"><button className="bg-yellow-300 font-bold uppercase tracking-wider shadow-lg px-4 py-2 ">About</button></a>
    </div>
</div>

export default Welcome

