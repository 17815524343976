import {useState, useEffect, useContext} from 'react'
import { GlobalContext } from '../hooks/GlobalContext'
import HeatingSchedule from './HeatingSchedule'
import HeatingSystemSetup from './HeatingSystemSetup'
import useDocumentTitle from '../hooks/useDocumentTitle';
import Session from '../hooks/Session';
import DropDownList from '../hooks/DropDownList';

import {PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

const Heating = (props) => {
    const {fuelInformation, heatingSysSetupImages} = props
    const context = useContext(GlobalContext)

    useEffect(() => {
    context.global.onComponent("Heating")
    }, [context.global])

    const [localComponent, setLocalComponent] = useState("")
    const handleLocalComponent = (value) => setLocalComponent(value)
    const [subMenu, setSubMenu] = useState("")

    const [values, setValues] = useState(null) 
    useEffect(() => {
        if(subMenu === 'hsched'){
            context.heatScheduleTypeOption === context.options[0] ?
                setValues({
                    listName: context.dropDownOption,
                    subMenu: subMenu,
                    heatScheduleTypeOption:context.heatScheduleTypeOption,
                    startMonth: context.startMonth,
                    scheduleStartDay: context.scheduleStartDay,
                    stopMonth: context.stopMonth,
                    stopDay: context.stopDay,
                })
            : context.heatScheduleTypeOption === context.options[1] ?
                setValues({
                    listName: context.dropDownOption,
                    subMenu: subMenu,
                    heatScheduleTypeOption:context.heatScheduleTypeOption,
                    startMonth: context.startMonth,
                    scheduleStartDay: context.scheduleStartDay,
                    stopMonth: context.stopMonth,
                    stopDay: context.stopDay,
                    constantTemp:context.constantTemp,
                })
            : context.heatScheduleTypeOption === context.options[2] ?
                setValues({
                    listName: context.dropDownOption,
                    subMenu: subMenu,
                    heatScheduleTypeOption:context.heatScheduleTypeOption,
                    startMonth: context.startMonth,
                    scheduleStartDay: context.scheduleStartDay,
                    stopMonth: context.stopMonth,
                    stopDay: context.stopDay,
                    dayTemp:context.dayTemp,
                    nightTemp:context.nightTemp,
                    startDay:context.startDay,
                    startNight:context.startNight,
                    isChecked:context.isChecked,
                })
            : context.heatScheduleTypeOption === context.options[3] ?
                setValues({
                    listName: context.dropDownOption,
                    subMenu: subMenu,
                    heatScheduleTypeOption:context.heatScheduleTypeOption,
                    startMonth: context.startMonth,
                    scheduleStartDay: context.scheduleStartDay,
                    stopMonth: context.stopMonth,
                    stopDay: context.stopDay,
                    heatTemp: context.hsHourlyTemp
                })
            : setValues(null)

        } else if (subMenu === 'hsetup') {
            setValues({
                listName: context.dropDownOption,
                subMenu: subMenu,
                heaterTypeOption:context.heaterTypeOption,
                fuelTypeOptions:context.fuelTypeOptions,
                fuelPrice:context.fuelPrice,
                localHeaterEfficiencyToggle:context.localHeaterEfficiencyToggle,
                localHeaterEfficiencyInput:context.localHeaterEfficiencyInput,
                ventilationOption:context.ventilationOption,
                deliveryMethodOption:context.deliveryMethodOption,
                variationOptions:context.variationOptions,
                isFuelPriceChecked:context.isFuelPriceChecked,
                haOption:context.haOption,
                msOption:context.msOption,
                globalHeaterEfficiencyToggle:context.globalHeaterEfficiencyToggle,
                globalHeaterEfficiencyInput:context.globalHeaterEfficiencyInput
            })
        }
    },[context, subMenu])
 
    return <>
        <div className="flex flex-wrap justify-evenly w-full" key={localComponent}>
            <div className="w-1/5 bg-red-300 rounded my-4 shadow">
                <div className="flex justify-around m-1 space-x-3">
                    <label className=" text-3xl" htmlFor="subMenuTitle">Heating: {localComponent}</label>                    
                </div> 
            </div>
            {subMenu.length > 0 && [
                <Session values={values}/>, 
                                
                <DropDownList localComponent={localComponent} subMenu={subMenu}  />
            ]}

        </div>
        <div className="flex flex-wrap">
            <div className="w-3/5 ml-auto mr-auto">
                {/* Header Menu */}
                <div className="mx-2 my-2 flex flex-wrap justify-around">
                    <button key="hsched" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="hsched">Schedule Setup</button>
                    
                    <button key="hsetup" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="hsetup">System Setup</button>  
                </div>
            </div>
        </div>
        {/* Menu Content */}
            <div className="flex flex-wrap justify-center">
            {subMenu === 'hsched' ?
                    <HeatingSchedule 
                        useDocumentTitle={useDocumentTitle} 
                        onLocalComponent={handleLocalComponent}  
                        options={context.options}
                    />
            : subMenu === 'hsetup' &&
                    <HeatingSystemSetup 
                        useDocumentTitle={useDocumentTitle} 
                        onLocalComponent={handleLocalComponent} 
                        fuelInformation={fuelInformation}
                        heatingSysSetupImages={heatingSysSetupImages}
                        heaterAge={context.heaterAge}
                        maintenanceSchedule={context.maintenanceSchedule}
                        dmUnitHeater={context.dmUnitHeater}
                        dmHotH2OBoiler={context.dmHotH2OBoiler}
                        dmSteamBoiler={context.dmSteamBoiler} 
                        varForcedAir={context.varForcedAir}
                        varH2OSteamPipes={context.varH2OSteamPipes}
                        varHeatedFloors={context.varHeatedFloors}
                        heaterTypeOption={context.heaterTypeOption}
                        fuelTypeOptions={context.fuelTypeOptions}
                        fuelPrice={context.fuelPrice}
                        localHeaterEfficiencyToggle={context.localHeaterEfficiencyToggle}
                        localHeaterEfficiencyInput={context.localHeaterEfficiencyInput}
                        ventilationOption={context.ventilationOption}
                        deliveryMethodOption={context.deliveryMethodOption}
                        dmSelectionOption={context.dmSelectionOption}
                        variationOptions={context.variationOptions}
                        isFuelPriceChecked={context.isFuelPriceChecked}
                        haOption={context.haOption}
                        msOption={context.msOption}
                        globalHeaterEfficiencyToggle={context.globalHeaterEfficiencyToggle}
                        globalHeaterEfficiencyInput={context.globalHeaterEfficiencyInput}

                        onHeaterTypeOption={context.onHeaterTypeOption}
                        onFuelTypeOptions={context.onFuelTypeOptions}
                        onFuelPrice={context.onFuelPrice}
                        onLocalHeaterEfficiencyOverrideClick={context.onLocalHeaterEfficiencyOverrideClick}
                        onLocalHeaterEfficiencyInput={context.onLocalHeaterEfficiencyInput}
                        onVentilationChange={context.onVentilationChange}
                        onDeliveryMethodChange={context.onDeliveryMethodChange}
                        onDMSelectionOption={context.onDMSelectionOption}
                        onVariationOptionsChange={context.onVariationOptionsChange}
                        onIsFuelPriceChecked={context.onIsFuelPriceChecked}
                        onHeaterAgeOption={context.onHeaterAgeOption}
                        onMSOption={context.onMSOption}
                        onGlobalHeaterEfficiencyOverrideClick={context.onGlobalHeaterEfficiencyOverrideClick}
                        onGlobalHeaterEfficiencyInput={context.onGlobalHeaterEfficiencyInput}

                    />
                }
        </div>
        <PopupboxContainer />
        </>
}

export default Heating