import { useEffect } from 'react';

const DOCUMENT_TITLE = "Materials"


const Materials = (props) => {
    const {useDocumentTitle, onLocalComponent, materialTypes, endWall1Type, endWall2Type, roofType, sideWall1Type, sideWall2Type, endKneewall1Type, endKneewall2Type, sideKneewall1Type, sideKneewall2Type, onEndWall1Type, onEndWall2Type, onRoofType, onSideWall1Type, onSideWall2Type, onEndKneewall1Type, onEndKneewall2Type, onSideKneewall1Type, onSideKneewall2Type } = props

    useDocumentTitle(DOCUMENT_TITLE)
    useEffect(() => {
         onLocalComponent(DOCUMENT_TITLE);
    }, [onLocalComponent]);
   
   

    const handleKneewallClick = () => {
        onEndKneewall1Type(endKneewall1Type)
        onEndKneewall2Type(endKneewall1Type)
        onSideKneewall1Type(endKneewall1Type)
        onSideKneewall2Type(endKneewall1Type)
    }

    return <>
    
        {/* Controls */}
        <div className="flex justify-evenly py-3">
            <label className="label" htmlFor="gh-roof">Roof Type: </label>
            <select className="border overflow-ellipsis w-2/3" id="gh-roof" name="type" value={roofType} onChange={e => onRoofType(e.target.value)}>
                <option>Select One</option>
                {materialTypes.filter((opt) => opt.Primary_Key <= 11 || opt.Primary_Key === 27 || opt.Primary_Key === 28).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name} </option> )}
            </select>
        </div>
        
        <div className="flex justify-evenly py-3">
            <label className="label" htmlFor="gh-endWall1">End Wall 1: </label>
            <select className="border overflow-ellipsis w-2/3" id="gh-endWall1" name="type" value={endWall1Type} onChange={e => onEndWall1Type(e.target.value)}>
                <option>Select One</option>
                {materialTypes.filter((opt) => opt.Primary_Key <= 11 || opt.Primary_Key === 27 || opt.Primary_Key === 28).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name} </option> )}
            </select>
        </div>
        
        <div className="flex justify-evenly py-3">
            <label className="label" htmlFor="gh-endWall2">End Wall 2: </label>
            <select className="border overflow-ellipsis w-2/3" id="gh-endWall2" name="type" value={endWall2Type} onChange={e => onEndWall2Type(e.target.value)}>
                <option>Select One</option>
                {materialTypes.filter((opt) => opt.Primary_Key <= 11 || opt.Primary_Key === 27 || opt.Primary_Key === 28).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name} </option> )}
            </select>
        </div>

        <div className="flex justify-evenly py-3">
            <label className="label" htmlFor="gh-sideWall1">Side Wall 1: </label>
            <select className="border overflow-ellipsis w-2/3" id="gh-sideWall1" name="type" value={sideWall1Type} onChange={e => onSideWall1Type(e.target.value)}>
                <option>Select One</option>
                {materialTypes.filter((opt) => opt.Primary_Key <= 11 || opt.Primary_Key === 27 || opt.Primary_Key === 28).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name} </option> )}
            </select> 
        </div>
        
        <div className="flex justify-evenly py-3">
            <label className="label" htmlFor="gh-sideWall2">Side Wall 2: </label>
            <select className="border overflow-ellipsis w-2/3" id="gh-sideWall2" name="type" value={sideWall2Type} onChange={e => onSideWall2Type(e.target.value)}>
                <option>Select One</option>
                {materialTypes.filter((opt) => opt.Primary_Key <= 11 || opt.Primary_Key === 27 || opt.Primary_Key === 28).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name} </option> )}
            </select>                  
        </div>

        <p className=" px-3 text-indigo-700"> Kneewall Heights</p>

        <div className="flex justify-evenly py-3">
            <label className="label"  htmlFor="gh-endKneewall1" >End Kneewall 1: </label>
            <select className="border overflow-ellipsis w-2/3" id="gh-endKneewall1" name="type" value={endKneewall1Type} onChange={e => onEndKneewall1Type(e.target.value)}>
                <option>Select One</option>
                {materialTypes.filter((opt) => opt.Primary_Key === 12 || opt.Primary_Key === 13 || opt.Primary_Key === 14 || opt.Primary_Key === 29).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name} </option> )}
            </select>
        </div>
        
        <div className="flex justify-evenly py-3">
            <label className="label" htmlFor="gh-endKneewall2">End Kneewall 2: </label>
            <select className="border overflow-ellipsis w-2/3" id="gh-endKneewall2" name="type" value={endKneewall2Type} onChange={e => onEndKneewall2Type(e.target.value)}>
                <option>Select One</option>
                {materialTypes.filter((opt) => opt.Primary_Key === 12 || opt.Primary_Key === 13 || opt.Primary_Key === 14 || opt.Primary_Key === 29).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name} </option> )}
            </select>
        </div>

        <div className="flex justify-evenly py-3">
            <label className="label" htmlFor="gh-sideKneewall1" >Side Kneewall 1: </label>
            <select className="border overflow-ellipsis w-2/3" id="gh-sideKneewall1" name="type" value={sideKneewall1Type} onChange={e => onSideKneewall1Type(e.target.value)}>
                <option>Select One</option>
                {materialTypes.filter((opt) => opt.Primary_Key === 12 || opt.Primary_Key === 13 || opt.Primary_Key === 14 || opt.Primary_Key === 29).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name} </option> )}
            </select>
        </div>
        
        <div className="flex justify-evenly py-3">
            <label className="label" htmlFor="gh-sideKneewall2" >Side Kneewall 2: </label>
            <select className="border overflow-ellipsis w-2/3" id="gh-sideKneewall2" name="type" value={sideKneewall2Type} onChange={e => onSideKneewall2Type(e.target.value)}>
                <option>Select One</option>
                {materialTypes.filter((opt) => opt.Primary_Key === 12 || opt.Primary_Key === 13 || opt.Primary_Key === 14 || opt.Primary_Key === 29).map((option) => <option key={option.Primary_Key} value={option.Material_Name}>{option.Material_Name} </option> )}
            </select>
        </div>

        <div className="flex justify-evenly py-3">
            <input className="border p-2 OVERRIDE_D"  type="button" id="gh-kneewall" value="Set Kneewall Equal"  onClick={handleKneewallClick}></input>
        </div>
    </>
}

export default Materials;