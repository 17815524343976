import * as React from 'react'
import content from './Content'

import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

type DocumentationProps = {switch: number}


const Documentation = (props:DocumentationProps) => {
    
    const [currentPage, setCurrentPage] = React.useState(1)
    const perPage:number = 5
    const lastIndex = currentPage * perPage;
    const firstIndex = lastIndex - perPage;
    const currentData = content.slice(firstIndex, lastIndex);
    const pages = [];

    for (let i = 1; i <= Math.ceil(content.length / perPage); i++) {
      pages.push(i);
    }

    const handlePageClick = (value:number = 0) => setCurrentPage(value ?? 0)
   
    const renderPageNumbers = pages.map(number => 
        <li 
            className={`px-2 mx-1 border-2 rounded  
                ${currentPage === number ? 'bg-white border-gray-600 text-gray-600' : 'bg-gray-500 border-gray-600 text-white'}`} 
            key={number} value={number} onClick={(e) => handlePageClick(e.currentTarget.value)}> {number}
        </li>
    );

    const handleDocumentation = (source:string = "") => {      
        const content = (<video controls> <source src={`./videoContent/${source}.webm`} type="video/webm" />
        Your browser does not support the video tag.
      </video> )
        return PopupboxManager.open({ content })
    }
        
    const getView = () => {
        switch(props.switch){
        case 1: return <>
            {/* Video snippets on how to use each component and it's features. 4-6 per page. Use paginiation when necessary.  */}
            <div key="docs" className=" w-full items-center justify-center font-semibold">
                <h1 className="text-center text-3xl">Get Started</h1>             
                <p className="text-center py-5 text-2xl text-red-900">This section is a work in progress. Thank you for your patience.</p>  
                <div className='grid grid-cols-1 text-2xl py-5'>
                    <ul className="flex justify-center">
                        <li className='list-none'>Page:</li> {renderPageNumbers}
                    </ul>
                </div>
                {currentData.map((info, id) => { return <>
                <div key={id} className="grid grid-cols-1 w-3/4 ml-auto mr-auto  rounded-lg shadow-md py-3 " onClick={() => handleDocumentation(info.source)}>
                    <span className="text-center text-2xl block px-1 font-bold ">{info.title}</span>
                    <span className=" text-center  text-xl italic text-gray-500">{info.description}</span> 
                </div>

                <PopupboxContainer  />
            </>})} 

            </div> 
            
            
                                  
        </>
            
        case 0: return <>
            <div key="about" className="grid grid-cols-1 font-semibold">
                <h1 className=" text-center text-4xl py-5">About</h1>               
            </div> 
            <div className="flex flex-col w-5/6 ml-auto mr-auto text-3xl">
                <p className='py-5'>Welcome to Virtual Grower. Virtual Grower is a decision support tool for greenhouse growers.  Users can build a greenhouse with a variety of materials for roofs and sidewalls, design the greenhouse style, schedule temperature set points throughout the year, and predict heating costs and plant growth for over 785 sites within the US.</p>
                
                <p className='py-5'>Check out the <a href="/getstarted" className=' underline'> Get Started</a> to find out more about the program, our contributors, and the methods used to develop the current version of the program. Simply click on any of the items to the left to read more about them.</p>

                <p className='py-5'>We welcome any questions and feedback, so please feel free to contact us at <a className='underline' href="mailto:virtualgrower@usda.gov">virtualgrower@usda.gov</a>.</p>
            </div>
        </>
    }}

    return <>{getView()}</>
}

export default Documentation
