
//Libraries
import {useState} from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'

// Components
import { GlobalContextProvider } from './components/hooks/GlobalContext';
import Navbar from './components/Menu/Navbar'
import Welcome from './components/Welcome'
import Documentation from './components/Documentation/Documentation'
import Location from './components/Location/Location'
import Heating from './components/Heating/Heating'
import Greenhouse from './components/Greenhouse/Greenhouse'
import Lighting from './components/Lighting/Lighting';
import Plants from './components/Plants/Plants'
import GreenhouseSetup from './components/Output/GreenhouseSetup';
import Test from './components/Output/Test';
// import Settings from './components/hooks/Settings'


// CSS: VG4 
import "./css/general.css"
import "./css/navbar.css"
import "./css/ribbon.css"

// Tables & DB
import {fuelInformation} from './tables/fuels'
import {heatingSysSetupImages} from './tables/heatingsystemsetup'

function App() { 
  const [component, setComponent] = useState("")
  const handleComponent = (value) => setComponent(value)

  const [isMetric, setIsMetric] = useState(false)
  const handleIsMetric = () => setIsMetric(!isMetric)

  
  // location zip & city Data
  const zipData = require('./tables/zipData.json')
  const cityData = require('./tables/cities.json')

  // Greenhouse
  const airinfiltration  = require('./tables/airinfiltration')
  
  // Plants data
  const plantData =  require('./tables/plants.json')
  
  const checkInputString = (input) => {
    const regex = /^[a-zA-Z]+$/;
    if(!input.match(regex)) {alert("Input must be a letter/string!"); return false}

  }
  
  const checkInputInteger = (number) => {
    const regex = /^[0-9]+$/;
    if(!number.match(regex)) {alert("Input must be a numbers"); return false;}
  }
  
  const feetToMeters = (feet) => feet * 0.3048
  const metersToFeet = (meters) => meters * 3.2808399
  const celsiusToFahrenheit = (c) => (c * 1.8) + 32
  const fahrenheitToCelsius = (f) => ((f - 32) * 5) / 9
  const mphToKph = (value) => value * 1.609344
  const ouncesToGrams = (oz) => oz * 28.3495231
  const BTUToKJ = (value) => value * 1.05505585
  const KJToBTU = (value) => value * 0.94781712
  // Not sure what these are for if the above is the same
  const exactBTUToKJ = (value) => value * 1.05505585
  const exactKJToBTU = (value) => value *  0.94781712
  
  //cost per BTU (cpBTU) to cost per KJ and back
  const cpBTUTocpKJ = (cost) => cost * 1.05505585
  const SQFToSQM = (sqF) => sqF * 0.09290304

  const convertCostFromMetric = (cost, english, metric) => {
    if(english === 'gallon' && metric === 'Liter') return cost * 3.78541178
    else if(english === 'cu ft' && metric === 'cu m') return cost * 0.0283168466
    else if (english === 'ton' && metric === 'metric ton') return cost * 1.10231131
    else if (english === 'kwh' && metric === 'kJ') return cost * 3600
    else if (english === 'lb' && metric === 'kilo') return cost * 0.45359237
    else if (english === 'therm' && metric === 'cu m') return cost * 2.74654186
    else if (english === 'cord' && metric === 'cu m') return cost * 3.62455636
    else return cost
  }

  const convertCostToMetric = (cost, english, metric) => {
    if(english === 'gallon' && metric === 'Liter') return cost / 3.78541178
    else if(english === 'cu ft' && metric === 'cu m') return cost / 0.0283168466
    else if (english === 'ton' && metric === 'metric ton') return cost / 1.10231131
    else if (english === 'kwh' && metric === 'kJ') return cost / 3600
    else if (english === 'lb' && metric === 'kilo') return cost / 0.45359237
    else if (english === 'therm' && metric === 'cu m') return cost / 2.74654186
    else if (english === 'cord' && metric === 'cu m') return cost / 3.62455636
    else return cost
  }

  const [darkMode, setDarkMode] = useState(false)
  // const [theme, setTheme] = useState('light')
  // const handleDarkMode = () => {
  //   if(!darkMode) setTheme('dark') 
  //   else  setTheme('light')
  //   setDarkMode(!darkMode)
  // }
  
  
  // global props
  const global = {
    component: component,
    onComponent: handleComponent,
    // darkMode: darkMode,
    // onDarkMode: handleDarkMode,
    isMetric: isMetric,
    onIsMetric: handleIsMetric,
    checkInputInteger: checkInputInteger,
    checkInputString: checkInputString,
    feetToMeters: feetToMeters,
    metersToFeet: metersToFeet,
    celsiusToFahrenheit: celsiusToFahrenheit,
    fahrenheitToCelsius: fahrenheitToCelsius,
    mphToKph: mphToKph,
    ouncesToGrams: ouncesToGrams,
    BTUToKJ: BTUToKJ,
    KJToBTU: KJToBTU,
    cpBTUTocpKJ: cpBTUTocpKJ,
    SQFToSQM: SQFToSQM,
    onConvertCostFromMetric: convertCostFromMetric,
    onConvertCostToMetric: convertCostToMetric,
  
  }
  
  return (
    <GlobalContextProvider global={global}>
    <main className="w-full h-screen">
      <BrowserRouter>
        
        {/* Menu */}
        <Navbar /> 
        
        {/* Content  */}
        <Routes> 
          <Route path="/" element={ <Welcome />} />
          <Route path="/about" element={<Documentation switch={0} />} />
          <Route path="/getstarted" element={<Documentation switch={1} />} />
          <Route path="/location" element={<Location cityData={cityData} zipData={zipData}  />} />
          <Route path="/greenhouse/*" element={<Greenhouse aiData={airinfiltration} />} />
          <Route path="/heating/*" element={<Heating fuelInformation={fuelInformation} heatingSysSetupImages={heatingSysSetupImages} />} />
        
            
          <Route path="/lighting"element={<Lighting />} />
          <Route path="/plants" element={<Plants plantData={plantData} />}/>
          {/* <Route path="/output" element={<GreenhouseSetup />} /> */}
          <Route path="/output" element={<Test />} />
          {/* <Route path="/settings" element={<Settings global={global} />} /> */}
        </Routes>
      </BrowserRouter>
 
    </main>
    <footer className="ribbon">
            <h4 >Beta Release</h4>
        </footer>    
    </GlobalContextProvider>
  );
}

export default App;
