import Plot from 'react-plotly.js'


const MapChart = (props) => {
  const {values} = props
 
  return <>
    <Plot
      data = {[
          {type:'scattermapbox',
            lat:[values.position[0]],
            lon:[values.position[1]],
            mode:'markers',
            marker: {size:14},
            text:[`${values.city}, ${values.state} ${values.zipcode}`]},          
        ]}
        
      layout = {{
        width: 1000, 
        height: 500, 
        autosize: true,
        hovermode:'closest',
        mapbox: { style: "open-street-map", center:  {lat:values.position[0], lon:values.position[1]}, zoom: 5 }
      }}

      config = {{displayModeBar: false}}
      
    />
    </>

};

export default MapChart;