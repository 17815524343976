import {useState, useEffect, useContext} from 'react'

import { GlobalContext } from '../hooks/GlobalContext'
import DropDownList from '../hooks/DropDownList';
import Session from '../hooks/Session'
import useDocumentTitle from '../hooks/useDocumentTitle';

import LightingSchedule from './Schedule/LightingSchedule'
import LightingSetup from './Setup/LightingSetup'

import {PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

const scheduleType = ['Light Schedule', 'Hourly Light Schedule', 'Threshold', 'Hourly Threshold']
const lightingUnit = ["µmol /m² /s", "Footcandles", "Lux", "W/m² [PAR]", "W/m² [SW]"]

const Lighting = () => {
    const context = useContext(GlobalContext)

    useEffect(() => {
         context.global.onComponent("Lighting");
    }, [context.global]);
    
    const [localComponent, setLocalComponent] = useState("")
    const handleLocalComponent = (value) => setLocalComponent(value)
    
    const [subMenu, setSubMenu] = useState("")
    const [values, setValues] = useState(null)
    useEffect(() => {
        if(subMenu === 'lightingSchedule'){
            context.scheduleTypeOption === 'Light Schedule' ? 
                setValues({listName: context.dropDownOption,
                subMenu:subMenu, scheduleTypeOption: context.scheduleTypeOption, beginTime: context.beginTime, endTime: context.endTime})
            : context.scheduleTypeOption === 'Hourly Light Schedule' ? 
                setValues({listName: context.dropDownOption,
                subMenu:subMenu, scheduleTypeOption: context.scheduleTypeOption, toggle1:context.toggle1,toggle2:context.toggle2,toggle3:context.toggle3,toggle4:context.toggle4,toggle5:context.toggle5,toggle6:context.toggle6,toggle7:context.toggle7,toggle8:context.toggle8,toggle9:context.toggle9,toggle10:context.toggle10,toggle11:context.toggle11,toggle12:context.toggle12,toggle13:context.toggle13,toggle14:context.toggle14,toggle15:context.toggle15,toggle16:context.toggle16,toggle17:context.toggle17,toggle18:context.toggle18,toggle19:context.toggle19,toggle20:context.toggle20,toggle21:context.toggle21,toggle22:context.toggle22,toggle23:context.toggle23,toggle24:context.toggle24})          
            : context.scheduleTypeOption === 'Threshold' ? 
                setValues({listName: context.dropDownOption,
                subMenu:subMenu, scheduleTypeOption: context.scheduleTypeOption, typeOfLights:context.TOLDropDown, LUDropDown: context.LUDropDown, insideLightThreshold: context.cValue, beginTime: context.beginTime, endTime: context.endTime})
            : context.scheduleTypeOption === 'Hourly Threshold' ? 
                setValues({listName: context.dropDownOption,
                subMenu:subMenu, scheduleTypeOption: context.scheduleTypeOption, hourlyThreshold1:context.hourlyThreshold1,hourlyThreshold2:context.hourlyThreshold2,hourlyThreshold3:context.hourlyThreshold3,hourlyThreshold4:context.hourlyThreshold4,hourlyThreshold5:context.hourlyThreshold5,hourlyThreshold6:context.hourlyThreshold6,hourlyThreshold7:context.hourlyThreshold7,hourlyThreshold8:context.hourlyThreshold8,hourlyThreshold9:context.hourlyThreshold9,hourlyThreshold10:context.hourlyThreshold10,hourlyThreshold11:context.hourlyThreshold11,hourlyThreshold12:context.hourlyThreshold12,hourlyThreshold13:context.hourlyThreshold13,hourlyThreshold14:context.hourlyThreshold14,hourlyThreshold15:context.hourlyThreshold15,hourlyThreshold16:context.hourlyThreshold16,hourlyThreshold17:context.hourlyThreshold17,hourlyThreshold18:context.hourlyThreshold18,hourlyThreshold19:context.hourlyThreshold19,hourlyThreshold20:context.hourlyThreshold20,hourlyThreshold21:context.hourlyThreshold21,hourlyThreshold22:context.hourlyThreshold22,hourlyThreshold23:context.hourlyThreshold23,hourlyThreshold24:context.hourlyThreshold24})
            : setValues(null)
        
        } else if(subMenu === 'lightingSetup') {
            setValues({listName: context.dropDownOption,
                subMenu:subMenu,
                LUDropDown: context.LUDropDown,
                numberOfLights:context.numberOfLights,
                TOLDropDown:context.TOLDropDown,
                efficacy:context.efficacy,
                wattageOfLights:context.wattageOfLights,
                wattageOfBallasts:context.wattageOfBallasts
            })
        }
    }, [subMenu, context])
  
    
    return <>
        <div className="flex flex-wrap justify-evenly w-full">
            <div className="w-1/3 bg-yellow-300 rounded my-4 py-1 shadow">
                <div className="flex justify-around py-2">
                    <label className=" text-3xl" htmlFor="subMenuTitle">Lighting:  {localComponent}</label>
                </div> 
            </div>

            {subMenu.length > 0 && [
                <Session subMenu={subMenu} values={values}/>, 
                
                <DropDownList localComponent={localComponent} subMenu={subMenu}  />
            ]}
        </div>

        <div className="flex flex-wrap">
            <div className="w-3/5 ml-auto mr-auto">
                {/* Header Menu */}
                <div className="mx-2 my-2 flex flex-wrap justify-around">
                    <button key="lsch" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="lightingSchedule">Schedule</button>
                    
                    <button key="lstp" onClick={e => setSubMenu(e.currentTarget.name)} className='TABS' name="lightingSetup">Setup</button>  
                </div>
            </div>
        </div>

        {/* Menu Content */}
        <div className="flex flex-wrap">
        {subMenu === 'lightingSchedule' ?
            <LightingSchedule 
                useDocumentTitle={useDocumentTitle} 
                onLocalComponent={handleLocalComponent}  
                global={context.global} 
                scheduleType={scheduleType}
                lightingUnit={lightingUnit}
                scheduleTypeOption={context.scheduleTypeOption}
                LUDropDown={context.LUDropDown}
                onScheduleTypeOption={context.onScheduleTypeOption}
                onLUDropDown={context.onLUDropDown}
            />
        : subMenu === 'lightingSetup' ?
            <LightingSetup 
                useDocumentTitle={useDocumentTitle} 
                onLocalComponent={handleLocalComponent} 
                global={context.global}
                LUDropDown={context.LUDropDown} 
                lightingUnit = {context.lightingUnit}
                typeOfLights = {context.typeOfLights}
                numberOfLights = {context.numberOfLights}
                TOLDropDown = {context.TOLDropDown}
                efficacy = {context.efficacy}
                wattageOfLights = {context.wattageOfLights}
                wattageOfBallasts = {context.wattageOfBallasts}
                
                onLUDropDown={context.onLUDropDown}
                onNumberOfLights={context.onNumberOfLights}
                onTOLDropDown={context.onTOLDropDown}
                onEfficacy={context.onEfficacy}
                onWattageOfLights={context.onWattageOfLights} 
                onWattageOfBallasts={context.onWattageOfBallasts}
            />
        : <div className="w-3/5 ml-auto mr-auto">
            <div className="mx-2 my-2 flex flex-wrap justify-around">
                <p>Please select a lighting option to begin</p>
            </div>
        </div>}
    </div>

    <PopupboxContainer />
    </>
}

export default Lighting
