let WIDTH = 0, LENGTH = 0, ROOFHEIGHT = 0, CURTAINSA = 0, ROOFSA = 0, ROOFVOL = 0, LOWERVOL = 0, QUONSET = false, ROOFTYPE = '', CURRENTAIRINPUT = 0, CURRENTUVALUEINPUT = 0, CURRENTECAIRINPUT = 0

/*  archArea
*  Used for volume.
*  calcuates the area bound by line segment w intersecting a circle at two
*  points.  A ray is drawn from the center of the circle, perpendicular to
*  w and ending at the circles rim.  The distance between w and the rim of
*  the circle along this ray is defined as ah.  if ah is creater than half
*  of w then assume that there is a semi circle sitting atop a rectangle.
*/
export const archArea = (width, ah) => {
    let hw = width / 2
   
    //if ah = 0, division by 0 will occur so return 0
    if(ah <= hw) {
        if(ah === 0) return 0
        
        //make sure that ah is not negative
        ah = Math.abs(ah);

        const r = (Math.pow(hw, 2) + Math.pow(ah, 2)) / (2 * ah);
        const theta = (Math.PI - 2 * Math.atan(hw/ah));
        
        return (Math.pow(r, 2) * theta) - hw * (r - ah);
    }
    //if the roof starts before the arch starts and there is a rectagular volume
    else return (Math.PI * Math.pow(hw, 2)/2) + (width * (ah-hw));            
}

/* archLength
* Used for surface area.  Returns the length of the roof factoring in the bend.
*/
export const archLength = (width, ah) => {
    let hw = width/2

    if(ah <= hw) {
        const r = (Math.pow(hw, 2) + Math.pow(ah, 2)) / (2 * ah);
        const theta = 2 * Math.PI - 4 * Math.atan(hw / ah);
        return r * theta;

    } else return (Math.PI * hw) + (2 * (ah - hw));

}

 // Roof SA Conduction
export const RoofSAConduction = (roofShape, width, peaksPerSpan, roofHeight, sideHeight, length, numberOfSpans) => {
    if(roofShape === 'Arch') {
        let arch = archLength((width / peaksPerSpan),(roofHeight - sideHeight));
        return length * arch * peaksPerSpan * numberOfSpans
    }

    else if (roofShape === 'Triangular') {
        let alpha = Math.sqrt(Math.pow((roofHeight - sideHeight), 2)+ Math.pow((0.5 * (width/peaksPerSpan)), 2));
        let  slope_area = alpha * length;
        return (slope_area * peaksPerSpan * numberOfSpans);
    }

    else if(roofShape === 'Half Arch') {
        let ah = roofHeight - sideHeight;
        let w = width * 2;
        let arch = archLength(w,ah);
        return 0.5 *(arch * length) * numberOfSpans;
    }
    else  return length * width * numberOfSpans 
}

// USA Conduction
export const USAConduction = (sidewall, roofShape, width, peaksPerSpan, roofHeight, sideHeight, length, numberOfSpans) => {
    if(roofShape === 'Arch') {
        if(sidewall) return 0;

        let archA = archArea((width / peaksPerSpan),(roofHeight - sideHeight))
        return (archA * peaksPerSpan)
    }

    else if (roofShape === 'Triangular') {
        if(sidewall) return 0;

        let tri_area = 0.5 * (roofHeight - sideHeight) * (width/peaksPerSpan);
        return tri_area * peaksPerSpan * numberOfSpans;
    }

    else if(roofShape === 'Half Arch') {
        if(sidewall) return ((roofHeight - sideHeight) * length) * numberOfSpans;
        
        let ah = roofHeight - sideHeight;
        let w = width * 2;
        let archA = archArea(w, ah);
        return archA; 
    }
    else return 0;
} 
    

export const calculateRoofEqualibriumTemperature = (outsideTemp, setPoint, costList, materials) => {

    costList[0].greenhouse.map((item) => {WIDTH = item.width; LENGTH = item.length; ROOFHEIGHT = item.roofHeight; CURTAINSA = item.energyCurtainSurfaceArea; ROOFSA = item.roofSurfaceArea; ROOFVOL = item.roofVolume; LOWERVOL = item.houseVolume; QUONSET = item.quonset; ROOFTYPE = item.roofType; CURRENTAIRINPUT = item.currentAirInput; CURRENTUVALUEINPUT = item.currentUValueInput; CURRENTECAIRINPUT = item.currentECAirInput })
 
    // TU = ((AC * UC* TD) + (AR *UR * TO))/(AR *UR + AC * UC)
    // Geometry for ecRoof areas are done here
    if(QUONSET) {
        let r = 0, ecWidth = 0, ah = ROOFHEIGHT

        if(ah < WIDTH) {
            r = (Math.pow((WIDTH / 2), 2) + Math.pow(ROOFHEIGHT, 2)) / (2 * ROOFHEIGHT)
            //width of one EC segment, there are 3
            ecWidth = r * Math.sqrt(2 - Math.sqrt(2))
            CURTAINSA = 3 * LENGTH * ecWidth
            ah = r - Math.sqrt(Math.pow(r, 2) - Math.pow((ecWidth / 2), 2))
            ROOFVOL = (3 * (archArea(ecWidth, ah) * LENGTH));
            LOWERVOL = (archArea(WIDTH, ROOFHEIGHT) * LENGTH) - ROOFVOL;

            ROOFSA = 3 * (LENGTH * archLength(ecWidth, ah)) + (archArea(ecWidth, ah) * 2)

           
        } else {
            //Arch is taller then wide, so there is a vertile portion we need bottom and arch height
            let bHeight = ROOFHEIGHT - WIDTH
            let aHeight = ROOFHEIGHT - bHeight

            r = (Math.pow(( WIDTH / 2), 2) + Math.pow(aHeight, 2)) / (2 * aHeight);
            
            //width of one EC segment, there are 3
            ecWidth = r * Math.sqrt(2 - Math.sqrt(2));
            ROOFHEIGHT = r - Math.sqrt(Math.pow(r, 2) - Math.pow((ecWidth / 2), 2));
            let archL = archLength(ecWidth, ah)
            let archA = archArea(ecWidth, ah)
            let bottomSA = 2 * LENGTH * bHeight
            ROOFSA = (3 * ((LENGTH * archL) + (archA * 2))) + bottomSA;
            CURTAINSA = (3 * LENGTH * ecWidth) + bottomSA;
            ROOFVOL = (3 * archArea(ecWidth, ah) * LENGTH) + (LENGTH * bHeight * ah);
            LOWERVOL = (archArea(WIDTH, ROOFHEIGHT) * LENGTH) - ROOFVOL;
        }

    }

    let roofUValue = 0
    materials.filter((item) => item.Material_Name === ROOFTYPE).map((item) => roofUValue = Number(item.Heat_Transfer))   
   
   //final long calculation
   return ((ROOFSA * roofUValue * outsideTemp) + (0.02 * CURRENTAIRINPUT * ROOFVOL * outsideTemp) + (CURTAINSA * CURRENTUVALUEINPUT * setPoint) + (0.02 * CURRENTECAIRINPUT * LOWERVOL * setPoint)) / ((ROOFSA * roofUValue) + (0.02 * CURRENTAIRINPUT * ROOFVOL) + (CURTAINSA * CURRENTUVALUEINPUT) + (0.02 * CURRENTECAIRINPUT * LOWERVOL));
}


export const heatGainFromCurtain = (outsideTemp, roofTemp, setPoint, costList, materials) => {

    let roofShape = "", peaksPerSpan = 1, sideHeight = 0,  numberOfSpans = 1, endWall1Material = "", endWall2Material = "", sideWall2Material = ""

    costList[0].greenhouse.map((item) => {WIDTH = item.width; LENGTH = item.length; ROOFHEIGHT = item.roofHeight; CURTAINSA = item.energyCurtainSurfaceArea; ROOFSA = item.roofSurfaceArea; ROOFVOL = item.roofVolume; LOWERVOL = item.houseVolume; QUONSET = item.quonset; ROOFTYPE = item.roofType; CURRENTAIRINPUT = item.currentAirInput; CURRENTUVALUEINPUT = item.currentUValueInput; CURRENTECAIRINPUT = item.currentECAirInput; roofShape = item.roofShape; peaksPerSpan = item.peaksPerSpan; sideHeight = item.sideHeight; numberOfSpans = item.numberOfSpans; endWall1Material = item.endWall1Type; endWall2Material = item.endWall2Type; sideWall2Material = item.sideWall2Type })

    let roofUValue = 0, endWall1UValue = 0, endWall2UValue = 0, sideWall2UValue = 0
    materials.filter((item) => item.Material_Name === ROOFTYPE).map((item) => roofUValue = Number(item.Heat_Transfer))
    materials.filter((item) => item.Material_Name === endWall1Material).map((item) => endWall1UValue = Number(item.Heat_Transfer))
    materials.filter((item) => item.Material_Name === endWall2Material).map((item) => endWall2UValue = Number(item.Heat_Transfer))
    materials.filter((item) => item.Material_Name === sideWall2Material).map((item) => sideWall2UValue = Number(item.Heat_Transfer))
    
    //here is the ridiculously long calculation
    if(QUONSET) {
        let r, ecWidth, volume, roofSA, ah
        if(ROOFHEIGHT <= WIDTH) {
            r = (Math.pow((WIDTH / 2), 2) + Math.pow((ROOFHEIGHT), 2)) / (2 * (ROOFHEIGHT))

            //width of one segment, there are 3 segments
            ecWidth =  r * Math.sqrt(2 - Math.sqrt(2))
            ah = r - Math.sqrt(Math.pow(r, 2) - Math.pow((ecWidth / 2), 2))
            volume = 3 * archArea(ecWidth, ah) * LENGTH
            let archL = archLength(ecWidth, ah)
            let archA = archArea(ecWidth, ah);
            ROOFSA = 3 * ((LENGTH * archL) + (archA * 2));

            let lowerSA = 2 * (archArea(WIDTH, ROOFHEIGHT) - (3 * archArea(ecWidth, ah)));
            LOWERVOL = (lowerSA / 2) * LENGTH;

            let hlRoof = (ROOFSA * (roofTemp - outsideTemp) * roofUValue) + (CURRENTECAIRINPUT * 0.02 * volume * (roofTemp - outsideTemp))
            let hlSides = (lowerSA * (setPoint - outsideTemp) * materials.getRoofUvalue()) + (CURRENTECAIRINPUT* 0.02 * LOWERVOL) * (setPoint - outsideTemp);
            
            return hlRoof + hlSides;
        
        } else {
            let bHeight = ROOFHEIGHT - WIDTH
            let aHeight = ROOFHEIGHT - bHeight;
            r = (Math.pow((WIDTH / 2), 2) + Math.pow((aHeight), 2)) / (2 * (aHeight))
            
            //width of one segment, there are 3 segments
            ecWidth =  r * Math.sqrt(2 - Math.sqrt(2));
            ah = r - Math.sqrt(Math.pow(r, 2) - Math.pow((ecWidth / 2), 2));

            volume = (3 * archArea(ecWidth, ah) * LENGTH) + (LENGTH * bHeight * ah);
            let archL = archLength(ecWidth, ah);
            let archA = archArea(ecWidth, ah);
            let bottomSA = 2 * LENGTH * bHeight;
            roofSA = 3 * ((LENGTH * archL) + (archA * 2)) + bottomSA;
            let lowerSA = 2 * ((archArea(WIDTH, aHeight) - (3 * archArea(ecWidth, ah))) + (WIDTH * bHeight));
            let lowerVol = (lowerSA / 2) * LENGTH
            let hlRoof = (roofSA * (roofTemp - outsideTemp) * roofUValue) + (CURRENTECAIRINPUT * 0.02 * volume * (roofTemp - outsideTemp));
            let hlSides = (lowerSA * (setPoint - outsideTemp) * roofUValue) + (CURRENTECAIRINPUT * 0.02 * lowerVol) * (setPoint - outsideTemp);
            
            return hlRoof + hlSides;
        }
    }
            
    return (RoofSAConduction(roofShape, WIDTH, peaksPerSpan, ROOFHEIGHT, sideHeight, LENGTH, numberOfSpans) * (roofTemp - outsideTemp) * roofUValue) + 
        (USAConduction(false, roofShape, WIDTH, peaksPerSpan, ROOFHEIGHT, sideHeight, LENGTH, numberOfSpans) * (roofTemp - outsideTemp) * endWall1UValue) + 
        (USAConduction(false, roofShape, WIDTH, peaksPerSpan, ROOFHEIGHT, sideHeight, LENGTH, numberOfSpans) * (roofTemp - outsideTemp) * endWall2UValue) + 
        (USAConduction(true, roofShape, WIDTH, peaksPerSpan, ROOFHEIGHT, sideHeight, LENGTH, numberOfSpans) * (roofTemp - outsideTemp) * sideWall2UValue) + (CURRENTECAIRINPUT * 0.02 * ROOFVOL * (roofTemp - outsideTemp)
    )
}


// calculateSideConduction
// Calculates the heatloss through conduction given the difference in temperature between the inside and outside (deltaTemp)

export const calculateSideConduction = (deltaTemp, costList, materials) => {
    let roofShape = "", hasEnergyCurtain = false, side1Loss = 0, side2Loss = 0, end1Loss = 0, end2Loss = 0, sideHeight = 0,  sideKneewall1 =  0, sideKneewall2 =  0, sideWall1Material = "", sideWall2Material = "", endKneewall1 =  0, endKneewall2 =  0, endWall1Material = "", endWall2Material = "", endKneewall1Material = "", endKneewall2Material = "", numberOfSpans = 0

    costList[0].greenhouse.map((item) => {WIDTH = item.width; LENGTH = item.length; ROOFHEIGHT = item.roofHeight; roofShape = item.roofShape; sideHeight = item.sideHeight; sideKneewall1 = item.sideWall1;  sideKneewall2 = item.sideWall2; sideWall1Material = item.sideWall1Type; sideWall2Material = item.sideWall2Type; endKneewall1 = item.endWall1;  endKneewall2 = item.endWall2; endWall1Material = item.endWall1Type; endWall2Material = item.endWall2Type; endKneewall1Material = item.endKneewall1Type; endKneewall2Material = item.endKneewall2Type; hasEnergyCurtain = item.ecInstalled; numberOfSpans = item.numberOfSpans })

    //if they have a rectangular roof and a energycurtian we need to account for it
    let x = 1
    if(roofShape === 'Arch' && hasEnergyCurtain) x = 0.85
    
    let sideKneewall1UValue = 0, sideKneewall2UValue = 0, endKneeWall1UValue = 0, endKneeWall2UValue = 0, endWall1UValue = 0, endWall2UValue = 0

    materials.filter((item) => item.Material_Name === sideWall1Material).map((item) => sideKneewall1UValue = Number(item.Heat_Transfer))
    materials.filter((item) => item.Material_Name === sideWall2Material).map((item) => sideKneewall2UValue = Number(item.Heat_Transfer))
    materials.filter((item) => item.Material_Name === endWall1Material).map((item) => endWall1UValue = Number(item.Heat_Transfer))
    materials.filter((item) => item.Material_Name === endWall2Material).map((item) => endWall2UValue = Number(item.Heat_Transfer))
    materials.filter((item) => item.Material_Name === endKneewall1Material).map((item) => endKneeWall1UValue = Number(item.Heat_Transfer))
    materials.filter((item) => item.Material_Name === endKneewall2Material).map((item) => endKneeWall2UValue = Number(item.Heat_Transfer))
      
    
    //calculate conduction of sides, keep in mind sideheigt includes kneewall
    side1Loss = ((LENGTH * (sideHeight - sideKneewall1)) * sideKneewall1UValue  * deltaTemp * x) + ((LENGTH * sideKneewall1) * sideKneewall1UValue * deltaTemp);

    side2Loss = ((LENGTH * (sideHeight- sideKneewall2)) * sideKneewall2UValue * deltaTemp * x) + ((LENGTH * sideKneewall2) * sideKneewall2UValue * deltaTemp);

    end1Loss = ((WIDTH * (sideHeight  - endKneewall1)) * endWall1UValue * deltaTemp * x) + ((WIDTH * endKneewall1) * endKneeWall1UValue * deltaTemp)


    end2Loss = ((WIDTH * (sideHeight - endKneewall2)) * endWall1UValue * deltaTemp * x) + ((WIDTH * endKneewall2) * endKneeWall2UValue * deltaTemp);

    return side1Loss + side2Loss + (numberOfSpans *(end1Loss + end2Loss));
}

    
                    
                   
    
   
    