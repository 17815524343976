const content = [
    {
        id: 1,
        title: 'Home',
        exact: true,
        to: '/',
        icon: 'mdi mdi-home',
        
    },
    {
        id: 2,
        title: 'Location',
        exact: true,
        to: '/location',
        icon: 'mdi mdi-map-marker-outline',
    },
    {
        id: 3,
        title: 'Greenhouse',
        exact: true,
        to: '/greenhouse',
        icon: 'mdi mdi-greenhouse',
    },
    {
        id: 4,
        title: 'Heating',
        exact: true,
        to: '/heating',
        icon: 'mdi mdi-radiator ',
    },
    {
        id: 5,
        title: 'Lighting',
        exact: true,
        to: '/lighting',
        icon: 'mdi mdi-ceiling-light-multiple-outline ',
    },
    {
        id: 6,
        title: 'Plants',
        exact: true,
        to: '/plants',
        icon: 'mdi mdi-sprout-outline',
    },
    {
        id: 7,
        title: 'Output',
        exact: true,
        to: '/output',
        icon: 'mdi mdi-export',
    },
    {
        id: 8,
        title: 'Predictions/Forecast',
        exact: true,
        to: '/pf',
        icon: 'mdi mdi-directions-fork',
    },
    {
        id: 9,
        title: 'Settings',
        exact: true,
        to: '/settings',
        icon: 'mdi mdi-cog-outline',
    },
    {
        id: 10,
        title: 'Profile',
        exact: true,
        to: '/user',
        icon: 'mdi mdi-shield-account',
    },
     {
        id: 99,
        title: 'Output',
        exact: true,
        to: '/output',
        icon: 'mdi mdi-export',
    },
];

export default content;
