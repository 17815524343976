import {useContext, useEffect, useState} from 'react'
import { GlobalContext } from '../hooks/GlobalContext';


const DOCUMENT_TITLE = "Schedule Setup"
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

function HeatingSchedule(props) {
    const {onLocalComponent, useDocumentTitle, options} = props
    const context = useContext(GlobalContext)
    const [sDAYS, setSDAYS] = useState([])
    const [eDAYS, setEDAYS] = useState([])


    useDocumentTitle(DOCUMENT_TITLE)
    useEffect(() => {
         onLocalComponent(DOCUMENT_TITLE);
    }, [onLocalComponent]);


    const handleAMPMChange = (e) => {
        if (e.target.value <= 12 && e.target.value >= 1) {
            if(e.target.id === 'AM'){
                context.onStartDay(e.target.value)
            } else if (e.target.id === 'PM') {
                context.onStartNight(e.target.value)
            }
        }
    }
       
    const handleCheckboxClick = () => context.onIsChecked(!context.isChecked)

    const handleHSHourlyTempInput = (e) => {
        if(isNaN(e.target.value)) {
            alert('Please enter a numeric value.')
            context.onHSHourlyTemp({...context.hsHourlyTemp, [e.target.id] : Number(55)})
        } else context.onHSHourlyTemp({...context.hsHourlyTemp, [e.target.id]: Number(e.target.value)})
    }
        
   
    useEffect(() => {
        // Attempted to combining the two and a mess was made. February didn't want to work properly. Separated the 2 instead.
        // Start Date
        if(MONTHS.indexOf(context.startMonth) === 1) setSDAYS([1 , 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28])
    
        else if((MONTHS.indexOf(context.startMonth) === 3) || (MONTHS.indexOf(context.startMonth) === 5) || (MONTHS.indexOf(context.startMonth) === 8) || (MONTHS.indexOf(context.startMonth) === 10)) setSDAYS([1 , 2, 3, 4, 5, 6, 7, 8, 9,10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30])
        
        else setSDAYS([1 , 2, 3, 4, 5, 6, 7, 8, 9,10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31])
            
        // Stop Date 
        if((MONTHS.indexOf(context.stopMonth)) === 1) setEDAYS([1 , 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28])
        
        else if(((MONTHS.indexOf(context.stopMonth)) === 3) || ((MONTHS.indexOf(context.stopMonth)) === 5) || ((MONTHS.indexOf(context.stopMonth)) === 8) || ((MONTHS.indexOf(context.stopMonth)) === 10)) setEDAYS([1 , 2, 3, 4, 5, 6, 7, 8, 9,10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30])
       
        else setEDAYS([1 , 2, 3, 4, 5, 6, 7, 8, 9,10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31])
   }, [context.startMonth, context.stopMonth])

   console.log(context.scheduleStartDay)
   
    return <>
    {/* Header */}
        <div className="flex justify-around mr-auto ml-auto">
            <div className="w-full my-5 px-5 py-1 bg-red-300 rounded shadow space-x-5">
                <label className="pt-2 label" htmlFor="start">Start Date: </label>
                <select className="border border-black" name="sMonth" value={context.startMonth} onChange={e => context.onStartMonth(e.target.value) }>
                    {MONTHS.map((month) => <option>{month}</option>)}
                </select>   
                <select className="border border-black" name="sDay" value={context.scheduleStartDay} onChange={e => context.onScheduleStartDay(e.target.value) }>
                    {sDAYS.map((day) => <option>{day}</option>)}
                </select>  

                <label className="pt-2 label" htmlFor="end">Stop Date: </label>
                <select className="border border-black" name="eMonth" value={context.stopMonth} onChange={e => context.onStopMonth(e.target.value) }>
                    {MONTHS.map((month) => <option>{month}</option>)}
                </select>   
                <select className="border border-black" name="eDay" value={context.stopDay} onChange={e => context.onStopDay(e.target.value) }>
                    {eDAYS.map((day) => <option>{day}</option>)}
                </select>  
                
                {/* Select Schedule Type */}
                <label className="pt-2 label" htmlFor="schedule-type">Schedule Type: </label>
                <select className="border-black" id="schedule-type" name="type" value={context.heatScheduleTypeOption} onChange={e => context.onHeatScheduleTypeOptions(e.target.value)}>
                    <option>Select One</option>
                    {options.map((option, id) => <option key={id} value={option}>{option} </option>)}    
                </select>
            </div>
        </div>

        {/* Content */}
        <div className="flex w-2/3 py-1 my-5 ml-auto mr-auto bg-red-100 rounded shadow justify-evenly">
            <h1 className="ml-2 text-3xl">Temperature Settings ({context.global.isMetric ? '°C': '°F'})</h1>
        </div>

        {context.heatScheduleTypeOption === options[0] ?  <div className="flex bg-gray-100 w-2/3 ml-auto mr-auto justify-evenly py-3 rounded shadow" ><p className="flex justify-center py-3">This option has no feature</p></div>

        : context.heatScheduleTypeOption === options[1] ? 
             <div className="flex bg-gray-100 w-2/3 ml-auto mr-auto justify-evenly py-3 rounded shadow" >
                <label className="label" htmlFor="temperature" >Temperature ({context.global.isMetric ? '°C': '°F'}): </label>
                <input className="border-black" value={context.constantTemp} onChange={e => context.onConstantTemp(e.target.value)} id="temperature" type="text"  size="1"></input>
            </div>
            
        : context.heatScheduleTypeOption === options[2] ? [
             <div className="flex bg-gray-100 w-2/3 ml-auto mr-auto justify-evenly py-3 rounded shadow" key="dailyTemp">  
                <label className="label" htmlFor="dayTemp" >Day Temperature ({context.global.isMetric ? '°C': '°F'}): </label>
                <input className="border-black" value={context.dayTemp} onChange={e => context.onDayTemp(e.target.value)} id="dayTemp" type="text"  size="1"></input>    
                
                <label className="label" htmlFor="dayTemp" >Night Temperature ({context.global.isMetric ? '°C': '°F'}): </label>
                <input className="border-black" value={context.nightTemp} onChange={e => context.onNightTemp(e.target.value)} id="nightTemp" type="text"  size="1"></input>
            </div>,

            <div className="flex w-2/3 p-5 my-5 ml-auto mr-auto bg-gray-100 shadow justify-evenly" key="timeTemp"> 
                
                <div className="space-x-2" >
                    <input checked={context.isChecked} type="checkbox" onChange={handleCheckboxClick}  id="dayStart"></input>
                    <label className="label" htmlFor="dayStart">It is day while the sun is up </label><br />
                </div>

                <label className="label" htmlFor="AM">Day Start (AM): </label>
                <input className="border-black" disabled={context.isChecked} value={context.startDay} onChange={handleAMPMChange} id="AM" type="text"  size="1"></input>
                

                <label className="label" htmlFor="PM">Night Start (PM): </label>
                <input className="border-black" disabled={context.isChecked} value={context.startNight} onChange={handleAMPMChange} id="PM" type="text"  size="1"></input>
            </div>
        ]:
            
        context.heatScheduleTypeOption === options[3] ?
            <div className="flex bg-gray-100 w-2/3 ml-auto mr-auto justify-evenly py-3 rounded shadow" >
                <table className="table-fixed">

                    <thead>
                    <tr>
                        <th></th>
                        <th>12</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                        <th>10</th>
                        <th>11</th>
                    </tr>
                    </thead>
                    
                    <tbody>
                    <tr>
                        <td>AM</td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp12A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp12A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp1A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp1A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp2A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp2A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp3A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp3A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp4A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp4A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp5A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp5A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp6A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp6A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp7A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp7A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp8A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp8A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp9A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp9A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp10A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp10A"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp11A']} onChange={e => handleHSHourlyTempInput(e)} id="tmp11A"  size="1"></input> </td>
                    </tr>
                   
                    <tr>
                        <td>PM</td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp12P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp12P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp1P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp1P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp2P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp2P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp3P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp3P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp4P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp4P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp5P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp5P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp6P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp6P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp7P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp7P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp8P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp8P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp9P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp9P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp10P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp10P"  size="1"></input> </td>
                        <td><input className="border-2 border-bg-gray-200" value={context.hsHourlyTemp['tmp11P']} onChange={e => handleHSHourlyTempInput(e)} id="tmp11P"  size="1"></input> </td>
                    </tr>
                    </tbody>
                </table>


                
            </div>
        
        :  <div className="flex bg-gray-100 w-2/3 ml-auto mr-auto justify-evenly py-3 rounded shadow" ><div className="py-3"> Select a Schedule Type to enable this feature </div></div>     
        }
            
    </>
}

export default HeatingSchedule
